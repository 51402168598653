export const state = () => ({
  productType: 'friendly-finance',
  balloonValue: false,
  name: '',
  description: '',
  totalWeeks: '',
  status: '',
  insurance: false,
  servicing: false,
  vehicle_type: '',
});

export const getters = {
  getVehicleType(state) {
    return state.vehicle_type;
  },
};

export const mutations = {
  setVehicleType(store, payload) {
    store.vehicle_type = payload;
  },
  setPayloadFields(store, payload) {
    store[payload.key] = payload.value;
  },
  resetStore(store) {
    store.productType = 'friendly-finance';
    store.balloonValue = false;
    store.name = '';
    store.description = '';
    store.totalWeeks = '';
    store.status = '';
    store.insurance = false;
    store.servicing = false;
    store.vehicle_type = '';
  },
};

export const actions = {
  setPayloadFields({ commit }, payload) {
    commit('setPayloadFields', payload);
  },
  setVehicleType({ commit }, payload) {
    commit('setVehicleType', payload);
  },
  resetStore({ commit }) {
    commit('resetStore');
  },
  async submit({ state, commit }) {
    const payload = { ...state };
    // editing the payload before sending it
    if (state.balloonValue) {
      payload.balloonValue = 4000;
    } else payload.balloonValue = 0;

    payload.vehicle_type = state.vehicle_type.id;
    payload.totalWeeks = Number(state.totalWeeks);

    await this.$axios.post('/v2/application/package', payload);
  },
};
