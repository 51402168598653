export const state = () => ({
  page: 0,
  agreements: [],
  filters: '',
});

export const getters = {
  getAgreements(store) {
    return store.agreements;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};
export const mutations = {
  reset(store) {
    store.page = 0;
    store.agreements = [];
    store.filters = '';
  },
  setAgreements(store, payload) {
    store.agreements = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};
export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getAgreements({ commit, state }) {
    let url = 'finance?results=10&payment_status=anchor-payment';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const agreements = await this.$axios.$get(url);
    commit('setAgreements', agreements.agreement_list);
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
