export const state = () => ({
  phase: 0,
});

export const getters = {
  getApplicationPhase(state) {
    return state.phase;
  },
};

export const mutations = {
  reset(store) {
    store.phase = 0;
  },
  setApplicationPhase(state, payload) {
    state.phase = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  setApplicationPhase({ commit, state }, payload) {
    commit('setApplicationPhase', payload);
  },
};
