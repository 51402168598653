const fetchAssessmentById = async (assessmentId, context) => {
  const response = await context.$axios.$get(
    `/v2/application/assessment/${assessmentId}`
  );
  if (!response || !Object.keys(response).length) throw new Error();
  response.assessmentId = assessmentId;
  return response;
};

const skipTheoryAssessmentRequest = async (state, commit, context) => {
  const { applicationId } = state;
  await context.$axios.$put(
    `/v2/application/${applicationId}`, { theory_required: false },
  );
};

module.exports = {
  fetchAssessmentById,
  skipTheoryAssessmentRequest,
};
