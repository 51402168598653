// eslint-disable-next-line import/prefer-default-export
export const state = () => ({
  navbarLinks: {
    driver: {
      icon: 'mdi-account-circle',
      name: 'Driver',
      menuList: {
        agreement_list: {
          label: 'Agreement List',
          link: 'agreement_list',
        },
        driver_list: {
          label: 'Driver List',
          link: 'driver_list',
        },
        aftercare_review: {
          label: 'Aftercare review',
          link: 'aftercare_review',
        },
      },
    },
    fleet: {
      icon: 'mdi-view-dashboard',
      name: 'Fleet',
      menuList: {
        vehicle_list: {
          label: 'Vehicles List',
          link: 'fleet_list',
        },
        check_in_out: {
          label: 'Check In/Out',
          link: 'fleet_transfer',
        },
        condition_report: {
          label: 'Condition Report',
          link: 'condition_report_dynamic',
          path: 'default',
        },
        condition_reports_list: {
          label: 'Condition Reports List',
          link: 'condition_reports_list',
        },
        used_vehicles: {
          label: 'Used Vehicles',
          link: 'fleet_used',
        },
      },
    },
    vehicle: {
      icon: 'mdi-car-back',
      name: 'Vehicle',
      menuList: {
        vehicle_list: {
          label: 'Vehicle Types',
          link: 'fleet_types',
        },
        vehicle_batches: {
          label: 'Vehicle Batches',
          link: 'fleet_batches',
        },
        vehicle_products: {
          label: 'Vehicle Packages',
          link: 'fleet_product',
        },
      },
    },
    finance: {
      icon: 'mdi-database',
      name: 'Finance',
      menuList: {
        new_agreements: {
          label: 'New Agreements',
          link: 'finance_new_agreements',
        },
        closed_agreements: {
          label: 'Closed Agreements',
          link: 'finance_closed_agreements',
        },
      },
    },
    sales: {
      icon: 'mdi-cart',
      name: 'Sales',
      menuList: {
        create_order: {
          label: 'Create Order',
          link: 'new_order',
        },
        continue_order: {
          label: 'Continue Order',
          link: 'continue_order',
        },
        assessments: {
          label: 'Assessments',
          link: 'assessments_list',
        },
      },
    },
    servicing: {
      icon: 'mdi-cog',
      name: 'Servicing',
      menuList: {
        servicing_list: {
          label: 'Servicing List',
          link: 'services_list',
        },
        phv_and_mot: {
          label: 'PHV and MOT',
          link: 'phv_and_mot',
        },
      },
    },
    internal: {
      icon: 'mdi-sync',
      name: 'Internal',
      menuList: {
        employees_list: {
          label: 'Employees list',
          link: 'employee_list',
        },
        dealership_list: {
          label: 'Dealerships list',
          link: 'dealership_list',
        },
        branches_list: {
          label: 'Branches list',
          link: 'branches_list',
        },
      },
    },
  },
});

export const getters = {
  getNavbarItems(store) {
    return store.navbarLinks;
  },
};
