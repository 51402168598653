export const state = () => ({
  depositAmount: '',
  weeklyAmount: '',
  packageId: '',
  status: '',
});

export const getters = {
};

export const mutations = {
  setPayloadFields(store, payload) {
    store[payload.key] = payload.value;
  },
  resetStore(store) {
    store.depositAmount = '';
    store.weeklyAmount = '';
    store.package = '';
    store.status = '';
  },
};

export const actions = {
  setPayloadFields({ commit }, payload) {
    commit('setPayloadFields', payload);
  },
  resetStore({ commit }) {
    commit('resetStore');
  },
  async submit({ state }) {
    const {
      depositAmount,
      weeklyAmount,
      packageId,
      status,
    } = state;
    const payload = {
      depositAmount: Number(depositAmount),
      weeklyAmount: Number(weeklyAmount),
      package: packageId,
      status,
    };
    await this.$axios.post('/v2/application/price', payload);
  },
};
