export const state = () => ({
  page: 0,
  assessments: [],
  filters: '',
  resultCount: 0,
});
export const getters = {
  getData(store) {
    return store.assessments;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.assessments = [];
    store.filters = '';
  },
  setAssessments(store, payload) {
    store.assessments = payload;
  },
  clearAssessments(store) {
    store.assessments = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async fetchData({ commit, state }) {
    let url = '/assessment?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const assessments = await this.$axios.$get(url);
    const formatted = assessments.assessment_list.map((assessment) => {
      let driver_name = '';
      let result = '';
      const hub = '';
      if (assessment.application && assessment.application.length > 0) {
      }
      if (assessment.driver && assessment.driver.length > 0) {
        if (assessment.driver[0].title) {
          driver_name = `${driver_name} ${assessment.driver[0].title}`;
        }
        if (assessment.driver[0].first_name) {
          driver_name = `${driver_name} ${assessment.driver[0].first_name}`;
        }
        if (assessment.driver[0].middle_name) {
          driver_name = `${driver_name} ${assessment.driver[0].middle_name}`;
        }
        if (assessment.driver[0].last_name) {
          driver_name = `${driver_name} ${assessment.driver[0].last_name}`;
        }
      } else driver_name = 'No driver';

      if (assessment.passed == true) result = 'Passed';
      else result = 'Failed';

      return {
        result,
        driver_name,
        ...assessment,
      };
    });
    commit('setAssessments', formatted);
    commit('setResultCount', assessments.total);
  },
  async clearAssessments({ commit }) {
    commit('clearAssessments');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
