<template>
  <div>
    <v-card>
      <v-navigation-drawer
        dark
        permanent
        :expand-on-hover="computedResize"
        color="var(--nav__grey)"
        class="navigation-style"
        mini-variant-width="90"
      >
        <v-list
          min-height="100vh"
          rounded
        > <div
            class="list-style"
          >
              <v-list-item class="px-3 mb-4 mt-4">
                  <v-list-item-avatar>
                    <img class="logo-style" src="/logo/reversed-logo.png" />
                  </v-list-item-avatar>
                  <v-list-item-title
                  @click="navigateTo('staff_home')"
                  class=".text-md-body-1 text-h5 ma-2 pulse-title-style"
                  >
                      Home
                  </v-list-item-title>
              </v-list-item>
            </div>
              <v-divider></v-divider>
         <div
            class="list-style"
          >
              <v-list-item class="px-4 mb-2 mt-2 pb-2" @click="navigateTo('staff_home')">
                  <v-list-item-icon>
                    <v-icon large> mdi-magnify</v-icon>
                  </v-list-item-icon>
                    <v-list-item-title> Lookup</v-list-item-title>
              </v-list-item>
              <v-list-group
                  v-for="(item, i) in navItems"
                  :key="i"
                  class="mb-6 pb-2"
                  :value="false"
                  :prepend-icon="item.icon"
              >
                  <template v-slot:activator>
                      <v-list-item-title>{{item.name}}</v-list-item-title>
                  </template>
                  <v-list
                    :value="false"
                    link
                  >
                      <v-list-item
                          v-for="(menuItem, i) in item.menuList"
                          link
                          @click="decideRoute(menuItem)"
                          class="px-4 ma-2 mb-4"
                          :key="i"
                      >
                          <v-list-item-title
                            class="submenu-title-style"
                          >{{menuItem.label}}
                          </v-list-item-title>
                      </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
              </v-list-group>
              <v-list-item v-if="isNotProdEnvironment" index="9" @click="navigateTo('test')">
                <v-list-item-icon>
                      <v-icon large>mdi-seesaw</v-icon>
                  </v-list-item-icon>
                <v-list-item-title>Test Playground</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-4 mb-2" @click="navigateTo('staff_home')">
                  <v-list-item-icon>
                      <v-icon large>mdi-location-exit</v-icon>
                  </v-list-item-icon>
                      <v-list-item-title @click="logout"> Logout</v-list-item-title>
              </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>
<script>
export default {
  methods: {
    decideRoute(param) {
      if (param.path) this.navigateToConditionReportDynamic('condition_report_dynamic');
      else this.navigateTo(param.link);
    },
    navigateTo(param) {
      this.$router.push(this.$store.getters.getRoute(param));
    },
    navigateToConditionReportDynamic(path) {
      this.$router.push(this.$store.getters.getDynamicRoute(path, 'default'));
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
  computed: {
    navItems() {
      return this.$store.getters['pages/navbar/getNavbarItems'];
    },
    computedResize() {
      if (window.screen.availWidth > 1030) return false;
      return true;
    },
    isNotProdEnvironment() {
      return process.env.environment !== 'prod';
    },
  },
};
</script>
<style scoped>
.logo-style {
  height: 20px;
  width: auto;
}
.pulse-title-style {
  letter-spacing: 3px !important;
}
.v-card {
  border-radius: 0px !important;
  min-height: 100%;
  background-color: var(--nav__grey) !important;
}
.mdi-location-exit::before {
  font-size: 24px;
}

.mdi-magnify::before {
  font-size: 29px;
}

::v-deep .v-list .v-list-item:hover {
  background-color: #5F7A6F !important;
  color: var(--green__sweet) !important;
  font-weight: 600;
}
::v-deep .v-list .v-list-item--active {
  color: var(--green__sweet) !important;
}
.submenu-title-style {
  font-size: 15px !important;
  padding: 1em;
}
.list-style {
  background-color: var(--nav__grey_secondary) !important;
  border-radius: 25px;
  margin: 10px 10px 10px 10px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.v-list--rounded {
  padding: 0px !important;
}
</style>
