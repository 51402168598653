/* eslint-disable no-param-reassign */
import { fetchDetails, updateDetails } from './requests';
import { validateForm, validateDocuments } from './validations';

export const getters = {
  getData(store) {
    return store;
  },
  getFormDetails(store) {
    return store.formDetails;
  },
  getDocuments(store) {
    return store.documents;
  },
  getPcoExpiryDate(store) {
    return store.documents.pco.selectedDate || store.documents.pco.expiryDate;
  },
  getPoaExpiryDate(store) {
    return store.documents.poa.selectedDate || store.documents.poa.expiryDate;
  },
  getFormValidation(store) {
    return store.isFormValid;
  },
  getApplicationId(store) {
    return store.driver.applicationId;
  },
};
export const mutations = {
  setFormDetailsByApi(store, payload) {
    Object.keys(payload).forEach((item) => {
      store.formDetails[item] = payload[item];
    });
    store.documents.pco.expiryDate = payload.pco.expiryDate;
    store.documents.poa.expiryDate = payload.poa.expiryDate;
  },
  setDocumentHasExpired(store, payload) {
    Object.keys(payload).forEach((item) => {
      store.documents[item].hasExpired = payload[item].hasExpired;
    });
  },
  setDetailbyUser(store, payload) {
    store.formDetails[payload.key] = payload.value;
  },
  setPcoExpiryDate(store, payload) {
    store.documents.pco.selectedDate = payload;
  },
  setPoaExpiryDate(store, payload) {
    store.documents.poa.selectedDate = payload;
  },
  setFormValidation(store, payload) {
    store.isFormValid = payload;
  },
  resetStore(store) {
    // reset driver
    Object.keys(store.formDetails).forEach((item) => {
      store.formDetails[item] = '';
    });
    store.formDetails.uberRating = 0.0;
    // reset documents
    store.documents.pco.expiryDate = null;
    store.documents.pco.hasExpired = false;
    store.documents.pco.selectedDate = null;
    store.documents.poa.expiryDate = null;
    store.documents.poa.hasExpired = false;
    store.documents.poa.selectedDate = null;
    store.documents.poa.expiryDate = null;
    store.documents.poa.hasExpired = false;
    store.documents.poa.selectedDate = null;
  },
};

export const actions = {
  async fetchAdditionalDetails(store, applicationId) {
    await fetchDetails(store, applicationId, this);
  },
  async validateDriverDocuments({ state, commit }) {
    await validateDocuments(state, commit, this);
  },
  setDetailbyUser({ commit }, payload) {
    commit('setDetailbyUser', payload);
  },
  setPcoExpiryDate({ commit }, payload) {
    commit('setPcoExpiryDate', payload);
  },
  setPoaExpiryDate({ commit }, payload) {
    commit('setPoaExpiryDate', payload);
  },
  async updateAdditionalDetails({ state }) {
    await updateDetails(state, this);
  },
  async validateForm({ commit, state }) {
    await validateForm(state, commit);
  },
  resetStore({ commit }) {
    commit('resetStore');
  },
};

export const state = () => ({
  formDetails: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    postCode: '',
    country: '',
    driverId: '',
    driverName: '',
    dvlaNumber: '',
    applicationId: '',
    pcoNumber: '',
    dvlaTestDate: '',
    nationalInsurance: '',
    uberRating: 0.0,
    referralSource: '',
  },
  documents: {
    pco: {
      selectedDate: null,
      expiryDate: null,
      hasExpired: false,
    },
    poa: {
      selectedDate: null,
      expiryDate: null,
      hasExpired: false,
    },
  },
  isFormValid: false,
});
