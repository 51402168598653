export const state = () => ({
  page: 0,
  dealerships: [],
});

export const getters = {
  getDealerships(store) {
    return store.dealerships;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.dealerships = [];
  },
  setDealerships(store, payload) {
    store.dealerships = payload;
  },
  clearDealerships(store) {
    store.dealerships = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getDealerships({ commit, state }) {
    let url = 'dealer?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }

    const dealerships = await this.$axios.$get(url);

    commit('setDealerships', dealerships.dealer_list);
  },
  async clearDealerships({ commit }) {
    commit('clearDealerships');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
};
