<template>
  <el-card>
    <h3>Change your password</h3>
    <el-form label-position="top" :model="form" id='password-form'>
      <el-form-item label="Current password">
        <el-input v-model="form.currentPassword" show-password></el-input>
      </el-form-item>
      <el-popover
        ref="popover"
        placement="bottom"
        trigger="focus">
        <span>Your password must have:</span>
        <ul style="list-style-type:none; line-height:2em">
          <li>&nbsp;&nbsp;- At least 8 characters</li>
          <li>&nbsp;&nbsp;- A mix of uppercase and lowercase letters</li>
          <li>&nbsp;&nbsp;- At least 1 number</li>
          <li>&nbsp;&nbsp;- At least 1 symbol</li>
        </ul>
      </el-popover>
      <el-form-item label="New password"> <!-- (min 10 characters, needs a lowercase, uppercase and special character) -->
        <el-input v-popover:popover v-model="form.newPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="Confirm new password">
        <el-input v-model="form.newPasswordConfirm" show-password></el-input>
      </el-form-item>
      <el-form-item id='update'>
        <el-button type="success" @click="update">Update password</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    };
  },
  methods: {
    update() {
      if (!this.form.newPassword || !this.form.newPasswordConfirm || !this.form.currentPassword) {
        this.$message({ message: 'All fields must be filled.', type: 'error', center: true });
      } else if (this.form.newPassword == this.form.newPasswordConfirm) {
        if (this.form.currentPassword == this.form.newPassword) {
          this.$message({ message: 'Current password and new password cannot be the same.', type: 'error', center: true });
        } else {
          Auth.currentAuthenticatedUser()
            .then((user) => Auth.changePassword(user, this.form.currentPassword, this.form.newPassword))
            .then((data) => {
              this.$message({ message: 'Password has been changed successfully.', type: 'success', center: true });
            })
            .catch((err) => {
              if (err.code == 'InvalidParameterException') {
                this.$message({ message: 'Your new password does not meet complexity requirements.', type: 'error', center: true });
              } else if (err.code == 'NotAuthorizedException') {
                this.$message({ message: 'Your current password is incorrect.', type: 'error', center: true });
              } else {
                this.$message({ message: 'An unknown error occurred.', type: 'error', center: true });
              }
            });
        }
      } else {
        this.$message({ message: "Passwords don't match.", type: 'error', center: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#password-form {
  margin-top: 2em;
}
.el-card {
  padding: 15px;
}
// .div.el-card__body {
//   padding: 10px;
// }
</style>
