<template>
    <el-dialog title="Inactivity Warning" :visible="isIdle" width="50%" >
      <p>You have been inactive for 30 minutes and will be logged out shortly...</p>
      <p>Move the mouse to stay logged in.</p>
    </el-dialog>
</template>

<script>
import * as Logger from '@/utils/logger';

export default {
  data() {
    return {
      timeout: {},
      isIdle: false,
      eventListeners: [
        'drag',
        'keypress',
        'mousedown',
        'mousemove',
        'scroll',
        'touchmove',
      ],
    };
  },
  async created() {
    this.eventListeners.forEach((e) => document.addEventListener(e, this.resetTimer, false));
    this.resetTimer();
  },
  methods: {
    async resetTimer() {
      this.isIdle = false;
      localStorage.setItem('timeout.lastActivity', Date.now());
      this.clearAndResetTimeout();
    },
    async showLogoutPrompt() {
      const now = Date.now();
      const lastActivity = localStorage.getItem('timeout.lastActivity');

      if ((now - lastActivity) > this.idleTime) {
        this.isIdle = true;
        setTimeout(this.logoutIfAllTabsInactive, this.promptTime);
      } else {
        this.isIdle = false;
        this.clearAndResetTimeout();
      }
    },
    async logoutIfAllTabsInactive() {
      const now = Date.now();
      const lastActivity = localStorage.getItem('timeout.lastActivity');

      if ((now - lastActivity) > this.idleTime) {
        this.$store.dispatch('auth/logout');
      } else {
        this.isIdle = false;
        this.clearAndResetTimeout();
      }
    },
    async clearAndResetTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.showLogoutPrompt, this.idleTime);
    },
    async clearEventListeners() {
      this.eventListeners.forEach((e) => document.removeEventListener(e, this.resetTimer, false));
    },
  },
  props: {
    idleTime: {
      type: Number,
      required: true,
    },
    promptTime: {
      type: Number,
      required: true,
    },
  },
  beforeDestroy() {
    this.clearEventListeners();
  },
};
</script>
