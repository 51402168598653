/* eslint-disable no-param-reassign */
import currency from 'currency.js';
import {
  fetchOrder,
  downPayment,
  updateEVApplication,
  confirmEV,
  undoEVApplicationDetails,
  sendScriveRequest,
  createOrdwaySubscription,
  cancelAgreement,
  confirmEVWithoutChanges,
  finalDownpayment,
} from './requests';
import { createAssessmentInfoString } from './utils';

export const state = () => ({
  orderPhases: {
    initialDetails: {
      label: 'Initial Details',
      value: false,
      info: 'Not completed',
      icon: 'mdi-account',
      color: 'var(--green)',
    },
    assessment: {
      label: 'Assessment',
      value: false,
      info: '',
      icon: 'mdi-file-document-edit-outline',
      color: 'var(--purple)',
    },
    additionalDetails: {
      label: 'Additional Details',
      value: false,
      info: 'Not completed',
      icon: 'mdi-book-variant',
      color: 'var(--orange)',
    },
    plan: {
      label: 'Plan',
      value: false,
      info: 'Not selected',
      icon: 'mdi-star',
      color: 'var(--green)',
    },
    chooseModel: {
      label: 'Choose Model',
      value: false,
      info:
        'Already know the Reg? Skip this stage and assign the vehicle below',
      icon: 'mdi-gamepad-circle-left',
      color: 'var(--purple)',
    },
    assignVehicle: {
      label: 'Assign Vehicle',
      value: false,
      info: 'Not selected',
      icon: 'mdi-car',
      color: 'var(--orange)',
    },
    updatePaymentMethod: {
      label: 'Update Payment Method',
      value: false,
      info: 'Incompleted',
      icon: 'mdi-credit-card-check-outline',
      color: 'var(--green)',
    },
    downpayment: {
      label: 'Downpayment',
      value: false,
      info: 'Not completed',
      icon: 'mdi-cash',
      color: 'var(--purple)',
    },
    declaration: {
      label: 'Declaration',
      value: false,
      info: 'Not signed',
      icon: 'mdi-file-document-edit-outline',
      color: 'var(--orange)',
    },
    evPackages: {
      label: 'EV Packages',
      value: false,
      info: 'Not updated',
      icon: 'mdi-leaf',
      color: 'var(--green)',
    },
  },
  coreDetails: {
    agreementId: '',
    applicationId: '',
    applicationType: '',
    applicationNotes: '',
    applicationStatus: '',
    assessmentId: '',
    assessmentPassed: 0,
    branchName: '',
    driverId: '',
    driverMobile: '',
    driverName: '',
    pcoDocumentNo: '',
    dvlaDocumentNo: '',
    practicalRequired: false,
    theoryRequired: false,
    theoryTaken: false,
    vehicleTypeName: '',
    vehicleTypeId: '',
    collectionDate: '',
    vrm: '',
    downpaymentAmount: 0,
    vehicleTypeDownpayment: '',
    vehicleDownpayment: '',
    progressValue: 0,
    updatePaymentMethodUrl: '',
    downpaymentStatus: 'awaiting',
    finalDownpaymentAmount: 0,
    finalDownpaymentStatus: 'awaiting',
    finalDownpayment: false,
    uberCleanAirAmount: '',
    branchId: '',
    vehicle: {},
    evDetails: {
      uberCleanAirAmount: '',
      uberCleanBoolean: '',
      uberCleanConfirmed: null,
      carAndChargeBoolean: '',
      bpCardNumber: '',
      shellCardNumber: '',
      uberCleanAirPackage: {
        weeks: '',
        value: '',
      },
      savedCardDetails: {
        bpCardNumber: '',
        shellCardNumber: '',
      },
    },
    paymentCardDetails: '',
    scrive: false,
    ordway: false,
    scriveSent: false,
    scriveSigned: false,
    liveAgreementId: null,
    paidDownpaymentAmount: 0,
    friendlyFinanceInsurance: false,
    friendlyFinanceInsuranceDocument: {
      expired: '',
      expiryDate: '',
    },
    uploadedInsuranceDocumentExpiry: '',
  },
});

export const getters = {
  getCoreOrderDetails(store) {
    return store.coreDetails;
  },
  getData(store) {
    return store;
  },
  getLiveAgreementId(store) {
    return store.coreDetails.liveAgreementId;
  },
  getVehicleTypeId(store) {
    return store.coreDetails.vehicleTypeId;
  },
  getApplicationId(store) {
    return store.coreDetails.applicationId;
  },
  getApplicationNotes(store) {
    return store.coreDetails.applicationNotes;
  },
  getAssessmentId(store) {
    return store.coreDetails.assessmentId;
  },
  getProgressCheckValue(store) {
    return store.coreDetails.progressValue.value;
  },
  getUpdatePaymentUrl(store) {
    return store.coreDetails.updatePaymentMethodUrl;
  },
  getDownpaymentStatus(store) {
    return store.coreDetails.downpaymentStatus;
  },
  getDriverId(store) {
    return store.coreDetails.driverId;
  },
  getDriverName(store) {
    return store.coreDetails.driverName;
  },
  getAssignedVehicleId(store) {
    return store.coreDetails.vehicle.id;
  },
  getUberCleanAirPackage(store) {
    return store.coreDetails.evDetails.uberCleanAirPackage;
  },
  getApplicationType(store) {
    return store.coreDetails.applicationType;
  },
  getSavedEVCardDetails(store) {
    return store.coreDetails.evDetails.savedCardDetails;
  },
  getPaymentMethodDetails(store) {
    return store.coreDetails.paymentCardDetails;
  },
  getFinalDownpaymentAmount(store) {
    return store.coreDetails.finalDownpaymentAmount;
  },
  getFinalDownpaymentStatus(store) {
    return store.coreDetails.finalDownpaymentStatus;
  },
  getFFInsuranceStatus(store) {
    return store.coreDetails.friendlyFinanceInsurance;
  },
  getInsuranceExpiryDate(store) {
    return store.coreDetails.friendlyFinanceInsuranceDocument.expiryDate;
  },
};

export const mutations = {
  setOrderDetails(store, payload) {
    const { sections, data } = payload;
    // Set core details from response
    Object.keys(data).forEach((item) => {
      store.coreDetails[item] = data[item];
    });

    Object.keys(store.orderPhases).forEach((item) => {
      store.orderPhases[item].value = sections[item];
    });
    // Set Initial Details phase
    if (sections.initialDetails) { store.orderPhases.initialDetails.info = 'Completed'; }

    // Set Assessment phase
    const { assessment } = store.orderPhases;
    assessment.info = createAssessmentInfoString(data);

    // Set Additional Details phase
    if (sections.additionalDetails) { store.orderPhases.additionalDetails.info = 'Completed'; }

    // Set plan phase
    if (sections.plan) store.orderPhases.plan.info = 'Selected';
    if (data.applicationType) {
      store.orderPhases.plan.info += ` : ${
        data.applicationType.charAt(0).toUpperCase()
        + data.applicationType.slice(1)
      }`;
    }

    // Set Choose Model phase
    if (sections.chooseModel) { store.orderPhases.chooseModel.info = data.vehicleTypeName; }

    // Set Assign Vehicle phase
    if (sections.assignVehicle) { store.orderPhases.assignVehicle.info = `${data.vehicle.name} ${data.vehicle.vrm}`; }

    // Set Declaration text
    if (sections.declaration) store.orderPhases.declaration.info = 'Signed';

    // Set Payment Method URL and text if it's completed
    if (sections.updatePaymentMethod) { store.orderPhases.updatePaymentMethod.info = 'Completed'; }
    store.coreDetails.updatePaymentMethodUrl = data.updatePaymentMethodUrl;

    // Set Downpayment info
    if (sections.downpayment) store.orderPhases.downpayment.info = 'Completed';
    // Set EV Vehicle Packages info
    if (sections.evPackages) {
      store.orderPhases.evPackages.info = 'Completed';
    } else store.orderPhases.evPackages.info = 'Not Completed';
    if (store.coreDetails.evDetails.uberCleanConfirmed === true) store.orderPhases.evPackages.info += ' - Uber Clean Amount is confirmed.';
    else store.orderPhases.evPackages.info += ' - Awaiting Uber Clean Amount confirmation';
    /*
    * For an electric rental vehicle, we need to set the value and
    *  weeks details of a Clean Air Package in the initial load if the package is already set
    */
    if (store.coreDetails.evDetails.uberCleanAirAmount === 300) {
      store.coreDetails.evDetails.uberCleanAirPackage.weeks = 13;
      store.coreDetails.evDetails.uberCleanAirPackage.value = 300.00;
    } else if (store.coreDetails.evDetails.uberCleanAirAmount === 100) {
      store.coreDetails.evDetails.uberCleanAirPackage.weeks = 4;
      store.coreDetails.evDetails.uberCleanAirPackage.value = 100.00;
    } else {
      store.coreDetails.evDetails.uberCleanAirPackage.weeks = '';
      store.coreDetails.evDetails.uberCleanAirPackage.value = store.coreDetails.evDetails.uberCleanAirPackage.value;
    }
    // Set progress check value
    const totalPhase = Object.keys(store.orderPhases).length + 2; // adding and scrive and go live stage
    const phasePercentage = currency(100).divide(totalPhase);
    Object.keys(store.orderPhases).forEach((item) => {
      if (store.orderPhases[item].value) {
        store.coreDetails.progressValue = currency(
          store.coreDetails.progressValue,
        ).add(phasePercentage);
      }
    });
    if (store.coreDetails.scriveSigned) {
      store.coreDetails.progressValue = currency(store.coreDetails.progressValue)
        .add(phasePercentage);
    }
    /* Api sends the downpayment amount as 0 as default;
      / then we calculate the final downpayment in the session
      / if someone makes a partial downpayment.
      / On the second reload, api returns the finaldownpayment values that has been made.
      / so we don't need to calculate it. This is what happens in the code below
    */
    if (!store.coreDetails.finalDownpaymentAmount) {
      store.coreDetails.finalDownpaymentAmount = currency(data.vehicleDownpayment)
        .subtract(data.paidDownpaymentAmount);
    }
    // set insurance document expiring status
    store.coreDetails.friendlyFinanceInsuranceDocument.expiryDate = data.friendlyFinanceInsuranceDocument.expiryDate;
    store.coreDetails.friendlyFinanceInsuranceDocument.expired = data.friendlyFinanceInsuranceDocument.expired;
    store.coreDetails.uploadedInsuranceDocumentExpiry = data.friendlyFinanceInsuranceDocument.expiryDate;
  },
  resetStore(store) {
    // reset core details
    Object.keys(store.coreDetails).forEach((item) => {
      store.coreDetails[item] = '';
    });
    // reset order phase's values
    Object.keys(store.orderPhases).forEach((item) => {
      store.orderPhases[item].value = false;
    });
    // reset order phase's info
    store.orderPhases.initialDetails.info = 'Not Completed';
    store.orderPhases.plan.info = 'Not selected';
    store.orderPhases.additionalDetails.info = 'Not completed';
    store.orderPhases.chooseModel.info = 'Already know the Reg? Skip this stage and assign the vehicle below';
    store.orderPhases.assignVehicle.info = 'Not selected';
    store.orderPhases.evPackages.info = 'Not updated';
    store.coreDetails.evDetails = {
      uberCleanAirAmount: '',
      uberCleanBoolean: '',
      uberCleanConfirmed: null,
      carAndChargeBoolean: '',
      shellCardNumber: '',
      bpCardNumber: '',
      uberCleanAirPackage: {
        weeks: '',
        value: '',
      },
    };
    store.coreDetails.progressValue = 0;
    store.coreDetails.friendlyFinanceInsurance = undefined;
  },
  setApplicationNotes(store, value) {
    store.coreDetails.applicationNotes = value;
  },
  setVehicleTypeName(store, value) {
    store.coreDetails.vehicleTypeName = value;
  },
  setVehicleTypeId(store, value) {
    store.coreDetails.vehicleTypeId = value;
  },
  setPlanTypeName(store, value) {
    store.coreDetails.applicationType = value;
  },
  setDownpaymentStatus(store, value) {
    store.coreDetails.downpaymentStatus = value;
    if (value === 'succeeded') store.orderPhases.downpayment.value = true;
  },
  setDownpaymentAmount(store, value) {
    store.coreDetails.downpaymentAmount = value;
  },
  setFinalDownpaymentStatus(store, value) {
    store.coreDetails.finalDownpaymentStatus = value;
  },
  setUberCleanAirPackage(store, args) {
    // we keep the next 2 details in the store to show them on the payment plan summary to the user
    store.coreDetails.evDetails.uberCleanAirPackage.weeks = args.weeks;
    store.coreDetails.evDetails.uberCleanAirPackage.value = args.value;
    // we still set the uber clean amount separately to update the application.
    store.coreDetails.evDetails.uberCleanAirAmount = args.value;
  },
  setEVDetailsInStore(store, args) {
    store.coreDetails.evDetails[args.key] = args.value;
  },
  setInsuranceExpiryDate(store, value) {
    store.coreDetails.friendlyFinanceInsuranceDocument.expiryDate = value;
  },
};
export const actions = {
  async fetchOrderDetails(store, applicationId) {
    await fetchOrder(store, this, applicationId);
  },
  resetStore({ commit }) {
    commit('resetStore');
  },
  setApplicationNotes({ commit }, value) {
    commit('setApplicationNotes', value);
  },
  setVehicleType({ commit }, payload) {
    commit('setVehicleTypeName', payload.name);
    commit('setVehicleTypeId', payload.id);
  },
  setPlanTypeName({ commit }, value) {
    commit('setPlanTypeName', value);
  },
  setDownpaymentStatus({ commit }, value) {
    commit('setDownpaymentStatus', value);
  },
  setDownpaymentAmount({ commit }, value) {
    commit('setDownpaymentAmount', value);
  },
  async makeDownPayment(store) {
    await downPayment(store, this);
  },
  async updateEVApplication({ state }) {
    await updateEVApplication(state, this);
  },
  updateUberCleanAirPackage({ commit }, args) {
    commit('setUberCleanAirPackage', args);
  },
  setEVDetailsInStore({ commit }, args) {
    commit('setEVDetailsInStore', args);
  },
  async undoEVChanges({ state }) {
    await undoEVApplicationDetails(state, this);
  },
  async confirmEVSection({ state }) {
    await confirmEV(state, this);
  },
  async confirmEVNotElectric({ state }) {
    await confirmEVWithoutChanges(state, this);
  },
  async sendScrive({ state }) {
    await sendScriveRequest(state, this);
  },
  async createOrdway(store) {
    await createOrdwaySubscription(store, this);
  },
  async cancelScrive({ state }) {
    await cancelAgreement(state, this);
  },
  async makeFinalDownpayment(store) {
    await finalDownpayment(store, this);
  },
  setInsuranceExpiryDate({ commit }, payload) {
    commit('setInsuranceExpiryDate', payload);
  },
};
