import { Auth } from "aws-amplify";

const adminUsers = [
  "eng-admin@ottocar.co.uk",
  "matt@ottocar.co.uk",
  "jeff@ottocar.co.uk",
  "eddie@ottocar.co.uk ",
  "brian@ottocar.co.uk",
  "eduardo@ottocar.co.uk",
];

const partnerRoles = ["partner"];

const driverRoles = ["driver"];

const employeeRoles = [
  "assessment",
  "claims",
  "employee", // This should be removed, but is needed because eng-admin was set up with an invalid role value
  "enquiries",
  "finance",
  "fulfilment",
  "marketing",
  "operations",
  "sales",
  "services",
  "vans",
];

export function checkPermission(context, roleType) {
  Auth.currentAuthenticatedUser({
    bypassCache: false,
  })
    .then((user) => {
      const role = user.attributes["custom:role"];
      const permittedRoles = getRoles(roleType);

      if (!permittedRoles.includes(role)) {
        context.redirect(context.store.getters.getRoute("login"));
      }
    })
    .catch((err) => {
      endSession(context);
    });
}

export function loggedOut(context) {
  Auth.currentAuthenticatedUser({
    bypassCache: false,
  })
    .then((user) => {
      const role = user.attributes["custom:role"];
      let redirectPath = "";

      if (partnerRoles.includes(role)) {
        redirectPath = "fleet_batches";
      } else if (driverRoles.includes(role)) {
        if (
          user.attributes["custom:add_card"] &&
          user.attributes["custom:add_card"] == "1"
        ) {
          redirectPath = "card_dashboard";
        } else {
          redirectPath = "user_dashboard";
        }
      } else if (employeeRoles.includes(role)) {
        redirectPath = "staff_home";
      } else {
        endSession(context);
      }
      context.redirect(context.store.getters.getRoute(redirectPath));
    })
    .catch((err) => {
      // User is logged out if error is caught (so nothing to handle)
    });
}

export async function isAdmin() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });
  if (adminUsers.includes(user.attributes.email)) {
    return true;
  }
  return false;
}

function endSession(context) {
  context.store.dispatch("auth/logout");
  context.redirect(context.store.getters.getRoute("login"));
}

function getRoles(roleType) {
  switch (roleType) {
    case "partner":
      return partnerRoles;
    case "driver":
      return driverRoles;
    case "employee":
      return employeeRoles;
    case "employeeOrPartner":
      return [...partnerRoles, ...employeeRoles];
    case "all":
      return [...partnerRoles, ...driverRoles, ...employeeRoles];
    default:
      throw "InvalidRoleType";
  }
}
