export const state = () => ({
  employeeDepartments: {
    fulfilment: 'Fulfilment',
    services: 'Services',
    assessment: 'Assessment',
    finance: 'Finance',
    sales: 'Sales',
    claims: 'Claims',
    enquiries: 'Enquiries',
    operations: 'Operations',
    marketing: 'Marketing',
    vans: 'Vans',
  },
});

export const getters = {
  getEmployeeDepartments(store) {
    return store.employeeDepartments;
  },
};

export const mutations = {

};

export const actions = {

};
