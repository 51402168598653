import {
  vrmLookups,
  conditionReportSubmit,
} from './validation';

import {
  fetchData,
  submit,
} from './requests';

const REPORT_TYPES = {
  default: {
    name: 'default',
    disableVRMLookup: false,
    fetchData: fetchData.default,
    rerouteName: 'condition_report_dynamic',
    submit: submit.default,
    validateVRMLookup: vrmLookups.default,
    validateSubmit: conditionReportSubmit.default,
  },
  collectionNew: {
    name: 'collectionNew',
    disableVRMLookup: true,
    fetchData: fetchData.collectionNew,
    rerouteName: 'agreement_single',
    submit: submit.collectionNew,
    validateVRMLookup: vrmLookups.collectionNew,
    validateSubmit: conditionReportSubmit.collectionNew,
  },
  temporaryRental: {
    name: 'temporaryRental',
    disableVRMLookup: false,
    fetchData: fetchData.temporaryRental,
    rerouteName: 'previous_page',
    submit: submit.temporaryRental,
    validateVRMLookup: vrmLookups.temporaryRental,
    validateSubmit: conditionReportSubmit.temporaryRental,
  },
  returnVehicle: {
    name: 'returnVehicle',
    fetchData: fetchData.returnVehicle,
    rerouteName: 'previous_page',
    validateVRMLookup: vrmLookups.returnVehicle,
    validateSubmit: conditionReportSubmit.returnVehicle,
    submit: submit.returnVehicle,
    disableVRMLookup: true,
  },
  assignCourtesy: {
    name: 'assignCourtesy',
    fetchData: fetchData.assignCourtesy,
    rerouteName: 'services_list',
    validateVRMLookup: vrmLookups.assignCourtesy,
    validateSubmit: conditionReportSubmit.assignCourtesy,
    submit: submit.assignCourtesy,
    disableVRMLookup: false,
  },
  returnCourtesy: {
    name: 'returnCourtesy',
    fetchData: fetchData.returnCourtesy,
    rerouteName: 'services_list',
    validateVRMLookup: vrmLookups.returnCourtesy,
    validateSubmit: conditionReportSubmit.returnCourtesy,
    submit: submit.returnCourtesy,
    disableVRMLookup: true,
  },
  rentalSwap: {
    name: 'rentalSwap',
    fetchData: fetchData.rentalSwap,
    rerouteName: 'services_list',
    validateVRMLookup: vrmLookups.rentalSwap,
    validateSubmit: conditionReportSubmit.rentalSwap,
    submit: submit.rentalSwap,
    disableVRMLookup: false,
  },
  finishService: {
    name: 'finishService',
    fetchData: fetchData.finishService,
    rerouteName: 'services_list',
    validateVRMLookup: vrmLookups.finishService,
    validateSubmit: conditionReportSubmit.finishService,
    submit: submit.finishService,
    disableVRMLookup: true,
  },
};

export const state = () => ({
  conditionReportId: '',
  reason: 'placeholder',
  location: '',
  agreementId: '',
  applicationId: '',
  driverId: '',
  vehicleObject: {},
  checkList: {},
  loading: false,
  reportType: {},
  activePhase: 0,
  mileage: null,
  fuelGauge: null,
  notes: null,
});

export const getters = {
  getLoading(store) {
    return store.loading;
  },
  getActivePhase(store) {
    return store.activePhase;
  },
  isVrmLookupDisabled(store) {
    return store.reportType.disableVRMLookup;
  },
  getReportType(store) {
    return store.reportType.name;
  },
  getReason(store) {
    return store.reason;
  },
  getRerouteName(state) {
    return state.reportType.rerouteName;
  },
  getLocation(store) {
    return store.location;
  },
  getAgreementId(store) {
    return store.agreementId;
  },
  getVehicleObject(store) {
    return store.vehicleObject;
  },
  getConditionReportId(store) {
    return store.conditionReportId;
  },
  getChecklist(store) {
    return store.checklist;
  },
};

export const mutations = {
  reset(store) {
    store.reason = 'placeholder';
    store.conditionReportId = '';
    store.location = '';
    store.agreementId = '';
    store.applicationId = '';
    store.driverId = '';
    store.vehicleObject = {};
    store.checkList = {};
    store.loading = false;
    store.reportType = {};
    store.activePhase = 0;
    store.mileage = null;
    store.fuelGauge = null;
    store.notes = null;
    store.signature = null;
  },
  resetBasic(store) {
    if (store.reportType.name === 'default') store.reason = '';
    else store.reason = 'placeholder';
    store.conditionReportId = '';
    store.location = '';
    if (store.reportType.disableVRMLookup === false) store.vehicleObject = {};
    store.checkList = {};
    store.loading = false;
    store.activePhase = 0;
    store.mileage = null;
    store.fuelGauge = null;
    store.notes = null;
    store.signature = null;
  },
  setLoading(store, value) {
    store.loading = value;
  },
  setActivePhase(store, value) {
    store.activePhase = value;
  },
  setReason(store, value) {
    store.reason = value;
  },
  setLocation(store, value) {
    store.location = value;
  },
  setAgreementId(store, value) {
    store.agreementId = value;
  },
  setApplicationId(store, value) {
    store.applicationId = value;
  },
  setDriverId(store, value) {
    store.driverId = value;
  },
  setVehicleObject(store, value) {
    store.vehicleObject = value;
  },
  setConditionReportId(store, value) {
    store.conditionReportId = value;
  },
  setChecklist(store, value) {
    store.checklist = value;
  },
  setMileage(store, value) {
    store.mileage = value;
  },
  setFuelGauge(store, value) {
    store.fuelGauge = value;
  },
  setNotes(store, value) {
    store.notes = value;
  },
  setReportType(store, reportType) {
    store.reportType = REPORT_TYPES[reportType];
  },
  setSignature(store, value) {
    store.signature = value;
  },
  setDataDynamic(store, payload) {
    store[payload.field] = payload.value;
  },
  submit(store) {
    store.reportType.submit(store, this);
  },
};

export const actions = {
  reset({ commit }) {
    commit('reset');
  },
  resetBasic({ commit }) {
    commit('resetBasic');
  },
  routeBack(store, reportType) {
    return REPORT_TYPES[reportType].rerouteName;
  },
  setLoading({ commit }, value) {
    commit('setLoading', value);
  },
  setActivePhase({ commit }, value) {
    commit('setActivePhase', value);
  },
  setReason({ commit }, value) {
    commit('setReason', value);
  },
  setLocation({ commit }, value) {
    commit('setLocation', value);
  },
  setAgreementId({ commit }, value) {
    commit('setAgreementId', value);
  },
  setVehicleObject({ commit }, value) {
    commit('setVehicleObject', value);
  },
  setConditionReportId({ commit }, value) {
    commit('setConditionReportId', value);
  },
  setChecklist({ commit }, value) {
    commit('setChecklist', value);
  },
  setMileage({ commit }, value) {
    commit('setMileage', value);
  },
  setFuelGauge({ commit }, value) {
    commit('setFuelGauge', value);
  },
  setNotes({ commit }, value) {
    commit('setNotes', value);
  },
  validateVrmLookup(store, vehicleObject) {
    return store.state.reportType.validateVRMLookup(vehicleObject);
  },
  validateConditionReport(store) {
    console.log('Validation result', store.state.reportType.validateSubmit(store.state));
    return store.state.reportType.validateSubmit(store.state);
  },
  async fetchData(store, params) {
    await store.state.reportType.fetchData(store, this, params);
  },
  async submit(store) {
    await store.state.reportType.submit(store.state, this);
  },
  setReportType({ commit }, reportType) {
    const validReportTypes = Object.keys(REPORT_TYPES);

    if (!validReportTypes.includes(reportType)) throw Error('Invalid report type');
    else commit('setReportType', reportType);
  },
  setSignature({ commit }, value) {
    commit('setSignature', value);
  },
};
