export const state = () => ({
  values: [],
  filters: '',
});

export const getters = {
  fetchAllData(store) {
    return store.values;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.values = [];
    store.filters = '';
  },
  setValues(store, payload) {
    store.values = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  clearValues(store) {
    store.values = [];
  },
};

export const actions = {
  /**
   * @summary Reset all values in the vehicle store to default
   * @example
   * this.$store.dispatch("vehicles/reset");
   */
  async reset({ commit }) {
    commit('reset');
  },

  async fetchAllData({ commit, state }) {
    let url = 'vehicle/distinct_values?field=phv_exp';
    if (state.filters) {
      url += state.filters;
    }

    const value = await this.$axios.$get(url);
    // Store results
    commit('setValues', value.results);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
  async clearValues({ commit }) {
    commit('clearValues');
  },
};
