import moment from 'moment';

const currency = (val) => {
  if (typeof val !== 'number') return null;
  const result = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(val);
  return result;
};

const isoDate = (isoString) => {
  // TODO convert to this timezone (maybe)
  if (isoString) { return moment(new Date(isoString)).format('DD-MM-YYYY'); }
  return isoString;
};

export {
  currency,
  isoDate,
};
