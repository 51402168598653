export const state = () => ({
  page: 1,
  data: [],
  filters: '',
  headers: [
    { text: 'Driver', value: 'driver_name', sortable: false },
    { text: 'Vehicle', value: 'vehicle', sortable: false },
    { text: 'Branch', value: 'branch_name', sortable: false },
    { text: 'Started At', value: 'start_date', dataType: 'Date' },
    { text: 'Estimated Pickup', value: 'collection_eta' },
    { text: 'Agreed Pickup', value: 'collection_date' },
  ],
  sortBy: 'start_date',
  sortDesc: true,
  totalResults: undefined,
  itemsPerPage: undefined,
});

export const getters = {
  getData(state) {
    return state.data;
  },
  getPage(state) {
    return state.page;
  },
  getHeaders(state) { // need
    return state.headers;
  },
  getTotalResults(state) { // need
    return state.totalResults;
  },
  getItemsPerPage(state) { // need
    return state.itemsPerPage;
  },
};

export const mutations = {
  setData(store, payload) { // need
    store.data = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setTotalResults(state, value) {
    state.totalResults = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setSortDesc(state, value) {
    state.sortDesc = value;
  },
  setItemsPerPage(state, value) {
    state.itemsPerPage = value;
  },
};

export const actions = {
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
  async loadPage({ commit, state }) {
    let url = '/v2/application/';
    url += `?page=${state.page - 1}`;

    if (state.filters) url += state.filters;
    if (state.sortBy) url += `&order_by=${state.sortBy}`;
    if (state.sortDesc !== undefined) url += `&order=${state.sortDesc ? 'descending' : 'ascending'}`;
    if (state.itemsPerPage !== undefined) url += `&results=${state.itemsPerPage}`;

    const data = await this.$axios.$get(url);

    commit('setData', data.data);
    commit('setTotalResults', data.total);
  },
  setSortBy({ commit }, value) {
    commit('setSortBy', value);
  },
  setSortDesc({ commit }, value) {
    commit('setSortDesc', value);
  },
  setItemsPerPage({ commit }, value) {
    commit('setItemsPerPage', value);
  },
};
