export const state = () => ({
  page: 0,
  applications: [],
  filters: '',
  application_statuses: {
    rejected: 'Rejected',
    branch_selected: 'Branch Selected',
    plan_selected: 'Plan Selected',
    vehicle_selected: 'Vehicle Selected',
    liability_completed: 'Liability Completed',
    theory_completed: 'Theory Completed',
    practical_passed: 'Practical Passed',
    practical_failed: 'Practical Failed',
    documents_checked: 'Documents Checked',
    declaration_completed: 'Declaration Completed',
    vehicle_assigned: 'Vehicle Assigned',
    collection_arranged: 'Collection Arranged',
    downpayment_succeeded: 'Downpayment Succeeded',
    downpayment_failed: 'Downpayment Failed',
    downpayment_pending: 'Downpayment Pending',
    completed: 'Completed',
    cancelled: 'Cancelled',
  },
  application_types: {
    'r2b-classic': 'R2B',
    rental: 'Rental',
    'r2b-used': 'R2B Used',
  },
});

export const getters = {
  getApplications(store) {
    return store.applications;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getStatuses(store) {
    return store.application_statuses;
  },
  getTypes(store) {
    return store.application_types;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.applications = [];
    store.filters = '';
  },
  setApplications(store, payload) {
    store.applications = payload;
  },
  clearApplications(store) {
    store.applications = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};
export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getApplications({ commit, state }) {
    let url = '/application?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const applications = await this.$axios.$get(url);
    const formatted = applications.application_list.map((app) => {
      let vehicle_name = '';
      if (app.vehicle_type && app.vehicle_type.length > 0) {
        vehicle_name = `${app.vehicle_type[0].manufacturer} ${app.vehicle_type[0].model}`;
      } else vehicle_name = 'No vehicle type assigned';
      return {
        ...app,
        branch_name: app.branch[0].name,
        driver_name: `${app.driver[0].first_name} ${app.driver[0].last_name}`,
        driver_id: app.driver[0].id,
        vehicle_name,
      };
    });
    commit('setApplications', formatted);
  },
  async clearApplications({ commit }) {
    commit('clearApplications');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
