export const state = () => ({
  page: 0,
  vehicle_types: [],
});

export const getters = {
  getVehicleTypes(store) {
    return store.vehicle_types;
  },
  getPage(store) {
    return store.page;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.vehicle_types = [];
  },
  setVehicleTypes(store, payload) {
    store.vehicle_types = payload;
  },
  clearVehicleTypes(store) {
    store.vehicle_types = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getVehicleTypes({ commit, state }) {
    let url = 'vehicle_type?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }

    const vehicle_types = await this.$axios.$get(url);
    const with_vehicle_type_names = vehicle_types.vehicle_type_list.map((vehicle) => {
      let vehicle_type_name = '';
      if (vehicle.manufacturer) vehicle_type_name = `${vehicle_type_name} ${vehicle.manufacturer}`;

      if (vehicle.model) vehicle_type_name = `${vehicle_type_name} ${vehicle.model}`;

      return {
        ...vehicle,
        vehicle_type_name,
      };
    });
    commit('setVehicleTypes', with_vehicle_type_names);
  },
  async clearVehicleTypes({ commit }) {
    commit('clearVehicleTypes');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
