<template>
  <header>
    <n-link :to='$store.getters.getRoute("user_dashboard")'>
      <img src='~/assets/img/logo-green-dark.svg'>
    </n-link>
    <nav v-if='isAuthed'>
      <n-link :to='$store.getters.getRoute("user_profile")' v-if="!hideProfile">Profile</n-link>
      <span @click='$store.dispatch("auth/logout")'>Logout</span>
    </nav>
  </header>
</template>

<script>
export default {
  computed: {
    isAuthed() {
      return this.$store.getters['auth/isAuthed'];
    },
  },
  props: {
    hideProfile: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  header {
    background: #baeb49;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3em;
    padding: 2em 1em;
    border-bottom: 1px solid var(--grey__light);
    img {
      height: 2em;
    }
    a, span {
      margin: 0 0.5em;
      font-size: 18px;
      color: inherit;
      text-decoration: none;
      color: white;
      cursor: pointer;
    }
  }
</style>
