const validateLiabilityAnswers = (store) => {
  // check all questions have been answered
  const { questions } = store.state.liability;
  const { answers } = store.state.liability;
  return Object.keys(JSON.parse(answers)).length === questions.length;
};

const validateDocuments = async (state, commit, context) => {
  // Validate S3
  let isS3Valid = true;
  Object.keys(state.documents.dvla).forEach((docType) => {
    if (state.documents.dvla[docType].status !== 'succeeded') {
      commit('setDocumentStatus', {
        document: 'dvla',
        documentType: docType,
        status: 'failed',
      });
      isS3Valid = false;
    }
  });
  if (!isS3Valid) return false;

  // Validate RDS
  const payload = {
    driver_id: state.driver.driver_id,
    document_types: ['dvla_front', 'dvla_back'],
  };
  const rds = await context.$axios.$post('/v2/application/driver/validate_documents', payload);

  let isRDSValid = true;
  Object.keys(state.documents.dvla).forEach((docType) => {
    if (rds[docType].has_expired === 1) {
      commit('setDocumentStatus', {
        document: 'dvla',
        documentType: docType,
        status: 'failed',
      });
      isRDSValid = false;
    }
  });
  return isRDSValid;
};

module.exports = {
  validateDocuments,
  validateLiabilityAnswers,
};
