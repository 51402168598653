export const state = () => ({
  page: 0,
  pins: [],
  filters: '',
});

export const getters = {
  getPins(store) {
    return store.pins;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.pins = [];
    store.filters = '';
  },
  setPins(store, payload) {
    store.pins = payload;
  },
  clearPins(store) {
    store.pins = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getPins({ commit, state }, payload) {
    let url = `pins?results=5&vehicle=${payload}&order=due_date`;
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const pins = await this.$axios.$get(url);
    commit('setPins', pins.pin_list);
  },
  async clearVehicles({ commit }) {
    commit('clearVehicles');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
