export const state = () => ({
    depositAmount: '',
    weeklyAmount: '',
    package: '',
    status: '',
});

export const actions = {
    async submit({ state }, payload) {
        console.log('payload', payload);
        //update package via put request
        await this.$axios.put(`/v2/application/price/${payload.id}`, payload.data);
    },
};
