<template>
  <v-card id="card" class="mt-4">
    <v-data-table
      id="table"
      color="green"
      :headers="vTableHeaders"
      :items="storePath === undefined ? data : storeData"
      :server-items-length="
        storePath === undefined ? itemsTotalResults : storeTotalResults
      "
      :items-per-page="
        storePath === undefined ? itemsPerPage : storeItemsPerPage
      "
      :loading="loading === undefined ? localLoading : loading"
      @click:row="handleRowClick"
      @update:page="handlePageClick"
      @update:sort-by="handleSortBy"
      @update:sort-desc="handleSortDesc"
      :footer-props="{
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 30, 50],
      }"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :expanded="expanded"
      @update:expanded="updateExpanded"
      :item-key="itemKey"
      @update:items-per-page="handleUpdateResults"
    >
      <!-- This next bit allows us to dynamically edit cell data -->
      <template
        v-for="header in storeHeaders.map((header) => header.value)"
        v-slot:[`item.${header}`]="{ item }"
      >
        <div
          v-if="
            (item[header] == null || item[header] == '') && header !== 'action'
            && discardEmptyCellStyle.includes(item[header])
          "
          :key="item[header]"
          class="empty-cell-style"
        >
          -
        </div>
        <slot v-else v-bind:row="item" :name="header">
          {{ item[header] == null ? `-` : item[header] }}
        </slot>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <slot
          v-bind:row="{ item, headers }"
          name="expanded-item-section"
        ></slot>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'PTable',
  props: {
    itemsPerPage: {
      type: Number,
      default: 30,
    },
    data: {
      type: Array,
      default: undefined, // TODO validate undefined IF storePath given
    },
    headers: {
      type: Array,
      default: undefined, // TODO validate undefined IF storePath given
    },
    totalResults: {
      type: Number,
      default: undefined, // TODO validate undefined IF storePath given
    },
    storePath: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: undefined,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      required: false,
    },
    expandableColumnTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fakeLoading: true,
      localLoading: false,
      localPage: 1,
      start_date: 'start_date',
      expanded: [],
    };
  },
  async created() {
    if (this.storePath !== undefined) {
      this.localLoading = true;
      await this.setItemsPerPage(this.itemsPerPage);
      await this.loadPage();
      this.localLoading = false;
    }
  },
  methods: {
    handleRowClick(e) {
      this.$emit('row-click', e);
    },
    async handlePageClick(e) {
      if (this.storePath === undefined) this.$emit('update:page', e);
      else {
        this.localLoading = true;
        await this.updatePage(e);
        await this.loadPage();
        this.localLoading = false;
      }
    },
    async handleSortBy(e) {
      if (this.storePath === undefined) this.$emit('update:sort-by', e[0]);
      else {
        this.localLoading = true;
        await this.updateSortBy(e[0]);
        await this.loadPage();
        this.localLoading = false;
      }
    },
    async handleSortDesc(e) {
      if (this.storePath === undefined) this.$emit('update:sort-desc', e[0]);
      else {
        this.localLoading = true;
        await this.updateSortDesc(e[0]);
        await this.loadPage();
        this.localLoading = false;
      }
    },
    async handleUpdateResults(val) {
      if (this.storePath === undefined) { this.$emit('update:items-per-page', val); } else {
        this.localLoading = true;
        await this.setItemsPerPage(val);
        await this.loadPage();
        this.localLoading = false;
      }
    },
    async loadPage() {
      await this.$store.dispatch(`${this.storePath}/loadPage`);
    },
    async updatePage(e) {
      await this.$store.dispatch(`${this.storePath}/setPage`, e);
    },
    async updateSortBy(e) {
      await this.$store.dispatch(`${this.storePath}/setSortBy`, e);
    },
    async updateSortDesc(e) {
      await this.$store.dispatch(`${this.storePath}/setSortDesc`, e);
    },
    async setItemsPerPage(val) {
      await this.$store.dispatch(`${this.storePath}/setItemsPerPage`, val);
    },
    updateExpanded(value) {
      this.expanded = value;
    },
  },
  computed: {
    storePage() {
      if (this.storePath !== undefined) {
        return this.$store.getters[`${this.storePath}/getPage`];
      }
      return undefined;
    },
    storeData() {
      if (this.storePath !== undefined) {
        return this.$store.getters[`${this.storePath}/getData`];
      }
      return undefined;
    },
    storeHeaders() {
      if (this.storePath !== undefined) {
        return this.$store.getters[`${this.storePath}/getHeaders`];
      }
      return undefined;
    },
    storeTotalResults() {
      if (this.storePath !== undefined) {
        return this.$store.getters[`${this.storePath}/getTotalResults`];
      }
      return undefined;
    },
    storeItemsPerPage() {
      if (this.storePath !== undefined) {
        return this.$store.getters[`${this.storePath}/getItemsPerPage`];
      }
      return undefined;
    },
    vTableHeaders() {
      const tableHeaders = this.storePath === undefined ? this.headers : this.storeHeaders;
      if (this.showExpand) {
        return tableHeaders.concat({
          text: this.expandableColumnTitle,
          value: 'data-table-expand',
        });
      }
      return tableHeaders;
    },
    discardEmptyCellStyle() {
      return [
        'balloonValue',
      ];
    },
  },
};
</script>

<style lang="scss">
#table thead tr th {
  border-bottom: 1.2px solid var(--grey__mid) !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-weight: 600;
  text-align: center;
}
#table thead tr th i {
  color: #ffffff !important;
}
#card {
  background-color: var(--purple);
  padding-top: 0px;
}
#table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

#table td #table th {
  border: none;
  padding: 8px;
}

#table tr:nth-child(even) {
  background-color: #fafafa;
}

#table tr:hover {
  background-color: #ddd !important;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--grey__dark);
  color: white;
}
.v-data-table__wrapper {
  border-radius: 20px;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
  padding: 1em;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content
  td {
  color: var(--grey__slate) !important;
  font-size: 12px;
}
</style>
