<template>
  <div>
    <Header />
    <div id='inner'>
      <nuxt class='layout' />
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header';

export default {
  created() {

  },
  computed: {
    activePhase() {
      return this.$store.getters['branchapplication/getApplicationPhase'];
    },
  },
  components: {
    Header,
  },
};
</script>

<style scoped>
#inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1em;
}
</style>
