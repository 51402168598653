export const state = () => ({
  serviceTypes: {
    repair: 'Body Repair',
    mot: 'MOT',
    phv: 'PHV',
    service: 'Service',
    non_warranty: 'Non Warranty',
    warranty: 'Warranty',
  },
  checkInReasons: [
    {
      type: 'Check In',
      label: 'Accident',
      color: '#54CEE7',
      value: 'accident',
    },
    {
      type: 'Check In',
      label: 'Repair: Warranty',
      color: '#54CEE7',
      value: 'repair-warranty',
    },
    {
      type: 'Check In',
      label: 'Repair: Non-warranty',
      color: '#54CEE7',
      value: 'repair-other',
    },
    {
      type: 'Check In',
      label: 'MOT',
      color: '#54CEE7',
      value: 'mot',
    },
    {
      type: 'Check In',
      label: 'PHV issue',
      color: '#54CEE7',
      value: 'phv-problem',
    },
    {
      type: 'Check In',
      label: 'Drop',
      color: '#FE5656',
      value: 'drop',
    },
    {
      type: 'Check In',
      label: 'Pull off',
      color: '#FE5656',
      value: 'pull-off',
    },
    {
      type: 'Check In',
      label: 'Terminate',
      color: '#FE5656',
      value: 'terminated',
    },
  ],
});

export const getters = {
  getTypes(store) {
    return store.serviceTypes;
  },
  getCheckInReasons(store) {
    return store.checkInReasons;
  },
};
