<template>
  <div>
    <Header hide-profile />
    <nuxt id='inner' class='layout' />
  </div>
</template>

<script>
import Header from '@/components/common/Header';

export default {
  components: {
    Header,
  },
};
</script>

<style scoped lang='scss'>
#inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1em;
}
</style>
