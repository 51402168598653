<template>
<v-app>
  <v-main>
  <div>
    <el-container id="container">
      <NavbarV2 v-if="isAuthed" />
      <el-main>
        <el-row>
          <el-col id="topbar">
            <Topbar v-if="isAuthed" />
          </el-col>
        </el-row>
        <el-row>
          <nuxt id="inner" />
        </el-row>
      </el-main>
    </el-container>
    <Timeout :idleTime="idleTime" :promptTime="promptTime" />
  </div>
  </v-main>
  </v-app>
</template>

<script>
import NavbarV2 from '@/components/layout/NavbarV2';
import Topbar from '@/components/layout/Topbar';
import Timeout from '@/components/common/Timeout';

export default {
  computed: {
    isAuthed() {
      return this.$store.getters['auth/isAuthed'];
    },
  },
  methods: {

  },
  components: {
    NavbarV2,
    Topbar,
    Timeout,
  },
  data() {
    return {
      idleTime: 1800000, // 30 minutes
      promptTime: 10000, // 10 seconds
    };
  },
  errorCaptured(e) {
    if (process.env.environment !== 'prod') {
      console.log('ERROR CAPTURED', e);
      this.$nuxt.error(e);
    }
  },
};
</script>

<style lang="scss" scoped>
#container {
  min-height: 100vh;
  background-color: #f4f4f5;
}
::v-deep .el-main {
  padding: 0;
  overflow-x: hidden;
}
#inner {
  padding: 1.5em;
  background-color: #f4f4f5;
}
.el-menu.el-menu--horizontal {
  border: none !important;
}
.el-menu-item.is-active {
  border-bottom: none !important;
}
#type {
  position: fixed;
  right: 0;
  bottom: 0;
  background: var(--orange);
  color: white;
  padding: 0.5em;
}
.custom-icon {
  font-size: 24px;
}
</style>
