export const state = () => ({
  data: {},
  vehicle: {},
  driver: {},
  agreement: {},
  filters: '',
  telematics: {
    samsara: 'Samsara',
    kasava: 'Kasava',
    'taxi-witness': 'Taxi-Witness',
  },
  condition: {
    heavily_damaged: 'Heavily Damaged',
    write_off: 'Write Off',
  },
  write_off_reason: {
    cat_a: 'Cat A',
    cat_b: 'Cat B',
    cat_s: 'Cat S',
    cat_n: 'Cat N',
  },
  vehicleSuitable: true,
});

export const getters = {
  getData(store) {
    return store.data;
  },
  getVehicle(store) {
    return store.vehicle;
  },
  getAgreement(store) {
    return store.agreement;
  },
  getDriver(store) {
    return store.driver;
  },
  getFilters(store) {
    return store.filters;
  },
  getTelematics(store) {
    return store.telematics;
  },
  getVehicleSuitable(store) {
    return store.vehicleSuitable;
  },
  getVehicleCondition(store) {
    return store.condition;
  },
  getWriteOffReasons(store) {
    return store.write_off_reason;
  },
};
export const mutations = {
  reset(store) {
    store.data = {};
    store.filters = '';
    store.telematics = {};
  },
  clearData(store) {
    store.data = {};
    store.setIfVehicleSuitable = true;
  },
  setData(store, payload) {
    store.data = payload;
  },
  setVehicle(store, payload) {
    store.vehicle = payload;
  },
  setAgreement(store, payload) {
    store.agreement = payload;
  },
  setDriver(store, payload) {
    store.driver = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setTelematics(store, payload) {
    store.telematics = payload;
  },
  setIfVehicleSuitable(store, payload) {
    store.vehicleSuitable = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async clearData({ commit }) {
    commit('clearData');
  },
  async fetchData({ commit, state }, vehicle) {
    const url = `/v2/fleet/vehicle/${vehicle}`;
    const data = await this.$axios.$get(url);
    commit('setData', data);
    if (data) {
      commit('setVehicle', data.vehicle);
      commit('setDriver', data.driver);
      commit('setAgreement', data.agreement);
    }
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
  setIfVehicleSuitable(store, param) {
    store.commit('setIfVehicleSuitable', param);
  },
  async updateVehicle({ state }, data) {
    const vehicleId = state.vehicle.id;
    const url = `/v2/fleet/vehicle/${vehicleId}`;
    await this.$axios.$put(url, data);
  },
};
