export const state = () => ({
  pcnList: [],
  filters: '',
});

export const getters = {
  getPcnList(state) {
    return state.pcnList;
  },
};

export const mutations = {
  reset(state) {
    state.pcnList = [];
  },
  setPcnList(state, payload) {
    state.pcnList.push(payload);
  },
  clearPcnList(state) {
    state.pcnList = [];
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async setPcnList({ commit, state }, payload) {
    commit('setPcnList', payload);
  },
  async clearPcnList({ commit }) {
    commit('clearPcnList');
  },
};
