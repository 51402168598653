<template>
    <div class="pagination-wrapper">
        <el-row type="flex" class="row-bg" justify="right" style="float: right">
            <div class="block">
                <el-pagination
                    @current-change="change"
                    :current-page="value"
                    :page-size="pageSize"
                    layout="prev, pager, next, total"
                    :total="results"
                    background
                ></el-pagination>
            </div>
        </el-row>
    </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 30,
    },
  },
  methods: {
    change(newCurrentPage) {
      this.$emit('change');
      this.$emit('input', newCurrentPage);
    },
  },
};
</script>

<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #97d700;
  color: #ffffff;
}
.el-tag.el-tag--warning {
  border-color: #f0f9eb !important;
  border-radius: 25px;
}
.el-tag.el-tag--info {
  border-color: #f0f9eb !important;
  border-radius: 25px;
}
.pagination-wrapper {
    margin: 1em 0;
}
</style>
