export const state = () => ({
  page: 0,
  conditionReports: [],
  filters: '',
  resultCount: 0,
});

export const getters = {
  getConditionReports(store) { // to be removed once confirmed no longer being used
    return store.conditionReports;
  },
  getData(store) {
    return store.conditionReports;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.conditionReports = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setConditionReports(store, payload) {
    store.conditionReports = payload;
  },
  clearConditionReports(store) {
    store.conditionReports = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getConditionReports({ commit, state }) { // to be removed once confirmed no longer being used
    let url = 'condition_report?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const conditionReports = await this.$axios.$get(url);
    const withVehicleName = conditionReports.condition_report_list.map(((report) => {
      const reg = `${report.vehicle[0].vrm}`;
      let vehicle_name = '';
      if (report.vehicle && report.vehicle.length > 0) {
        vehicle_name = `${report.vehicle[0].manufacturer} ${report.vehicle[0].model} ${report.vehicle[0].color}`;
      }
      return {
        vehicle_name,
        reg,
        ...report,
      };
    }));
    commit('setConditionReports', withVehicleName);
    commit('setResultCount', conditionReports.total);
  },
  async fetchData({ commit, state }) {
    let url = 'condition_report?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const conditionReports = await this.$axios.$get(url);
    const withVehicleName = conditionReports.condition_report_list.map(((report) => {
      const reg = `${report.vehicle[0].vrm}`;
      let vehicle_name = '';
      if (report.vehicle && report.vehicle.length > 0) {
        vehicle_name = `${report.vehicle[0].manufacturer} ${report.vehicle[0].model} ${report.vehicle[0].color}`;
      }
      return {
        vehicle_name,
        reg,
        ...report,
      };
    }));
    commit('setConditionReports', withVehicleName);
    commit('setResultCount', conditionReports.total);
  },
  async clearConditionReports({ commit }) {
    commit('clearConditionReports');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
