export const defaultState = () => ({
  countries: ['United Kingdom'],
});

export const state = () => ({
  countries: ['United Kingdom'],
});

export const getters = {
  getCountries(state) {
    return state.countries;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  reset(state) {
    state.countries = ['United Kingdom'];
  },
};
