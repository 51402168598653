export const state = () => ({
  productType: 'friendly-finance',
  balloonValue: false,
  name: '',
  description: '',
  totalWeeks: '',
  status: '',
  insurance: false,
  servicing: false,
  vehicle_type: '',
});

export const getters = {
  getVehicleType(state) {
    return state.vehicle_type;
  },
};

export const mutations = {
  setVehicleType(store, payload) {
    store.vehicle_type = payload;
  },
  resetStore(store) {
    store.productType = 'friendly-finance';
    store.balloonValue = false;
    store.name = '';
    store.description = '';
    store.totalWeeks = '';
    store.status = '';
  },
};

export const actions = {
  resetStore({ commit }) {
    commit('resetStore');
  },
  async submit({ state }, payload) {
    await this.$axios.put(`/v2/application/package/${payload.id}`, payload.data);
  },
  setVehicleType({ commit }, payload) {
    commit('setVehicleType', payload);
  },
};
