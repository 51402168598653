import Vue from 'vue';

// Form
import PButton from '@/components/common/form/PButton.vue';
import PBtn from '@/components/common/form/PBtn.vue';
import PSlider from '@/components/common/form/PSlider.vue';

import PInput from '@/components/common/form/PInput.vue';
import PSelectAgreement from '@/components/common/form/PSelectAgreement.vue';
import PSelect from '@/components/common/form/PSelect.vue';
import PVrmLookup from '@/components/common/form/PVrmLookup.vue';
import PDatePicker from '@/components/common/form/PDatePicker';
import PVDatePicker from '@/components/common/form/Vuetify/PVDatePicker';
import PInfo from '@/components/common/form/PInfo';
import PHeader from '@/components/common/form/PHeader';

// Table
import PFilters from '@/components/common/table/PFilters.vue';
import PPagination from '@/components/common/table/PPagination.vue';
import PTable from '@/components/common/table/PTable.vue';
import PTabs from '@/components/common/table/PTabs.vue';

// Card
import PSummaryCard from '@/components/common/card/PSummaryCard.vue';
import PCard from '@/components/common/card/PCard.vue';

// Form
Vue.component('p-button', PButton);
Vue.component('p-input', PInput);
Vue.component('p-select', PSelect);
Vue.component('p-select-agreement', PSelectAgreement);
Vue.component('p-vrm-lookup', PVrmLookup);

// Table
Vue.component('p-pagination', PPagination);
Vue.component('p-tabs', PTabs);

// Card
Vue.component('p-card', PCard);
Vue.component('p-summary-card', PSummaryCard);

// Date picker
Vue.component('p-date-picker', PDatePicker);

// After Vuetify

Vue.component('p-table', PTable);
Vue.component('p-filters', PFilters);
Vue.component('pv-date-picker', PVDatePicker);
Vue.component('p-btn', PBtn);
Vue.component('p-info', PInfo);
Vue.component('p-header', PHeader);
Vue.component('p-slider', PSlider);
