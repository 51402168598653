const fetchVehicleList = async (commit, state, context) => {
  let url = "/v2/application/vehicle/assignable";
  if (!state.filters) url += `?page=${state.page - 1}`;
  else url += "?page=0";
  if (state.filters) url += state.filters;
  if (state.itemsPerPage !== undefined) url += `&results=${state.itemsPerPage}`;
  const response = await context.$axios.$get(url);
  commit("setData", response.data);
  commit("setTotalResults", response.total);
};

module.exports = {
  fetchVehicleList,
};
