import { Auth } from 'aws-amplify';

const applicationRoutes = {
  branch_selected: 'application_waiver',
  liability_completed: 'application_choose_plan',
  plan_selected: 'application_choose_vehicle',
  vehicle_selected: 'application_assessment',
  theory_completed: 'application_practical',
  practical_passed: 'application_review',
  practical_failed: 'user_dashboard',
  documents_checked: 'application_declaration',
  declaration_completed: 'application_success',
  downpayment_failed: 'user_dashboard',
  downpayment_succeeded: 'user_dashboard',
  downpayment_pending: 'user_dashboard',
};

const applicationOtherRoutes = {
  branch_selected: 'application_waiver',
  liability_completed: 'application_choose_plan',
  plan_selected: 'application_other_choose_vehicle',
  vehicle_selected: 'application_other_assessment',
  theory_completed: 'application_other_practical',
  practical_passed: 'application_other_review',
  practical_failed: 'user_dashboard',
  documents_checked: 'application_other_declaration',
  declaration_completed: 'application_other_success',
};

const requiredFields = [
  'dvla_test_date',
  'dvla_document_no',
  'dvla_expiry_date',
  'pco_document_no',
  'pco_expiry_date',
  'national_insurance_number',
];

const requiredDocuments = [
  'dvla_front',
  'dvla_back',
  'poa',
  'pco',
];

export default function (context) {
  // console.log(context, context.$axios)
  // Get information about the current user from Cognito (using cache if available)
  Auth.currentAuthenticatedUser({
    bypassCache: false,
  }).then((resp) => {
    const userId = resp.attributes['custom:user_id'];
    let route = {};
    // Fetch application details
    context.store.dispatch('application/fetchApplication', userId)
      .then((_) => {
        // Get application, driver and driverDocs from store
        const application = context.store.getters['application/getApplication'];
        const driver = context.store.getters['application/getDriver'];
        const driverDocs = context.store.getters['application/getDriverDocs'];

        // Get application_status if application exists
        let status = '';
        if (application) {
          status = application.application_status;
        }
        let type = '';
        if (application) {
          type = application.application_type;
        }

        // Get the route of the page that the user navigated to
        const currentRoute = context.app.router.currentRoute.name;

        // Determine correct route
        if (!isProfileComplete(driver)) {
          // Mark correct route as profile if licence information is incomplete
          route = context.store.getters.getRoute('user_profile');
          context.app.router.app.$message({ message: 'Please fill out your National Insurance, DVLA and PCO licence details before starting your application.', type: 'error', center: true });
        } else if (!isDocumentsComplete(driverDocs)) {
          // Mark correct route as profile if documents are missing
          route = context.store.getters.getRoute('user_profile');
          context.app.router.app.$message({ message: 'Please upload all required documents before starting your application.', type: 'error', center: true });
        } else if (!application) {
          // Mark correct route as start of application if no application exists
          route = context.store.getters.getRoute('application_start');
        } else if (application.paused) {
          route = context.store.getters.getRoute('user_profile');
          context.app.router.app.$message({ message: 'Your application has been paused. Please return to an Otto Hub to continue with your application.', type: 'error', center: true });
        } else if (Object.keys(applicationRoutes).includes(status)) {
          // Mark correct route using status -> route map
          if (application.application_type == 'r2b-classic') route = context.store.getters.getRoute(applicationRoutes[status]);
          else route = context.store.getters.getRoute(applicationOtherRoutes[status]);
        } else {
          // Mark correct route as profile if active application has an invalid status
          route = context.store.getters.getRoute('user_dashboard');
          context.app.router.app.$message({ message: 'You currently have an active application beyond this stage so you cannot create a new one.', type: 'error', center: true });
        }

        // If the correct route differs from the current route, redirect to correct route
        if (currentRoute != route.name) {
          context.redirect(route);
        }
      }).catch((err) => {
        console.log(err);
        // Redirect to user profile if an unhandled error occurs
        context.redirect(context.store.getters.getRoute('user_dashboard'));
        context.app.router.app.$message({ message: 'An error has occurred. Please try again, or contact the engineering team if the issue persists.', type: 'error', center: true });
      });
  });
}

/**
 * Function to determine if the driver's profile is complete.
 *
 * @param {Object} driver the driver object retrieved from store
 * @returns {Boolean} true if all licence details are filled out, or false if something is missing.
 */
function isProfileComplete(driver) {
  return requiredFields.every((field) => driver[field]);
}

/**
 * Function to determine if the driver has uploaded all required documents.
 *
 * @param {Array<Object>} driverDocs the driver documents array retrieved from store
 * @returns {Boolean} true if all documents have been uploaded, or false if something is missing.
 */
function isDocumentsComplete(driverDocs) {
  return requiredDocuments.every((docType) => driverDocs.some((e) => e.document_type === docType));
}
