export const state = () => ({
  page: 0,
  authorities: [],
  filters: '',
  resultCount: 0,
});

export const getters = {
  getData(store) {
    return store.authorities;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.authorities = [];
    store.filters = '';
  },
  setAuthorities(store, payload) {
    store.authorities = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async fetchData({ commit, state }, payload) {
    let url = '/payment_authority?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const authorityList = await this.$axios.$get(url);
    commit('setAuthorities', authorityList.authorities_list);
    commit('setResultCount', authorityList.total);
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
