export const state = () => ({
  page: 0,
  vehicles: [],
  filters: '',
  fuel_types: {
    ev: 'EV',
    hybrid: 'Hybrid',
    diesel: 'Diesel',
    'plug-in': 'Plug-In',
  },
  statuses: {
    accident: 'Accident',
    'accident-courtesy': 'Accident (Has Courtesy)',
    aftercare: 'Aftercare',
    assigned: 'Assigned',
    available: 'Available',
    'awaiting-checkout': 'Awaiting Checkout',
    awaiting_evaluation: 'Awaiting Evaluation',
    collection_arranged: 'Collection Arranged',
    'courtesy-assigned': 'Courtesy Assigned',
    'in-servicing': 'In Servicing',
    live: 'Live',
    mot: 'MOT',
    'mot-courtesy': 'MOT (Has Courtesy)',
    no_sale: 'Do Not Sell',
    ordered: 'Ordered',
    'phv-problem': 'PHV Problem',
    'phv-problem-courtesy': 'PHV Problem (Has Courtesy)',
    'repair-other': 'Repair: Non-Warranty',
    'repair-other-courtesy': 'Repair: Non-Warranty (Has Courtesy)',
    'repair-warranty': 'Repair: Warranty',
    'repair-warranty-courtesy': 'Repair: Warranty (Has Courtesy)',
    sold: 'Sold',
    'paid-off': 'Paid Off',
    archived: 'Archived',
    auction: 'Auction',
  },
  editableStatuses: {
    // '': 'Select status',
    sold: 'Sold',
    archived: 'Archived',
    auction: 'Auction',
  },
  suitable_types: {
    'r2b-used': 'R2B Used',
    rental: 'Rental',
    courtesy: 'Courtesy',
  },
  locations_output: {
    HA97NQ: 'A1 Repairs',
    W106SA: 'AK Auto repairs',
    NW106EU: 'A-Z Repairs',
    TW89HF: 'B&R Coachworks',
    E114HD: 'Bansal',
    'W6 0LH': 'Britannia House',
    KT147LF: 'Byfleet Ford',
    'CF24 3JW': 'Cardiff',
    SL25EA: 'City Gate Kia',
    'NW9 0HL': 'Colindale',
    'SE21 8EN': 'Dulwich',
    SE42JL: 'Dundalk Motors',
    GU124NU: 'Garland Motors',
    TW89FX: 'GWR Kia',
    'W6 9EA': 'Hammersmith',
    'IG4 5EY': 'Ilford',
    'OL9 6BY': 'Manchester',
    DA15FD: 'Mercedes-Benz Dartford',
    E152NF: 'Mercedes-Benz Stratford',
    'W5 4TT': 'Northfields',
    'NW10 6EX': 'Park Royal',
    TW148NW: 'Rygor Mercedes',
    AL97HN: 'S&B Commercials',
    W54TT: 'Suri Motors',
    WD188AD: 'Steven Eagell Toyota',
    W30SL: 'Tesla Dealership',
    'HA9 7RG': 'Wembley',

  },
  locations_input: {
    HA97NQ: 'A1 Repairs',
    W106SA: 'AK Auto repairs',
    NW106EU: 'A-Z Repairs',
    TW89HF: 'B&R Coachworks',
    E114HD: 'Bansal',
    'W6 0LH': 'Britannia House',
    KT147LF: 'Byfleet Ford',
    'CF24 3JW': 'Cardiff',
    SL25EA: 'City Gate Kia',
    'NW9 0HL': 'Colindale',
    'SE21 8EN': 'Dulwich',
    SE42JL: 'Dundalk Motors',
    GU124NU: 'Garland Motors',
    TW89FX: 'GWR Kia',
    'W6 9EA': 'Hammersmith',
    'IG4 5EY': 'Ilford',
    'OL9 6BY': 'Manchester',
    DA15FD: 'Mercedes-Benz Dartford',
    E152NF: 'Mercedes-Benz Stratford',
    'W5 4TT': 'Northfields',
    'NW10 6EX': 'Park Royal',
    TW148NW: 'Rygor Mercedes',
    AL97HN: 'S&B Commercials',
    W54TT: 'Suri Motors',
    WD188AD: 'Steven Eagell Toyota',
    W30SL: 'Tesla Dealership',
    'HA9 7RG': 'Wembley',
  },
  vehicle_source: {
    LEUMI: 'Leumi Bank',
    OTTOCAR: 'Ottocar',
  },
  resultCount: 0,
});

export const getters = {
  getVehicles(store) {
    return store.vehicles;
  },
  getData(store) {
    return store.vehicles;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getFuelTypes(store) {
    return store.fuel_types;
  },
  getStatuses(store) {
    return store.statuses;
  },
  getVehicleSources(store) {
    return store.vehicle_source;
  },
  getSuitableTypes(store) {
    return store.suitable_types;
  },
  getLocationsOutput(store) {
    return store.locations_output;
  },
  getLocationsInput(store) {
    return store.locations_input;
  },
  getResultCount(store) {
    return store.resultCount;
  },
  getEditableStatuses(store) {
    return store.editableStatuses;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.vehicles = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setVehicles(store, payload) {
    store.vehicles = payload;
  },
  clearVehicles(store) {
    store.vehicles = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  /**
   * @summary Reset all values in the vehicle store to default
   * @example
   * this.$store.dispatch("vehicles/reset");
   */
  async reset({ commit }) {
    commit('reset');
  },
  /**
   * @summary Get the current page of vehicles based on the filters set
   * @example
   * this.$store.dispatch("vehicles/fetchData");
   */
  async fetchData({ commit, state }) {
    let url = '/vehicle?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const vehicles = await this.$axios.$get(url);
    const withDriversName = vehicles.vehicle_list.map((vehicle) => {
      const payload = vehicle;
      if (vehicle.driver && vehicle.driver.length > 0) {
        payload.driver_name = `${vehicle.driver[0].first_name} ${vehicle.driver[0].last_name}`;
      }
      if (vehicle.insurance && vehicle.insurance.length > 0) {
        payload.insurance = vehicle.insurance[0].policy_number;
      }
      return payload;
    });
    commit('setVehicles', withDriversName);
    commit('setResultCount', vehicles.total);
  },
  /**
   * @summary Get the current page of vehicles based on the filters set
   * @example
   * this.$store.dispatch("vehicles/getVehicles");
   * @deprecated replaced by fetchData()
   */
  async getVehicles({ commit, state }) { // Added for backwards compatability - to be removed once mixins have been fully integrated
    let url = 'vehicle?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const vehicles = await this.$axios.$get(url);
    const withDriversName = vehicles.vehicle_list.map((vehicle) => {
      const payload = vehicle;
      if (vehicle.driver && vehicle.driver.length > 0) {
        payload.driver_name = `${vehicle.driver[0].first_name} ${vehicle.driver[0].last_name}`;
        payload.email = `${vehicle.driver[0].email}`;
        payload.mobile_phone = `${vehicle.driver[0].mobile_phone}`;
      } else vehicle.driver_name = 'Currently with no driver';
      if (vehicle.insurance && vehicle.insurance.length > 0) {
        payload.insurance = vehicle.insurance[0].policy_number;
      }
      return payload;
    });
    commit('setVehicles', withDriversName);
    commit('setResultCount', vehicles.total);
  },
  /**
   * @summary Fetches all vehicles in the database that match the filters set
   * @example
   * this.$store.dispatch("vehicles/fetchAllData");
   */
  async fetchAllData({ commit, state }) {
    let url = 'vehicle?results=50';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    // Fetch first batch
    const firstResp = await this.$axios.$get(url);

    // Calculate number of pages of results
    const pageCount = Math.floor((firstResp.total / firstResp.results));

    // Fetch each page of results
    const pageNumbers = Array.from(Array(pageCount).keys());
    const vehiclePages = pageNumbers.map((page) => {
      const resp = this.$axios.$get(`${url}&page=${page}`);
      return resp;
    });

    // Concatenate all pages into one array
    let vehicles = [];
    Promise.all(vehiclePages).then((values) => {
      const vehicleLists = values.map((val) => val.vehicle_list);
      vehicles = vehicles.concat(...vehicleLists);
    });

    // Store results
    commit('setVehicles', vehicles);
    commit('setResultCount', firstResp.total);
  },
  /**
   * @summary Clears the current vehicle list
   * @example
   * this.$store.dispatch("vehicles/clearVehicles");
   */
  async clearVehicles({ commit }) {
    commit('clearVehicles');
  },
  /**
   * @summary Sets the page to be fetched in the next GET request
   * @example
   * this.$store.dispatch("vehicles/setPage", 1);
   *
   * @param {int} payload - The page number to be set
   */
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  /**
   * @summary Sets the page to be fetched in the next GET request
   * @example
   * this.$store.dispatch("vehicles/setFilters", "&vrm=AB12CDE&status=Available");
   *
   * @param {String} payload - The filters to be set
   */
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
