const fetchOrder = async (store, context, applicationId) => {
  const response = await context.$axios.$get(
    `/v2/application/progress_check/${applicationId}`,
  );

  // Calculate downpayment we want to show
  const paidDownpaymentAmount = response.data.paid_downpayment_amount;
  const planType = response.data.application_type;
  const vehicleTypeDownpayment = response.data.vehicle_type_downpayment;
  const vehicleDownpayment = response.data.vehicle_downpayment;

  let downpaymentAmount;
  if (response.sections.downpayment) {
    downpaymentAmount = paidDownpaymentAmount;
  } else if (planType === 'friendly-finance') downpaymentAmount = vehicleDownpayment;
  else downpaymentAmount = planType === 'rental' ? 250.00 : vehicleTypeDownpayment;

  // we are reformatting the response for front end usage
  const formattedResponse = {
    data: {
      applicationId,
      agreementId: response.data.agreement_id,
      applicationType: response.data.application_type === null ? '' : response.data.application_type,
      applicationNotes: response.data.application_notes,
      assessmentId: response.data.assessment_id,
      assessmentPassed: response.data.assessment_passed,
      branchName: response.data.branch_name,
      driverId: response.data.driver_id,
      driverMobile: response.data.driver_mobile,
      driverName: response.data.driver_name,
      pcoDocumentNo: response.data.pco_document_no,
      dvlaDocumentNo: response.data.dvla_document_no,
      practicalRequired: response.data.practical_required,
      practicalTaken: response.data.practical_taken,
      theoryRequired: response.data.theory_required,
      theoryTaken: response.data.theory_taken,
      vehicleTypeName: response.data.vehicle_type_name,
      vehicleTypeId: response.data.vehicle_type_id,
      collectionDate: response.data.collection_date,
      liveAgreementId: response.data.live_agreement_id,
      vrm: response.data.vehicle.vrm,
      downpaymentAmount,
      vehicleTypeDownpayment: response.data.vehicle_type_downpayment,
      vehicleDownpayment: response.data.vehicle_downpayment,
      paidDownpaymentAmount: response.data.paid_downpayment_amount,
      finalDownpayment: response.sections.finalDownPayment,
      finalDownpaymentAmount: response.data.final_downpayment_amount,
      finalDownpaymentStatus: response.data.final_downpayment_status,
      ordwayCustomerId: response.data.ordway_customer_id,
      updatePaymentMethodUrl: response.data.update_payment_method_url,
      downpaymentStatus: response.data.downpayment_status,
      assignedVehicleId: response.data.vehicle.id,
      evDetails: {
        carAndChargeBoolean: response.data.car_and_charge_selected,
        uberCleanBoolean: response.data.has_uber_clean_fund,
        uberCleanConfirmed: response.data.uber_clean_air_confirmed,
        uberCleanAirAmount: response.data.uber_clean_air_amount,
        uberCleanAirPackage: {
          weeks: '',
          value: 0,
        },
        bpCardNumber: response.data.bp_card_id,
        shellCardNumber: response.data.shell_card_id,
        savedCardDetails: {
          bpCardNumber: response.data.bp_card_id,
          shellCardNumber: response.data.shell_card_id,
        },
      },
      vehicle: response.data.vehicle,
      scrive: response.sections.scrive,
      scriveSent: response.data.scrive_sent,
      scriveSigned: response.data.scrive_signed,
      applicationStatus: 'pending',
      paymentCardDetails: response.data.payment_method_account_number,
      branchId: response.data.branch_id,
      friendlyFinanceInsurance: response.sections.friendlyFinanceInsurance,
      friendlyFinanceInsuranceDocument: {
        expired: response.data.documents.insurance === undefined ? '' : response.data.documents.insurance.has_expired,
        expiryDate: response.data.documents.insurance === undefined ? '' : response.data.documents.insurance.expiry_date,
      },
    },
    sections: {
      initialDetails: response.sections.initial_details,
      assessment: response.sections.assessment,
      additionalDetails: response.sections.additional_details,
      plan: response.sections.plan,
      chooseModel: response.sections.choose_model,
      assignVehicle: response.sections.assign_vehicle,
      declaration: response.sections.declaration,
      updatePaymentMethod: response.sections.update_payment_method,
      downpayment: response.sections.downpayment,
      evPackages: response.sections.evPackages,
    },
  };
  store.commit('setOrderDetails', formattedResponse);
};

const downPayment = async (store, context) => {
  const payload = {
    application_id: store.state.coreDetails.applicationId,
    downpayment_amount: store.state.coreDetails.downpaymentAmount,
  };
  store.commit('setDownpaymentStatus', 'pending');
  const response = await context.$axios.$post(
    '/v2/application/downpayment',
    payload,
  );
  store.commit('setDownpaymentStatus', response.status);
};
const finalDownpayment = async (store, context) => {
  const payload = {
    application_id: store.state.coreDetails.applicationId,
    final_downpayment_amount: store.state.coreDetails.finalDownpaymentAmount,
  };
  store.commit('setFinalDownpaymentStatus', 'pending');
  const response = await context.$axios.$post(
    '/v2/application/finalDownpayment',
    payload,
  );
  store.commit('setFinalDownpaymentStatus', response.status);
};

const updateEVApplication = async (state, context) => {
  const {
    uberCleanAirAmount,
    uberCleanBoolean,
    carAndChargeBoolean,
    bpCardNumber,
    shellCardNumber,
  } = state.coreDetails.evDetails;
  const payload = {
    has_uber_clean_fund: uberCleanBoolean === null ? false : uberCleanBoolean,
    car_and_charge_selected: carAndChargeBoolean === null ? false : carAndChargeBoolean,
  };
  /*
  * Include uberCleanBoolean is true send uber amount. Otherwise don't send.
  */
  if (uberCleanBoolean) payload.uber_clean_air_amount = Number(uberCleanAirAmount);
  /*
  * Include carAndChargeBoolean is true send card details if provided. Otherwise don't send.
  */
  if (carAndChargeBoolean) {
    payload.bp_card_id = bpCardNumber === null ? '' : bpCardNumber;
    payload.shell_card_id = shellCardNumber === null ? '' : shellCardNumber;
  }

  await context.$axios.$put(
    `/v2/application/${state.coreDetails.applicationId}`, payload,
  );
};
const confirmEV = async (state, context) => {
  const {
    uberCleanAirAmount,
    uberCleanBoolean,
    carAndChargeBoolean,
    bpCardNumber,
    shellCardNumber,
  } = state.coreDetails.evDetails;

  const payload = {
    uber_clean_air_confirmed: true,
    car_and_charge_selected: carAndChargeBoolean === null ? false : carAndChargeBoolean,
    has_uber_clean_fund: uberCleanBoolean === null ? false : uberCleanBoolean,
  };
  /*
  * Include uberCleanBoolean is true send uber amount. Otherwise don't send.
  */
  if (uberCleanBoolean) payload.uber_clean_air_amount = Number(uberCleanAirAmount);
  /*
  * Include carAndChargeBoolean is true send card details if provided. Otherwise don't send.
  */
  if (carAndChargeBoolean) {
    payload.bp_card_id = bpCardNumber === null ? '' : bpCardNumber;
    payload.shell_card_id = shellCardNumber === null ? '' : shellCardNumber;
  }
  await context.$axios.$put(
    `/v2/application/${state.coreDetails.applicationId}`, payload,
  );
};
const undoEVApplicationDetails = async (state, context) => {
  const { applicationId } = state.coreDetails;
  const payload = {
    uber_clean_air_confirmed: false,
  };
  await context.$axios.$put(
    `/v2/application/${applicationId}`, payload,
  );
};
const sendScriveRequest = async (state, context) => {
  const { applicationId } = state.coreDetails;
  await context.$axios.$get(
    `/v2/application/sign/${applicationId}`,
  );
};

const cancelAgreement = async (state, context) => {
  const { applicationId } = state.coreDetails;
  await context.$axios.$delete(
    `/v2/application/agreement/${applicationId}`,
  );
};

const createOrdwaySubscription = async (store, context) => {
  const { agreementId, branchId, vehicle: { location } } = store.state.coreDetails;
  await context.$axios.$post(
    `/v2/application/ordway_create_subscription/${agreementId}`, {
      branch_id: branchId,
      location,
    },
  );
};
const confirmEVWithoutChanges = async (state, context) => {
  const payload = {
    uber_clean_air_confirmed: false,
    car_and_charge_selected: false,
    has_uber_clean_fund: false,
  };
  await context.$axios.$put(
    `/v2/application/${state.coreDetails.applicationId}`, payload,
  );
};
module.exports = {
  fetchOrder,
  downPayment,
  updateEVApplication,
  confirmEV,
  undoEVApplicationDetails,
  sendScriveRequest,
  createOrdwaySubscription,
  cancelAgreement,
  confirmEVWithoutChanges,
  finalDownpayment,
};
