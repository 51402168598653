var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4",attrs:{"id":"card"}},[_c('v-data-table',{attrs:{"id":"table","color":"green","headers":_vm.vTableHeaders,"items":_vm.storePath === undefined ? _vm.data : _vm.storeData,"server-items-length":_vm.storePath === undefined ? _vm.itemsTotalResults : _vm.storeTotalResults,"items-per-page":_vm.storePath === undefined ? _vm.itemsPerPage : _vm.storeItemsPerPage,"loading":_vm.loading === undefined ? _vm.localLoading : _vm.loading,"footer-props":{
      disableItemsPerPage: false,
      itemsPerPageOptions: [10, 30, 50],
    },"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":_vm.itemKey},on:{"click:row":_vm.handleRowClick,"update:page":_vm.handlePageClick,"update:sort-by":_vm.handleSortBy,"update:sort-desc":_vm.handleSortDesc,"update:expanded":_vm.updateExpanded,"update:items-per-page":_vm.handleUpdateResults},scopedSlots:_vm._u([_vm._l((_vm.storeHeaders.map(function (header) { return header.value; })),function(header){return {key:("item." + header),fn:function(ref){
    var item = ref.item;
return [(
          (item[header] == null || item[header] == '') && header !== 'action'
          && _vm.discardEmptyCellStyle.includes(item[header])
        )?_c('div',{key:item[header],staticClass:"empty-cell-style"},[_vm._v("\n        -\n      ")]):_vm._t(header,[_vm._v("\n        "+_vm._s(item[header] == null ? "-" : item[header])+"\n      ")],{"row":item})]}}}),{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var headers = ref.headers;
return [_vm._t("expanded-item-section",null,{"row":{ item: item, headers: headers }})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }