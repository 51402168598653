/* eslint-disable max-classes-per-file */
import crypto from 'crypto';
import logError from './error-logger';

class CustomError extends Error {
  // constructor(code, message, toast, link = 'https://our-documentation-here.co.uk') {
  //   super();
  //   this.id = crypto.randomBytes(32).toString('hex');
  //   this.code = code;
  //   this.message = message; // use this for debug, use toast for user presentation
  //   this.link = 'https://our-documentation-here.co.uk';

  //   // This shouldn't be necessary thanks to nuxt / terser
  //   // if (typeof environment != undefined || environment != 'prod') {
  //   //   console.log(this);
  //   // }

  //   if (typeof toast !== undefined && toast) {
  //     // create message using toast object, don't know how at this point
  //   }
  // }
  constructor(error) {
    console.log('💩 ERROR:', error);
    super(error);
    this.id = crypto.randomBytes(32).toString('hex');
  }
}

class BadRequestError extends CustomError {
  constructor(error) {
    console.log('BAD REQUEST ERROR');
    super(error);
    this.code = 400;
    logError(this, { type: 'BadRequestError' });
  }
}

class AuthorizationError extends CustomError {
  constructor(error) {
    console.log('AUTHORIZATION ERROR');
    super(error);
    this.code = 403;
    logError(this, { type: 'AuthorizationError' });
  }
}

class NotFoundError extends CustomError {
  constructor(error) {
    console.log('NOT FOUND ERROR');
    super(error);
    this.code = 404;
    logError(this, { type: 'NotFoundError' });
  }
}

class InternalError extends CustomError {
  constructor(error) {
    console.log('INTERNAL ERROR');
    super(error);
    this.code = 500;
    logError(this, { type: 'InternalError' });
  }
}

class UncaughtError extends CustomError {
  constructor(error) {
    console.log('UNCAUGHT ERROR');
    super(error);
    this.code = 500;
    debugger;
    logError(error, { type: 'UncaughtError' });
  }
}

export {
  CustomError,
  BadRequestError,
  NotFoundError,
  AuthorizationError,
  InternalError,
  UncaughtError,
};
