const createAssessmentInfoString = (data) => {
  let info = 'Theory: ';
  if (!data.theoryRequired) info += 'Not Required';
  else info += `${data.theoryTaken === true ? 'Taken' : 'Not taken'}`;

  // Show whether practical is required or taken
  info += ' - Practical: ';
  if (!data.practicalRequired) info += 'Not Required';
  else info += `${data.practicalTaken === true ? 'Taken' : 'Not taken'}`;

  // Show result of assessment
  if (data.practicalRequired || data.theoryRequired) {
    if (data.assessmentPassed) info += ' - Result: Passed';
    else if (!data.assessmentPassed && data.assessmentPassed !== null) { info += ' - Result: Failed'; } else info += ' - Result: Not decided yet.';
  }
  return info;
};

module.exports = {
  createAssessmentInfoString,
};
