export const state = () => ({
  page: 1,
  data: [],
  filters: '',
  headers: [
    { text: 'VRM', value: 'vrm' },
    { text: 'Model', value: 'model', sortable: false },
    { text: 'Color', value: 'color', sortable: false },
    { text: 'Location', value: 'location', sortable: false },
    { text: 'Days Off Road', value: 'days_off_road' },
    { text: 'Open Services', value: 'open_services', sortable: false },
  ],
  sortBy: 'vrm',
  sortDesc: 'ascending',
  totalResults: undefined,
  itemsPerPage: undefined,
});

export const getters = {
  getData(state) {
    return state.data;
  },
  getPage(state) {
    return state.page;
  },
  getHeaders(state) {
    return state.headers;
  },
  getTotalResults(state) {
    return state.totalResults;
  },
  getItemsPerPage(state) {
    return state.itemsPerPage;
  },
};

export const mutations = {
  setData(store, payload) {
    store.data = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setTotalResults(state, value) {
    state.totalResults = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setSortDesc(state, value) {
    state.sortDesc = value;
  },
  setItemsPerPage(state, value) {
    state.itemsPerPage = value;
  },
};

export const actions = {
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
  async loadPage({ commit, state }) {
    let url = '/v2/servicing/vehicles';
    url += `?page=${state.page - 1}`;

    if (state.filters) url += state.filters;
    if (state.sortBy) url += `&order_by=${state.sortBy === 'days_off_road' ? 'in_date' : state.sortBy}`;
    if (state.sortDesc !== undefined) url += `&order=${state.sortDesc ? 'descending' : 'ascending'}`;
    if (state.itemsPerPage !== undefined) url += `&results=${state.itemsPerPage}`;

    const data = await this.$axios.$get(url);
    data.data.forEach((vehicle) => {
      if (vehicle.in_date) {
        const today = new Date();
        const checkIn = new Date(vehicle.in_date);
        const timeDiff = today.getTime() - checkIn.getTime();
        const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
        Object.assign(vehicle, { days_off_road: dayDiff });
      } else Object.assign(vehicle, { days_off_road: null });
      return vehicle;
    });

    commit('setData', data.data);
    commit('setTotalResults', data.total);
  },
  setSortBy({ commit }, value) {
    commit('setSortBy', value);
  },
  setSortDesc({ commit }, value) {
    commit('setSortDesc', value);
  },
  setItemsPerPage({ commit }, value) {
    commit('setItemsPerPage', value);
  },
};
