// Any validation

const example = (store, value) => {
  if (value) return true;
  return false;
};

module.exports = {
  example,
};
