const AGREEMENT_TYPES = {
  'r2b-classic': 'R2B',
  rental: 'Rental',
  'r2b-used': 'R2B Used',
  'r2b-pasttime': 'R2B Passtime',
  'aftercare-full': 'Aftercare',
  'aftercare-insurance': 'Aftercare: Insurance Only',
  'friendly-finance': 'Friendly Finance',
  courtesy: 'Courtesy',
  van: 'Van',
};

const AGREEMENT_STATUSES = {
  live: 'Live',
  'paid-off': 'Paid Off',
  dropped: 'Dropped',
  paused: 'Paused',
  terminated: 'Terminated',
  completed: 'Completed',
  declaration_completed: 'Declaration Completed',
  vehicle_assigned: 'Vehicle Assigned',
  collection_arranged: 'Collection Arranged',
  downpayment_succeeded: 'Downpayment Succeeded',
  downpayment_failed: 'Downpayment Failed',
  downpayment_pending: 'Downpayment Pending',
  aftercare_review: 'Aftercare Review',
  cancelled: 'Cancelled',
  open: 'Open',
};

const agreementType = (val) => AGREEMENT_TYPES[val];
const agreementStatus = (val) => AGREEMENT_STATUSES[val];

export {
  agreementType,
  agreementStatus,
};
