const SERVICING_TYPES = {
  repair: 'Body Repair',
  mot: 'MOT',
  phv: 'PHV',
  service: 'Service',
  non_warranty: 'Non Warranty',
  warranty: 'Warranty',
};

const servicingType = (val) => SERVICING_TYPES[val];

export default servicingType;
