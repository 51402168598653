import { getValidPackage, formatPackages } from './utils.js';

export const state = () => ({
  driverId: '',
  driverName: '',
  driverMobile: '',
  applicationId: '',
  assignedVehicleId: '',
  assignedVehicleVrm: '',
  applicationType: '',
  vehicleTypeId: '',
  currentDriver: {},
  loading: false,
  packages: {},
  formattedPackageDetails: {},
});

export const getters = {
  getData(store) {
    return store;
  },
  getLoading(store) {
    return store.loading;
  },
  getApplicationId(store) {
    return store.applicationId;
  },
  getCurrentDriver(store) {
    return store.currentDriver;
  },
  getPackageDetails(store) {
    return store.formattedPackageDetails;
  },
};

export const mutations = {
  setStoreData(store, payload) {
    store.driverId = payload.driver_id;
    store.driverName = payload.driver_name;
    store.driverMobile = payload.driver_mobile;
    store.applicationId = payload.applicationId;
    store.applicationType = payload.application_type;
    store.assignedVehicleVrm = payload.vrm;
    store.assignedVehicleId = payload.vehicle.id;
    store.vehicleTypeId = payload.vehicle_type_id;
  },
  setApplicationId(store, payload) {
    store.applicationId = payload;
  },
  setAssignedVehicleId(store, payload) {
    store.assignedVehicleId = payload;
  },
  setLoading(store, payload) {
    store.loading = payload;
  },
  setCurrentDriverDetails(store, payload) {
    store.currentDriver.applicationId = payload.application_id;
    store.currentDriver.driverName = payload.driver_name;
    store.currentDriver.driverMobile = payload.driver_mobile;
    store.currentDriver.blockedReason = payload.blocked_reason;
  },
  resetCurrentDriver(store) {
    store.currentDriver = {};
  },
  resetStore(store) {
    store.driverName = '';
    store.applicationId = '';
    store.assignedVehicleId = '';
  },
  setPackages(store, payload) {
    store.packages = payload;
  },
  async setPackageDetails(store, params) {
    store.formattedPackageDetails = params;
  },
};

export const actions = {
  async fetchOrderDetails({ commit }, applicationId) {
    commit('setLoading', false);
    const response = await this.$axios.$get(
      `/v2/application/progress_check/${applicationId}`,
    );
    response.data.applicationId = applicationId;
    commit('setStoreData', response.data);
    commit('setLoading', false);
  },
  resetStore({ commit }) {
    commit('resetStore');
  },
  async evaluateAndAssign({ commit }, payload) {
    await this.$axios.$put(
      '/v2/application/vehicle/evaluate_and_assign', payload,
    );
  },
  async fetchCurrentDriver({ commit }, vehicleId) {
    let response = await this.$axios.$get(`/v2/application/vehicle/${vehicleId}`);
    if (response === undefined) response = {};
    commit('setCurrentDriverDetails', response);
  },
  resetCurrentDriver({ commit }) {
    commit('resetCurrentDriver');
  },
  async fetchPackages({ commit }, vehicleTypeId) {
    const response = await this.$axios
      .$get(`/v2/application/vehicle/${vehicleTypeId}/packagesAndPricing/friendly-finance
    `);
    commit('setPackages', response);
  },
  async filterPackages({ commit, state }, options) {
    if (!state.packages.length > 0) {
      commit('setPackageDetails', '');
      return;
    }
    const packageDetails = await getValidPackage(state, options);
    if (!packageDetails || packageDetails === undefined) {
      commit('setPackageDetails', '');
      return;
    }
    formatPackages(commit, packageDetails);
  },
};
