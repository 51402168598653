import {
  BadRequestError,
  AuthorizationError,
  NotFoundError,
  InternalError,
} from './error';

const axiosErrorMap = (error) => {
  if (error.response === undefined) return new InternalError(error);
  if (error.response.status === 400) return new BadRequestError(error);
  if (error.response.status === 403) return new AuthorizationError(error);
  if (error.response.status === 404) return new NotFoundError(error);

  if (error.response && error.response.data && error.response.data.error) {
    if (error.response.data.error.errorId) {
      return new InternalError(error.response.data.error.errors.join(','));
    }
  }

  return new InternalError(error);
};

export default axiosErrorMap;
