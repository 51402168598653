export const state = () => ({
  page: 0,
  employees: [],
  filters: '',
});

export const getters = {
  getEmployees(store) {
    return store.employees;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.employees = [];
    store.filters = '';
  },
  setEmployees(store, payload) {
    store.employees = payload;
  },
  clearEmployees(store) {
    store.employees = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getEmployees({ commit, state }) {
    let url = 'employee?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const employees = await this.$axios.$get(url);

    commit('setEmployees', employees.employee_list);
  },
  async clearEmployees({ commit }) {
    commit('clearEmployees');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
