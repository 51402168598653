<template>
  <el-row>
    <el-col :span="18">
      <el-input
        @input="handleChange"
        :value="value"
        :placeholder="placeholder"
        :disabled="disable && !editableField"
      ></el-input>
    </el-col>
    <el-col :span="5" :offset="1">
      <div v-if="disable" class="button-group">
        <el-button
          v-if="!editableField"
          type="edit"
          size="small"
          icon="el-icon-edit"
          @click="editableField = true"
          circle
        >
        </el-button>
        <div v-if="editableField">
          <el-button
            type="update"
            size="small"
            icon="el-icon-check"
            @click="update"
            circle
          >
          </el-button>
          <el-button
            type="discard"
            size="small"
            icon="el-icon-close"
            @click="discard"
            circle
          >
          </el-button>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    disable: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      editableField: false,
    };
  },
  methods: {
    handleChange(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    update() {
      this.$emit("update");
      // this.editableField = false;
    },
    discard() {
      this.$emit("discard");
    },
  },
};
</script>
<style scoped>
.el-input__inner {
  border-radius: 20px !important;
}
.el-icon-edit {
  color: white;
}
</style>
