<template>
  <v-menu
    ref='menu'
    v-model='menu'
    :close-on-content-click='false'
    :return-value.sync='date'
    transition='scale-transition'
    offset-y
    min-width='auto'
    mandatory
  >
    <template v-slot:activator='{ on, attrs }'>
      <v-text-field
        v-model="date"
        :label='label'
        prepend-icon='mdi-calendar'
        readonly
        v-bind='attrs'
        v-on='on'
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @change='save'
      no-title
      scrollable>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    save(date) {
      // const formattedDate = new Date(date);
      this.$refs.menu.save(date);
      this.$emit('input', date);
      this.$emit('change', date);
    },
  },
  computed: {
    date: {
      get() {
        if (!this.value) return;
        if (this.value === null) return new Date();
        return this.value.substr(0, 10);
      },
      set(value) {
        this.save(value);
      },
    },
  },
};
</script>
