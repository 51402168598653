export const state = () => ({
  page: 0,
  vehicles: [],
  filters: '',
  resultCount: 0,
});

export const getters = {
  getData(store) {
    return store.vehicles;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.vehicles = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setData(store, payload) {
    store.vehicles = payload;
  },
  clearData(store) {
    store.vehicles = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async fetchData({ commit, state }) {
    let url = 'vehicle?results=10&agreement_type=aftercare-full&agreement_type=aftercare-insurance';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const vehicles = await this.$axios.$get(url);
    const withDriversName = vehicles.vehicle_list.map((vehicle) => {
      const payload = vehicle;
      if (vehicle.driver && vehicle.driver.length > 0) {
        payload.driver_name = `${vehicle.driver[0].first_name} ${vehicle.driver[0].last_name}`;
      }
      if (vehicle.insurance && vehicle.insurance.length > 0) {
        payload.insurance = vehicle.insurance[0].policy_number;
      }
      return payload;
    });
    commit('setData', withDriversName);
    commit('setResultCount', vehicles.total);
  },
  async clearData({ commit }) {
    commit('clearVehicles');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
