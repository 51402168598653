const checkIfStatusCanChange = (store, param, context) => {
  // api request
  // fake response
  const ifSuitable = false;
  console.log('Hitting the request');
  store.commit('setIfVehicleSuitable', ifSuitable);
};
module.exports = {
  checkIfStatusCanChange,
};
