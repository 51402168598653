export const state = () => ({
  page: 0,
  data: {},
  services: [],
  filters: '',
  resultCount: 0,
});

export const getters = {
  getData(store) {
    return store.data;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getResultCount(store) {
    return store.resultCount;
  },
  getServices(store) {
    return store.services;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.data = {};
    store.filters = '';
    store.services = [];
    store.resultCount = 0;
  },
  clearData(store) {
    store.data = {};
  },
  setData(store, payload) {
    store.data = payload;
  },
  setServices(store, payload) {
    store.services = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
  setVehicleLocation(store, location) {
    store.data.location = location;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async clearData({ commit }) {
    commit('clearData');
  },
  async fetchData({ commit, state }, vehicleId) {
    const url = `/v2/servicing/vehicle/${vehicleId}`;
    const resp = await this.$axios.$get(url);

    commit('setData', resp);
    commit('setServices', resp.services);
  },
  setVehicleLocation({ commit }, location) {
    commit('setVehicleLocation', location);
  },
  // async reloadService({ commit, state }) {
  //   let url = `/v2/servicing/services/${state.data.id}`;
  //   const resp = await this.$axios.$get(url);
  //   commit('setServices', resp)
  // },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
