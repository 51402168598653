<template>
    <div v-loading="loading">
         <el-form-item label="VRM" prop="vrm">
            <el-input v-model="vrm" size="small" @change="search" :disabled="disabled" />
            <small v-if="Object.keys(value).length">
                {{ value.manufacturer }} {{ value.model }} {{ value.color }}
            </small>
        </el-form-item>
    </div>
</template>

<script>
export default {
  name: 'PVrmLookup',
  data() {
    return {
      vrm: null,
      loading: false,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.vrm = this.value.vrm;
  },
  methods: {
    async search() {
      this.loading = true;
      if (this.vrm) {
        this.vrm = this.vrm.replace(' ', '').toUpperCase();
        const vehicles = await this.$axios.$get(
          `/vehicle?vrm=${encodeURIComponent(this.vrm)}`,
        );
        const { vehicle_list } = vehicles;
        if (vehicle_list && vehicle_list.length) {
          const vehicle = vehicle_list[0];
          this.$emit('input', vehicle);
          this.$emit('change', vehicle);
        } else {
          this.$message({
            message: 'Cannot find vehicle',
            type: 'error',
            center: true,
          });
          this.$emit('input', {});
          this.$emit('change', {});
        }
      } else {
        this.$emit('input', {});
        this.$emit('change', {});
      }
      this.loading = false;
    },
  },
  watch: {
    value(value) {
      this.vrm = value.vrm;
    },
  },
};
</script>
