// validate VRM logic

const validateVrmDefault = (vehicleObject) => {
  const error = new Error();
  error.name = 'ValidationError';

  if (!Object.keys(vehicleObject).length) return false;

  if (!vehicleObject.agreement) {
    error.message = 'Vehicle must have an agreement';
    throw error;
  } else if (vehicleObject.status !== 'live' && vehicleObject.status !== 'aftercare') {
    error.message = 'Vehicle must be on the road to use this form';
    throw error;
  } else {
    const agreement = vehicleObject.agreement[0];

    if (agreement.agreement_status !== 'live' && agreement.agreement_status !== 'paused') {
      error.message = 'Agreement must be live to use this report';
      throw error;
    } else return true;
  }
};

const validateVrmCollectionNew = () => true;

const validateVrmTemporaryRental = (vehicleObject) => {
  const error = new Error();
  error.name = 'ValidationError';

  if (vehicleObject.status !== 'available') {
    error.message = 'This vehicle is not available';
    throw error;
  } else if (!vehicleObject.rental_value || !vehicleObject.total_due) {
    error.message = 'This vehicle does not have the correct values set';
    throw error;
  } else return true;
};

const validateVrmReturnVehicle = () => true;

const validateVrmAssignCourtesy = (vehicleObject) => {
  const error = new Error();
  error.name = 'ValidationError';

  if (vehicleObject.status !== 'available') {
    error.message = 'This vehicle is not available';
    throw error;
  } else return true;
};

const validateVrmReturnCourtesy = () => true;

const validateVrmRentalSwap = (vehicleObject) => {
  const error = new Error();
  error.name = 'ValidationError';

  if (vehicleObject.status !== 'available') {
    error.message = 'This vehicle is not available';
    throw error;
  } else if (!vehicleObject.rental_value || !vehicleObject.total_due) {
    error.message = 'This vehicle does not have the correct values set';
    throw error;
  } else return true;
};

const validateVrmFinishService = () => true;

// validate Condition Report logic

const validateConditionReportDefault = (state) => {
  const REQUIRED_STRINGS = [
    'conditionReportId',
    'reason',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportCollectionNew = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'agreementId',
    'applicationId',
    'driverId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportTemporaryRental = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'agreementId',
    'driverId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportReturnVehicle = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'agreementId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportAssignCourtesy = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'agreementId',
    'driverId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportReturnCourtesy = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'agreementId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportRentalSwap = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'agreementId',
    'driverId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const validateConditionReportFinishService = (state) => {
  const error = new Error();
  error.name = 'ValidationError';

  const REQUIRED_STRINGS = [
    'conditionReportId',
    'location',
    'mileage',
    'fuelGauge',
    'signature',
    'checklist',
  ];
  const REQUIRED_OBJECTS = [
    'vehicleObject',
    'checklist',
  ];

  const invalid_fields = [];

  const strings = REQUIRED_STRINGS.every((field) => {
    if (!state[field]) invalid_fields.push(field);
    return Boolean(state[field]);
  });
  const objects = REQUIRED_OBJECTS.every((field) => {
    if (!Object.keys(state[field]).length) invalid_fields.push(field);
    return Boolean(Object.keys(state[field]).length);
  });

  if (strings && objects) return true;

  console.log('MISSING FIELDS', invalid_fields);
  return false;
};

const vrmLookups = {
  default: validateVrmDefault,
  collectionNew: validateVrmCollectionNew,
  temporaryRental: validateVrmTemporaryRental,
  returnVehicle: validateVrmReturnVehicle,
  assignCourtesy: validateVrmAssignCourtesy,
  returnCourtesy: validateVrmReturnCourtesy,
  rentalSwap: validateVrmRentalSwap,
  finishService: validateVrmFinishService,
};

const conditionReportSubmit = {
  default: validateConditionReportDefault,
  collectionNew: validateConditionReportCollectionNew,
  temporaryRental: validateConditionReportTemporaryRental,
  returnVehicle: validateConditionReportReturnVehicle,
  assignCourtesy: validateConditionReportAssignCourtesy,
  returnCourtesy: validateConditionReportReturnCourtesy,
  rentalSwap: validateConditionReportRentalSwap,
  finishService: validateConditionReportFinishService,
};

module.exports = {
  vrmLookups,
  conditionReportSubmit,
};
