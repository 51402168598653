<template>
  <div class="anna-testing">
    <Header />
    <el-steps id="steps" :active="activePhase" finish-status="success" align-center>
      <el-step title="Start"></el-step>
      <el-step title="Waiver Form"></el-step>
      <el-step title="Plan"></el-step>
      <el-step title="Vehicle"></el-step>
      <el-step title="Assessment"></el-step>
      <el-step title="Practical"></el-step>
      <el-step title="Review"></el-step>
      <el-step title="Declaration"></el-step>
      <el-step title="Success"></el-step>
    </el-steps>
    <div id="inner">
      <nuxt />
    </div>
    <Timeout :idleTime="idleTime" :promptTime="promptTime" />
  </div>
</template>

<script>
import Header from '@/components/common/Header';
import Timeout from '@/components/common/Timeout';

export default {
  computed: {
    activePhase() {
      return this.$store.getters['application/getApplicationPhase'];
    },
  },
  components: {
    Header,
    Timeout,
  },
  data() {
    return {
      idleTime: 1800000, // 30 minutes
      promptTime: 10000, // 10 seconds
    };
  },
};
</script>

<style scoped lang='scss'>
::v-deep #inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 1em;
  background-color: white;
  height: 100%;
  min-height: 100vh;
}
#steps {
  width: 100%;
  margin-bottom: 3em;
}
.anna-testing {
  background-color: white
}
::v-deep .el-step__head.is-success {
  color: var(--green) !important;
  border-color: var(--green);
}
::v-deep .el-step__head.is-success .el-step__icon.is-text {
  background-color: var(--green) !important;
  color: white !important;
  border: none;
}
::v-deep .el-step__title.is-success {
  color: var(--green);
}
::v-deep .el-step__icon {
  width: 32px;
  height: 32px;
  font-size: 18px;
}
</style>
