<template>
    <div>
        <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="20">
                <Query v-if="isAuthed" />
            </el-col>
            <el-col :span="3" :offset="1">
                <el-row type="flex" class="row-bg vertical-center" justify="end">
                    <el-col :xs="11" :sm="11" :md="11" :lg="8" :xl="6">
                        <el-dropdown class="vertical-center" @command="handleCommand">
                            <span class="el-dropdown-link"><i class="el-icon-user-solid"></i></span>
                            <el-dropdown-menu>
                                <el-dropdown-item command="showPasswordChange">Change Password</el-dropdown-item>
                                <el-dropdown-item command="logout">Logout</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                    <el-col :span="1"></el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="passwordChangeVisible">
            <div style="padding: 10px">
                <PasswordChange />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Query from '@/components/layout/Query';
import PasswordChange from '@/components/profile/PasswordChange';

export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      passwordChangeVisible: false,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
    },
    showPasswordChange() {
      this.passwordChangeVisible = true;
    },
    handleCommand(command) {
      if (command == 'logout') {
        this.logout();
      } else if (command == 'showPasswordChange') {
        this.showPasswordChange();
      }
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
  computed: {
    isAuthed() {
      return this.$store.getters['auth/isAuthed'];
    },
  },
  components: {
    Query,
    PasswordChange,
  },
};
</script>

<style lang="scss" scoped>
*{
  font-size: 14px;
}
i {
  color: #333f48;
  font-size: 25px !important;
}
img {
  height: 1.8em;
  width:  1.8em;
}
.el-menu--horizontal>.el-submenu .el-submenu__title {
    font-size: 16px;
    color: white;
}
.el-card__body {
    background: white;
    border: none !important;
    padding: 0px !important;
}
.el-menu-item {
    text-align: center;
    padding: 0px !important;
    &:hover, &:active {
      background:#f7f7f7;
      cursor: default;
    }

}
.el-submenu {
    text-align: left !important;
    padding: 0px !important;
    &:hover, &:active {
      background:#f7f7f7;
      cursor: default;
    }
}
::v-deep.el-submenu__title {
    &:hover, &:active {
      background:#f7f7f7;
      cursor: default;
    }
}
::v-deep .el-submenu__icon-arrow {
    font-size: 0px !important;
}
::v-deep .el-input--small .el-input__inner {
    margin-left: 30px !important;
    margin-top: 20px !important;
}

.vertical-center {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

</style>
