<template>
<div class="error-wrapper">
    <i class="el-icon-heavy-rain error-icon" />
    <h1 v-if="error.statusCode && error.statusCode === 404">Oops! Resource not found</h1>
    <h1 v-else>Oops! Something went wrong</h1>
    <p>{{ errorCodeMap[error.statusCode] || errorCodeMap[500] }}</p>
    <p>If this keeps happening, please get in touch with the engineering team</p>
</div>
</template>

<script>
  export default {
    props: ['error'],
    layout: 'error',
    data() {
        return {
            errorCodeMap: {
                400: "Something wasn't quite right, please try again",
                401: 'You have to be logged in and authorised to see this page',
                404: 'We were unable to find what you were looking for',
                500: "We're not sure what though...",
                502: 'There was a problem on our side...',
                503: 'There was a problem on our side...',
                504: 'There was a problem on our side...',
            }
        }
    }
  }
</script>

<style scoped>
.error-wrapper {
    text-align: center;
}
.error-wrapper p {
    color: var(--grey__slate);
    margin: 1em;
}
.error-icon {
    font-size: 120px;
    color: var(--grey__slate);
    margin: .5em;
}
</style>
