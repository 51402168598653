<template>
    <el-card class="card-style">
        <el-row class="row-style">
            <el-col :span="4">
                <p>Analitycs</p>
            </el-col>
            <el-col :span="18">
                {{dataList}}
                <!-- <label v-for="result in resultsArray" :key="result" class="label-style">
                    {{result[1]}} {{result[0]}}
                </label> -->
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
import listData from '@/mixins/listData.js';

export default {
  name: 'PulseSummaryCard',
  mixins: [listData],
  data() {
    return {
      value: '',
      dataListFinal: {},
    };
    // },
    // async created() {
    //    this.dataListFinal = await this.dataList;
    //    console.log(this.dataListFinal)
  },
  async created() {
    this.dataListFinal = Promise.resolve(this.dataList).then((value) => {
    });
    // try {
    //     this.data = JSON.parse(this.filters)
    //     this.setStore(this.data.store);
    //     await this.getFilters();
    //     this.cardComponent = true;
    // } catch(error){
    //     console.log("error here")
    // }
  },
  methods: {
    // getFilters() {
    //     let filtersArray = []
    //     let filter = "";
    //     Object.keys(this.filters.filterBy).forEach((key, index) => {
    //         filtersArray.push(key)
    //     });
    //     filtersArray.forEach((key, index) => {
    //         this.value = this.data.filterBy[key];
    //         //  if there is more then one value for a filter
    //         this.value.forEach((k, i) => {
    //             filter = `&${filtersArray[index]}=${k}`
    //             this.setFilters(filter);
    //             this.loadPage().then(_ => {
    //                 this.resultsArray[i] = [k, this.results]
    //             });
    //         })
    //     })

    // }
  },
  props: ['filters'],
  computed: {
    async dataList() {
      const filtersArray = [];
      let filter = '';
      this.data = JSON.parse(this.filters);
      await this.setStore(this.data.store);

      Object.keys(this.data.filterBy).forEach((key, index) => {
        filtersArray.push(key);
      });

      await filtersArray.forEach((key, index) => {
        this.value = this.data.filterBy[key];
        //  if there is more then one value for a filter
        this.value.forEach((k, i) => {
          filter = `&${filtersArray[index]}=${k}`;
          this.setFilters(filter);
          this.loadPage().then((_) => {
            this.resultsArray[i] = [k, this.results];
          });
        });
      });
      return this.resultsArray;
    },
  },
};

</script>
<style lang="scss" scoped>
.card-style {
    border-radius: 25px;
    margin-bottom: 1em
}
.label-style {
    background-color: blueviolet;
    color: #FFFF;
    font-size: 14px;
    margin: 1em 0.9em 1em;
    padding: 0.7em;
    border-radius: 25px;
}
</style>
