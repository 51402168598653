<template>
    <v-card-text>
    <v-subheader>{{title}}</v-subheader>
        <v-slider
            :value="value"
            :tick-labels="tickLabels"
            :max="max"
            :min="min"
            :step="step"
            ticks="always"
            :tick-size="tickSize"
            :color="color"
            @input="input"
            @change="change"
            thumb-label="always"
            thumb-size="40"
            :thumb-color="thumbColor"
            track-color="green"
        ></v-slider>
    </v-card-text>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    title: {
      type: String,
    },
    tickLabels: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
    },
    step: {
      type: Number,
    },
    tickSize: {
      type: String,
    },
    color: {
      type: String,
      default: 'success',
    },
    thumbColor: {
      type: String,
      default: 'green',
    },
    min: {
      type: Number,
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>
