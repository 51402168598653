export const state = () => ({
  page: 0,
  pcns: [],
  filters: '',
  statuses: {
    created: 'New',
    processed: 'Processed',
    paid: 'Paid',
    'not-signed': 'Not Signed',
    signed: 'Signed',
  },
  charges: {
    unknown: 'Unknown',
    driver: 'Driver',
    otto: 'Otto',
  },
  resultCount: 0,
});

export const getters = {
  getData(store) {
    return store.pcns;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getStatuses(store) {
    return store.statuses;
  },
  getCharges(store) {
    return store.charges;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.pcns = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setData(store, payload) {
    store.pcns = payload;
  },
  clearData(store) {
    store.pcns = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async fetchData({ commit, state }) {
    let url = 'get_pcn_charges?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const pcns = await this.$axios.$get(url);
    const pcnDetails = pcns.pcn_list.map((pcn) => {
      let vrm = ' ';
      let authority = '';
      if (pcn.vehicle && pcn.vehicle.length > 0) {
        vrm = pcn.vehicle[0].vrm;
      }
      if (pcn.payment_authority && pcn.payment_authority.length > 0) {
        authority = pcn.payment_authority[0].name;
      }
      return {
        ...pcn,
        authority,
        vrm,
      };
    });
    commit('setData', pcnDetails);
    commit('setResultCount', pcns.total);
  },
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
