export const state = () => ({
  page: 0,
  agreements: [],
  filters: '',
  agreement_statuses: {
    live: 'Live',
    'paid-off': 'Paid Off',
    dropped: 'Dropped',
    paused: 'Paused',
    terminated: 'Terminated',
    completed: 'Completed',
    declaration_completed: 'Declaration Completed',
    vehicle_assigned: 'Vehicle Assigned',
    collection_arranged: 'Collection Arranged',
    downpayment_succeeded: 'Downpayment Succeeded',
    downpayment_failed: 'Downpayment Failed',
    downpayment_pending: 'Downpayment Pending',
    aftercare_review: 'Aftercare Review',
    cancelled: 'Cancelled',
  },
  agreement_types: {
    'r2b-classic': 'R2B',
    rental: 'Rental',
    'r2b-used': 'R2B Used',
    'r2b-pasttime': 'R2B Passtime',
    'aftercare-full': 'Aftercare',
    'aftercare-insurance': 'Aftercare: Insurance Only',
    courtesy: 'Courtesy',
    'friendly-finance': 'Friendly Finance',
    // "van": "Van"
  },
  resultCount: 0,
});

export const getters = {
  getAgreements(store) {
    return store.agreements;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getStatuses(store) {
    return store.agreement_statuses;
  },
  getTypes(store) {
    return store.agreement_types;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.agreements = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setAgreements(store, payload) {
    store.agreements = payload;
  },
  clearAgreements(store) {
    store.agreements = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getAgreements({ commit, state }) {
    let url = 'agreement?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const agreements = await this.$axios.$get(url);
    const withDriver = agreements.agreement_list.map((agreement) => {
      const driver_fields = {
        driver_name: 'No Driver',
        email: 'Unknown',
        mobile_phone: 'Unknown',
      };
      const vehicle_fields = {
        vehicle_name: 'Unknown',
        vrm: 'Unknown',
      };

      if (agreement.driver && agreement.driver.length > 0) {
        let driver_name = '';
        if (agreement.driver[0].title) {
          driver_name = `${driver_name} ${agreement.driver[0].title}`;
        }
        if (agreement.driver[0].first_name) {
          driver_name = `${driver_name} ${agreement.driver[0].first_name}`;
        }
        if (agreement.driver[0].middle_name) {
          driver_name = `${driver_name} ${agreement.driver[0].middle_name}`;
        }
        if (agreement.driver[0].last_name) {
          driver_name = `${driver_name} ${agreement.driver[0].last_name}`;
        }
        driver_fields.driver_name = driver_name;
        driver_fields.email = agreement.driver[0].email;
        driver_fields.mobile_phone = agreement.driver[0].mobile_phone;
      }

      if (agreement.vehicle) {
        vehicle_fields.vehicle_name = `${agreement.vehicle[0].manufacturer} ${agreement.vehicle[0].model}`;
        vehicle_fields.vrm = agreement.vehicle[0].vrm;
      }
      return {
        ...driver_fields,
        ...vehicle_fields,
        ...agreement,
      };
    });
    commit('setAgreements', withDriver);
    commit('setResultCount', agreements.total);
  },

  async clearAgreements({ commit }) {
    commit('clearAgreements');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
