export const state = () => ({
  page: 0,
  data: [],
  filters: '',
  statuses: {
    created: 'New',
    processed: 'Processed',
  },
  types: {
    inclusive: 'Inclusive',
    noninclusive: 'Noninclusive',
  },
  resultCount: 0,
});

export const getters = {
  getData(store) {
    return store.data;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getStatuses(store) {
    return store.statuses;
  },
  getTypes(store) {
    return store.types;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.data = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setData(store, payload) {
    store.data = payload;
  },
  clearData(store) {
    store.data = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async fetchData({ commit, state }) {
    let url = 'road_tax?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const api_response = await this.$axios.$get(url);
    const data = api_response.road_tax_list.map((datum) => {
      let vrm = ' ';
      let make_model = '';
      if (datum.vehicle && datum.vehicle.length > 0) {
        vrm = datum.vehicle[0].vrm;
        make_model = `${datum.vehicle[0].manufacturer} ${datum.vehicle[0].model}`;
      }
      return {
        ...datum,
        vrm,
        make_model,
      };
    });
    commit('setData', data);
    commit('setResultCount', api_response.total);
  },
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
