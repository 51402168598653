<template>
  <div>
    <el-form>
      <el-col :span="18">
        <el-date-picker
          placeholder="Pick a date"
          type="date"
          :value="value"
          @input="handleChange"
          :disabled="disable"
          size="large"
        ></el-date-picker>
      </el-col>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(selectedDate) {
      if (!this.disable) {
        this.$emit('input', selectedDate);
        this.$emit('change', selectedDate);
      }
    },
  },
};
</script>
