<template>
  <div class="tab-container">
    <el-row :offset="20">
      <el-col :span="12" class="col-style">
        <i class="el-icon-star-on"></i>
          <span>
            {{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}} {{title}}
          </span>
      </el-col>
      <el-col :span="12">
        <button
          :class= "{ active : tab.name === activeTab,  subtab : Object.keys(tab).includes('subtab')}"
          v-for="tab in tabs"
          :key="tab.name"
          @click="tabClicked(tab.name)"
        >
          {{ tab.label }}
        </button>
      </el-col>
    </el-row>
    </div>
</template>

<script>
export default {
  name: 'PulseTabs',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    // tabs: {
    //   type: Object,
    //   required: true,
    // },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'available',
          label: 'Available',
        },
        {
          name: 'live',
          label: 'Live',
          subtab: true,
        },
        {
          name: 'no_sale',
          label: 'No Sale',
        },
      ],
    };
  },
  methods: {
    tabClicked(tab) {
      this.$emit('tab-clicked', tab);
    },
  },
};
</script>

<style lang="scss" scoped>

.tab-container {
  text-align: right;
  overflow: hidden;
  margin-bottom: 1em;
  border-radius: 25px;
  border-bottom: 1px solid var(--green);
  width: 100%;
  background-color: #ffff;
}
.tab-container .col-style{
  text-align: left;
  text-align: start;
  padding: 0.4em 0.8em
}
.col-style span {
  font-size: 15px;
  color: var(--grey__dark);
  padding: 0.4em
}
 .tab-container button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.1s;
}
.tab-container button:hover, button.active {
  background-color: var(--green) !important;
  border-radius: 25px;
  color: #FFFF;
}
button.active:hover {
  background-color: var(--grey__slate) !important;
  border-radius: 25px;
  color: #FFFF;
}
button.subtab {
  // display:none;
}
</style>
