export const state = () => ({
  page: 0,
  charges: [],
  filters: '',
  statuses: {
    created: 'Ready to process',
    processed: 'Processed',
  },
  charges_to: {
    unknown: 'Unknown',
    driver: 'Driver',
    otto: 'Otto',
  },
  resultCount: 0,
});

export const getters = {
  getData(store) {
    return store.charges;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getStatuses(store) {
    return store.statuses;
  },
  getChargeTo(store) {
    return store.charges_to;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.charges = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setData(store, payload) {
    store.charges = payload;
  },
  clearData(store) {
    store.charges = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async fetchData({ commit, state }) {
    let url = 'charge?action=single';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const charges = await this.$axios.$get(url);
    const chargesDetails = charges.charge_list.map((charges) => {
      let vrm = ' ';
      if (charges.vehicle && charges.vehicle.length > 0) {
        vrm = charges.vehicle[0].vrm;
      }
      return {
        ...charges,
        vrm,
      };
    });
    commit('setData', chargesDetails);
    commit('setResultCount', charges.total);
  },
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
