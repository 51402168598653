<template>
  <div class="info-box"
  :style="`backgroundColor: var(--${computedColor})`">
    <v-icon dark>{{computedIcon}}</v-icon>
    <label class="info-label">
      <slot></slot>
    </label>
  </div>

</template>
<script>
export default {
  props: {
    color: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  computed: {
    computedColor() {
      return this.color ? this.color : 'green';
    },
    computedIcon() {
      return this.icon ? this.icon : 'mdi-star';
    },
  },
};
</script>
<style lang="scss" scoped>
.info-box {
  border-radius: 20px;
  border-top: none;
  border-right: none;
  border-left: none;
  margin: 0 0 1em 0;
  padding: 0.4em 0 0.4em 0.9em;
  color: #ffffff;
}
.info-label {
  color: white;
  padding: 1em;
  font-size: 14px;
}
.mdi:before {
  font-size: 16px;
}
</style>
