export const state = () => ({
  page: 0,
  payments: [],
  filters: '',
  payment_statuses: {
    outstanding: 'Outstanding',
    scheduled: 'Scheduled',
    processed: 'Processed',
    paused: 'Paused',
    'anchor-payment': 'Anchor Payment',
    cancelled: 'Cancelled',
  },
  references: {
    pcn: 'PCN',
    'accident-excess': 'Accident Excess',
    'congestion-charge': 'Congestion Charge',
    'dart-charge': 'Dartford Charge',
    'late-payment-fee': 'Late Payment Fee',
    mot: 'MOT',
    phv: 'PHV',
    holiday: 'Holiday',
    other: 'Other',
    deposit: 'Deposit',
    'weekly-payment-plan': 'Weekly Payment Plan',
    'accident-excess-plan': 'Accident Excess Plan',
    // "arrears-repayment-plan": "Consolidated Arrears"
  },
});
export const getters = {
  getPayments(store) {
    return store.payments;
  },
  getPages(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getPaymentStatuses(store) {
    return store.payment_statuses;
  },
  getReferences(store) {
    return store.references;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.payments = [];
    store.filters = '';
  },
  setPayments(store, payload) {
    store.payments = payload;
  },
  clearPayments(store) {
    store.payments = [];
  },
  setPages(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getPayments({ commit, state }) {
    let url = 'payment?results=5&order=week&sort=ascending';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const response = await this.$axios.$get(url);
    const payments = response.payment_list.map((payment) => ({
      ...payment,
    }));
    const sortA = payments;
    sortA.sort((a, b) => a.week - b.week);
    commit('setPayments', sortA);
  },
  async clearPayments({ commit }) {
    commit('clearPayments');
  },
  async setPages({ commit }, payload) {
    commit('setPages', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
