const THEORY_QUESTIONS = [
  {
    question: 'What does this sign mean?',
    image: {
      path: '/theory-images/question-1.jpeg',
      width: '5em',
    },
    answers: [
      ['Ahead only', false],
      ['End of speed limit', false],
      ['No entry', true],
    ],
    correctIndex: 2,
  },
  {
    question: 'You are driving towards traffic lights that have been on green for a while. What should you do?',
    answers: [
      ['Stay at the same speed', false],
      ['Be prepared to stop', true],
      ['Speed up so that you can get through them before they change', false],
      ['Brake hard so that you can be sure of stopping if they change to red', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'You are waiting to come out of a side street. Which vehicle is the hardest to see?',
    answers: [
      ['A milk float', false],
      ['A lorry', false],
      ['A tractor', false],
      ['A motorcycle', true],
    ],
    correctIndex: 3,
  },
  {
    question: 'Where should you stop your vehicle before deciding it’s safe to pull out?',
    image: {
      path: '/theory-images/question-4.jpeg',
      width: '200px',
    },
    answers: [
      ['Before the 2 broken white lines', true],
      ['On the broken white lines', false],
      ['No need to stop', false],
    ],
    correctIndex: 0,
  },
  {
    question: 'You are waiting to reverse between two cars into a parking space, on the left side of the road, when another car approaches from behind. What do you do?',
    answers: [
      ['Place the vehicle in reverse, apply the foot brake and signal left', true],
      ['Open your window and wave the other driver on', false],
      ['Do nothing until the other driver works it out for themselves', false],
    ],
    correctIndex: 0,
  },
  {
    question: 'What is the national speed limit on a dual carriageway?',
    answers: [
      ['70mph', true],
      ['80mph', false],
      ['50mph', false],
    ],
    correctIndex: 0,
  },
  {
    question: 'You are driving on a motorway and you\'re getting tired so you decide to stop. Where should you do this?',
    answers: [
      ['On a hard shoulder', false],
      ['At a service station', true],
      ['On the central reservation', false],
      ['On a slip road', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'You are driving down the road and you see a big group of pedestrians ahead of you, what do you do?',
    answers: [
      ['Continue at the same speed', false],
      ['Slow down and pay more attention to your surroundings', true],
      ['Speed up but keep an eye on the group', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'You are driving in heavy rain. Your separation distances are likely to be...',
    answers: [
      ['Ten times what they are in good conditions', false],
      ['Twice what they are in good conditions', true],
      ['Five times what they are in good conditions', false],
      ['The same as they are in good conditions', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'What should you do when you see this sign?',
    image: {
      path: '/theory-images/question-10.jpeg',
      width: '5em',
    },
    answers: [
      ['Carry on driving - it\'s your right of way', false],
      ['Be prepared to stop and look carefully before pulling out as it\'s not your right of way', true],
      ['There is never a need to stop but continue driving with caution as something may be coming', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'When are you allowed to wait in a box junction?',
    answers: [
      ['When you are waiting to turn right but are prevented from doing so by oncoming traffic', true],
      ['Never', false],
      ['When you are waiting at a zebra crossing', false],
      ['When you are stuck in a queue of traffic', false],
    ],
    correctIndex: 0,
  },
  {
    question: 'You want to make a U turn on the road. Before you do so you should...',
    answers: [
      ['Signal and then begin to manoeuvre so that other drivers will slow down', false],
      ['Use signals as well as indicators so that other drivers are fully aware of what you are about to do', false],
      ['Look over your shoulder to make sure that all is clear', true],
      ['Choose a lower gear so that you can make the manoeuvre really quickly', false],
    ],
    correctIndex: 2,
  },
  {
    question: 'Why should you always encourage passengers to exit onto the pavement (whenever possible)?',
    answers: [
      ['It\'s against the law for a passenger to get out of a vehicle into a road', false],
      ['It\'s safer for the passengers to get out onto the pavement and it avoids the risk of other vehicles hitting your open car door', true],
      ['It\'s best not to interfere with customers\' decisions. They have the right to exist the vehicle where and when they feel safe', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'If you are trying to find a pick up address or accepting a job, you should?',
    answers: [
      ['Slow down to a safe speed before using Google or the map on your phone to locate it', false],
      ['Pull over, stop and switch off your engine. Check the address details and find it either on a map or your SatNav before setting off again', true],
      ['Try and re-set your SatNav while you\'re still driving or balance the map on your steering wheel', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'What is a common cause of injuries to cyclists in cities that taxi drivers should be aware of?',
    answers: [
      ['Crashing into parked pars', false],
      ['Falling off their bikes when their trousers get caught in the chain', false],
      ['Vehicles turning and not seeing a cyclist that is to the left of them and knocking them off their bike', true],
    ],
    correctIndex: 2,
  },
  {
    question: 'When may you use your hazard warning lights?',
    answers: [
      ['Instead of sounding your horn in a built up area 11.30pm to 7am', false],
      ['On rural routes after warning signs of animals', false],
      ['On a motorway or on an unrestriced dual carriageway to warn of a hazard ahead', true],
      ['On the approach to toucan crossings where cyclists are waiting to cross', false],
    ],
    correctIndex: 2,
  },
  {
    question: 'You are driving along this road. The van cuts in close in front of you. what should you do?',
    image: {
      path: '/theory-images/question-17.jpeg',
      width: '200px',
    },
    answers: [
      ['Accelerate to get closer to the red van', false],
      ['Drop back to leave the correct separation distance', true],
      ['Flash your headlights several times', false],
      ['Give long blast on the horn', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'What is the common reason for a vehicle colliding into the rear of another vehicle?',
    answers: [
      ['Driver inattention and following other vehicles too closely', true],
      ['The driver in front hitting their brakes too hard', false],
      ['Poor road layouts and congestion', false],
    ],
    correctIndex: 0,
  },
  {
    question: 'What does this sign mean?',
    image: {
      path: '/theory-images/question-19.jpeg',
      width: '7em',
    },
    answers: [
      ['Buses, taxis, cyclists and motorbikes \'can use\' this lane during the hours specificed', true],
      ['Buses, taxis, cyclists and motorbikes \'must not use\' this lane during the hours specificed', false],
      ['Car park ahead for buses, taxis, cyclists and motorbikes', false],
    ],
    correctIndex: 0,
  },
  {
    question: 'If you are driving in 2 lanes of traffic and need to change lanes, what should you do?',
    answers: [
      ['Use your indicator and turn your bonnet towards the other lane so that another driver has no choice but to let you in', false],
      ['Maintain your speed but quickly move into an available gap when one becomes available', false],
      ['Use your indicator, slow down, wait for a gap and move over after checking it is safe', true],
    ],
    correctIndex: 2,
  },
  {
    question: 'While at a mini roundabout, you need to give way to...',
    answers: [
      ['Come to a halt even when there is no traffic', false],
      ['Give way to traffic on your left', false],
      ['Give way to traffic on your right', true],
      ['Give way to oncoming traffic ahead of you', false],
    ],
    correctIndex: 2,
  },
  {
    question: 'A passenger has said you have taken the wrong turn and will complain to the taxi company. What do you do?',
    answers: [
      ['Instantly turn around regardless of road markings and traffic', false],
      ['Turn into a side road or turn around when it is safe to do so', true],
      ['Check for an alternative route on the phone whilst driving', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'You will find that driving smoothly can...',
    answers: [
      ['Reduce journey times by about 15%', false],
      ['Reduce fuel consumption by about 15%', true],
      ['Increase fuel consumption by about 15%', false],
      ['Increase journey times by about 15%', false],
    ],
    correctIndex: 1,
  },
  {
    question: 'If you are in a collision that causes damage or injury to any other person, vehicle, animal or property, you must stop and give the following details to anyone who has reasonable grounds for requiring them',
    answers: [
      ['Your name, contact number, vehicle make & model and registration number', true],
      ['The vehicle owner\'s name and address', false],
      ['The vehicles make and registration', false],
      ['Insurance details', false],
    ],
    correctIndex: 0,
  },
];

const MOCK_THEORY_ANSWERS = {
  0: 'Ahead only',
  1: 'Stay at the same speed',
  2: 'A milk float',
  3: 'Before the 2 broken white lines',
  4: 'Place the vehicle in reverse apply the foot brake and signal left',
  5: '70mph',
  6: 'On a hard shoulder',
  7: 'Continue at the same speed',
  8: 'Ten times what they are in good conditions',
  9: "Carry on driving - it's your right of way",
  10: 'When you are waiting to turn right but are prevented from doing so by oncoming traffic',
  11: 'Signal and then begin to manoeuvre so that other drivers will slow down',
  12: "It's against the law for a passenger to get out of a vehicle into a road,13:Slow down to a safe speed before using Google or the map on your phone to locate it",
  14: 'Crashing into parked pars',
  15: 'Instead of sounding your horn in a built up area 11.30pm to 7am',
  16: 'Accelerate to get closer to the red van',
  17: 'Driver inattention and following other vehicles too closely',
  18: "Buses, taxis, cyclists and motorbikes 'can use' this lane during the hours specificed",
  19: 'Use your indicator and turn your bonnet towards the other lane so that another driver has no choice but to let you in',
  20: 'Come to a halt even when there is no traffic',
  21: 'Instantly turn around regardless of road markings and traffic',
  22: 'Reduce journey times by about 15%',
  23: 'Your name, contact number, vehicle make & model and registration number',
};

module.exports = {
  THEORY_QUESTIONS,
  MOCK_THEORY_ANSWERS,
};
