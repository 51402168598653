// Any API requests

const fetchData = async (store, context) => {
  const response = await context.$axios.$get('/url');
  store.commit('setExample', response);
};

module.exports = {
  fetchData,
};
