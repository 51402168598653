export const state = () => ({
  page: 0,
  drivers: [],
  filters: '',
  resultCount: 0,
});

export const getters = {
  getDrivers(store) {
    return store.drivers;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.drivers = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setDrivers(store, payload) {
    store.drivers = payload;
  },
  clearDrivers(store) {
    store.drivers = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getDrivers({ commit, state }) {
    let url = 'driver?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const drivers = await this.$axios.$get(url);
    commit('setDrivers', drivers.driver_list);
    commit('setResultCount', drivers.total);
  },
  async clearVehicles({ commit }) {
    commit('clearVehicles');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
