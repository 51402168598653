import Vue from 'vue';
import { currency, isoDate } from './locale';
import { agreementType, agreementStatus } from './agreement';
import servicingType from './servicing';

Vue.filter('currency', currency);
Vue.filter('isoDate', isoDate);

// Application / Agreement
Vue.filter('applicationType', agreementType);
Vue.filter('agreementType', agreementType);
Vue.filter('agreementStatus', agreementStatus);

// Servicing
Vue.filter('servicingType', servicingType);
