<template>
    <v-card id="filters-card-style">
      <v-container class="pa-6" fluid>
        <v-row class="p-filters-wrapper" justify="space-around" align="center">
          <slot name="filters"></slot>
        </v-row>
        <v-row align="center">
          <v-spacer></v-spacer>
          <v-btn small dark color="var(--primary)" @click="submit" depressed
            ><strong>Filter Results</strong></v-btn
          >
          <v-btn small dark color="var(--secondary)" @click="clear" depressed
            ><strong>Clear Filters</strong></v-btn
          >
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
export default {
  name: "PFilters",
  methods: {
    async submit() {
      this.$emit("submit");
    },
    async clear() {
      this.$emit("clear");
    },
  },
};
</script>

<style lang="scss">
.v-text-field,
.v-select,
.v-btn {
  margin: 0 0.5em;
  font-size: 14px;
}

#filters-card-style {
  padding-top: 1em;
}

.p-filters-wrapper {
  margin-bottom: 0.5em;
}
</style>
