<template>
  <div v-loading="loading">
    <a @click="goBack">
      <img src="back_light.png" style="height:40px; margin:0 0 20px 20px;" v-show="showBackButton" />
    </a>
    <el-row class="div-style">
      <nuxt id="inner" class="layout" />
    </el-row>
    <div>
    <el-button id="button-style" @click="startYourOrder" v-show="showStartOrderButton">
      <a>
        <h1>Start your order</h1>
        <!-- <img src="/valet-parking-1.png" id="forward" /> -->
      </a>
    </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: false,
      showBackButton: false,
      showStartOrderButton: false,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.userId = await this.$store.getters['auth/userId'];
    if (this.$route.name == 'profile') {
      this.showStartOrderButton = true;
    }
    // check route is document-upload and show back button
    if (this.$route.name == 'profile-document-upload') {
      this.showBackButton = true;
    }
    this.loading = false;
  },
  watch: {
    $route(to, from) {
      // check if entering or exiting document change, and show or hide back button
      if (
        to.name == 'profile-document-upload'
        && from.name != 'profile-document-upload'
      ) {
        this.showBackButton = true;
        this.showStartOrderButton = false;
      } else if (
        from.name == 'profile-document-upload'
        && to.name != 'profile-document-upload'
      ) {
        this.showBackButton = false;
        this.showStartOrderButton = true;
      } else if (to.name == 'profile' && from.name != 'profile') {
        this.showStartOrderButton = true;
      } else if (from.name == 'profile' && to.name != 'profile') {
        this.showStartOrderButton = false;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async startYourOrder() {
      await this.$axios
        .$get(`driver?id=${this.userId}`)
        .then((resp) => {
          this.driverInfo = resp.driver_list[0];
        })
        .then(async () => {
          this.driverDocuments = await this.$axios.$get(
            `/upload/driver?driver=${this.userId}`,
          );
          this.documentList = this.driverDocuments.document_driver_list.map(
            (doc) => doc.document_type,
          );
        });
      const {
        first_name,
        last_name,
        email,
        mobile_phone,
        address_line_1,
        post_code,
        country,
        dob,
        dvla_document_no,
        pco_document_no,
        dvla_expiry_date,
        pco_expiry_date,
      } = this.driverInfo;
      if (
        !first_name
        || !last_name
        || !email
        || !mobile_phone
        || !address_line_1
        || !post_code
        || !country
        || !dob
        || !dvla_document_no
        || !pco_document_no
        || !dvla_expiry_date
        || !pco_expiry_date
      ) {
        this.warningProfile();
      } else if (
        !this.documentList.length > 0
        || !this.documentList.includes('pco')
        || !this.documentList.includes('dvla_front')
        || !this.documentList.includes('poa')
        || !this.documentList.includes('dvla_back')
      ) {
        this.warningDocuments();
      } else {
        this.$router.push(this.$store.getters.getRoute('application_start'));
      }
    },
    warningProfile() {
      this.$alert(
        'You need to fill in all of the required fields to proceed.',
        'Sorry about that!',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        },
      );
    },
    warningDocuments() {
      this.$alert(
        'You need to upload all of the required documents to proceed.',
        'Sorry about that!',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        },
      );
    },
  },
};
</script>

<style scoped lang='scss'>
div {
  background: white;
  height: 100%;
  position: relative;
  z-index: 1;
}
h1 {
  color: white;
  font-size: 18.72px;
}
#inner {
  width: 980px;
  z-index: 3;
}
#steps {
  width: 100%;
  margin-bottom: 3em;
}
#forward {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  // bottom: 35px;
}
#button-style {
  // background-color: #a370cc;
  background: rgba(149, 214, 0, 0.8);
  // background: rgba(0, 151, 19, 0.1);
  border: none;
  text-align: center !important;
  position: fixed;
  width: 100%;
  bottom: 0px;
  &:hover {
    background: rgba(0, 0, 20, 0.18);
    color: black !important;
  }
  z-index: 99;
}
  header {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3em;
    padding: 2em 1em;
    img {
      height: 2.5em;
      padding-left: 1em;
    }
    a, span {
      margin: 0 0.5em;
      font-size: 18px;
      color: inherit;
      text-decoration: none;
      color: white;
      cursor: pointer;
    }
  }
  .div-style {
    max-width: 980px;
    margin: 4em auto;
    align-items: center;
    position: relative;
  }
</style>
