export const state = () => ({
  page: 1,
  data: [],
  filters: '',
  headers: [
    { text: 'Manufacturer', value: 'manufacturer', sortable: false },
    { text: 'Model', value: 'model', sortable: false },
    {
      text: 'Colour', value: 'color', sortable: false,
    },
    { text: 'Fuel Type', value: 'fuel_type', sortable: false },
    { text: 'Action', value: 'action', sortable: false },

  ],
  sortBy: undefined,
  sortDesc: undefined,
  totalResults: undefined,
  itemsPerPage: undefined,
});

export const getters = {
  getData(state) {
    return state.data;
  },
  getPage(state) {
    return state.page;
  },
  getHeaders(state) { // need
    return state.headers;
  },
  getTotalResults(state) { // need
    return state.totalResults;
  },
  getItemsPerPage(state) { // need
    return state.itemsPerPage;
  },
};

export const mutations = {
  setData(store, payload) { // need
    store.data = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setTotalResults(state, value) {
    state.totalResults = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setSortDesc(state, value) {
    state.sortDesc = value;
  },
  setItemsPerPage(state, value) {
    state.itemsPerPage = value;
  },
};

export const actions = {
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
  async loadPage({ commit, state }) {
    let url = 'vehicle_type?visible=1';
    url += `&page=${state.page - 1}`;

    if (state.filters) url += state.filters;
    if (state.sortBy) url += `&order_by=${state.sortBy}`;
    if (state.sortDesc !== undefined) url += `&order=${state.sortDesc ? 'descending' : 'ascending'}`;
    if (state.itemsPerPage !== undefined) url += `&results=${state.itemsPerPage}`;

    const response = await this.$axios.$get(url);
    commit('setData', response.vehicle_type_list);
    // commit('setTotalResults', response.total);
  },
  setSortBy({ commit }, value) {
    commit('setSortBy', value);
  },
  setSortDesc({ commit }, value) {
    commit('setSortDesc', value);
  },
  setItemsPerPage({ commit }, value) {
    commit('setItemsPerPage', value);
  },
};
