import validate from './validation';

import request from './requests';

export const state = () => ({
  loading: false,
  example: '',
});

export const getters = {
  getLoading(state) {
    return state.loading;
  },
  getExample(state) {
    return state.example;
  },
};

export const mutations = {
  reset(state) {
    state.loading = false;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setExample(state, value) {
    state.example = value;
  },
};

export const actions = {
  reset({ commit }) {
    commit('reset');
  },
  setLoading({ commit }, value) {
    commit('setLoading', value);
  },
  setExample({ commit }, value) {
    commit('setExample', value);
  },
  async fetchData(store) {
    await request.fetchData(store, this);
  },
  validateExample(store, value) {
    return validate.example(store, value);
  },
};
