<template>
  <div class="p-select-container">
    <div
      :class="`p-input-inner p-select${
        showOptions ? ' p-input-inner-focus' : ''
      }`"
      @click="focused"
      ref="p-select"
    >
      {{ value ? options[value] : "Select option" }}
    </div>
    <div
      class="p-option-container"
      v-show="showOptions"
      ref="p-option-container"
    >
      <div
        :class="`p-option${value === option ? ' p-option-selected' : ''}`"
        v-for="option in Object.keys(options)"
        :key="option"
        @click="change(option)"
        ref="p-option"
      >
        {{ options[option] }}
      </div>
    </div>
    <div> <label>

      </label> </div>
  </div>
</template>

<script>
export default {
  name: 'PSelect',
  data() {
    return {
      showOptions: false,
    };
  },
  methods: {
    isSelected(option) {
      if (this.value) {
        return option === this.value;
      }
      return false;
    },
    change(e) {
      this.showOptions = false;
      this.$emit('input', e);
      this.$emit('change', e);
    },
    focused(e) {
      console.log('focused', e);
      this.showOptions = true;
    },
    clickHappened(e) {
      const targetClasses = Object.values(e.target.classList);
      const optionEl = Object.values(this.$refs['p-option']);
      const selectEl = this.$refs['p-select'];
      if (
        this.showOptions
        && !optionEl.includes(e.target)
        && selectEl !== e.target
      ) {
        this.showOptions = false;
      }
    },
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
    },
  },
  created() {
    document.addEventListener('click', this.clickHappened);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickHappened);
  },
};
</script>

<style lang="scss" scoped>
.d-block {
  display: inline-block;
}
.p-select-container {
  position: relative;
  display: inline-block;
  margin: 5px 5px 5px 0;
  font-size: 13px;
  line-height: 32px;
}
.p-option-container {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  z-index: 99;
  border-top: none;
  overflow: hidden;
  width: 200px;
}
.p-input-inner {
  width: 200px;
  height: 32px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.p-input-inner-focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.p-option {
  padding: 0 15px;
}
.p-option:hover,
.p-option-selected {
  background-color: var(--grey__light);
}
</style>
