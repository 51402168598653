import { createDriver, update, createOrder } from './requests';
import { validateDocuments } from './validation';

const LIABILITY_QUESTIONS = [
  'Do you suffer from diabetes or use insulin?',
  'Do you wear glasses?',
  'Do you, or any immediate family members, suffer from epilepsy?',
  'Have you developed any medical condition in the past year?',
  'Have you suffered from anxiety or depression?',
  'Do you have any type of sleep disorder?',
  "Have you been involved in an accident or made any insurance claims in the last 3 years, whether your fault or someone else's fault?",
  'Have you been convicted of, or have any pending, motoring offences in the last 5 years?',
  'Have you been convicted of, or have any pending, criminal convictions in the last 5 years?',
];

export const state = () => ({
  liability: {
    questions: LIABILITY_QUESTIONS,
    answers: '',
  },
  driver: {
    dvla_number: '',
    driver_id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    mobile_phone: '',
    dob: new Date(),
    pco_number: '',
    no_area_code: '',
    dvla_expiry_date: null,
  },
  documents: {
    dvla: {
      dvla_front: {
        label: 'DVLA Front',
        status: 'awaiting',
      },
      dvla_back: {
        label: 'DVLA Back',
        status: 'awaiting',
      },
    },
  },
  assessment: {
    theory_required: true,
    practical_required: true,
  },
  liabilityValidation: false,
  branch: '',
  isSmsFailed: false,
  loading: false,
  isDriverFieldBeingEdited: false,
});

export const getters = {
  getData(store) {
    return store;
  },
  getDriverId(store) {
    return store.driver.driver_id;
  },
  getDriverDetails(store) {
    return store.driver;
  },
  getDvlaExpiryDate(state) {
    return state.driver.dvla_expiry_date;
  },
  getDocuments(state) {
    return state.documents;
  },
  getLiabilityAnswer(state) {
    return state.liability.answers;
  },
  getLiabilityIsSynced(state) {
    return state.liability.isSynced;
  },
  getLiabilityQuestions(state) {
    return state.liability.questions;
  },
  getLoading(store) {
    return store.loading;
  },
  getLiabilityValidation(store) {
    return store.liabilityValidation;
  },
  getSmsFailed(store) {
    return store.isSmsFailed;
  },
  getIsDriverFieldBeingEdited(store) {
    return store.isDriverFieldBeingEdited;
  },
};
export const mutations = {
  reset(store) {
    store.liability = {
      questions: LIABILITY_QUESTIONS,
      answers: '',
      isSynced: false,
    };
    store.driver = {
      dvla_number: '',
      driver_id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      mobile_phone: '',
      dob: new Date(),
      pco_number: '',
      no_area_code: '',
      dvla_expiry_date: null,
    };
    Object.keys(store.documents.dvla).forEach((documentType) => {
      store.documents.dvla[documentType].status = 'awaiting';
    });
    store.assessment = {
      theory_required: true,
      practical_required: true,
    };
    store.branch = '';
    store.loading = false;
    store.isSmsFailed = false;
    store.isDriverFieldBeingEdited = false;
    store.liabilityValidation = false;
  },
  setDriverDetail(store, payload) {
    store.driver[payload.key] = payload.value;
  },
  setDriverFromLookup(state, payload) {
    state.driver.driver_id = payload.id;
    state.driver.first_name = payload.first_name;
    state.driver.middle_name = payload.middle_name;
    state.driver.last_name = payload.last_name;
    state.driver.dvla_number = payload.dvla_document_no;
    state.driver.dvla_expiry_date = payload.dvla.expiryDate;
    state.driver.dob = new Date(payload.dob);
    state.driver.email = payload.email;
    state.driver.no_area_code = payload.no_area_code;
    state.driver.mobile_phone = payload.mobile_phone;
    state.driver.pco_number = payload.pco_document_no;
    state.documents.dvla.dvla_front.status = payload.dvla.hasExpired ? 'awaiting' : 'succeeded';
    state.documents.dvla.dvla_back.status = payload.dvla.hasExpired ? 'awaiting' : 'succeeded';
  },
  setDocumentStatus(state, payload) {
    const { document } = payload;
    const { documentType } = payload;
    const { status } = payload;
    state.documents[document][documentType].status = status;
  },
  setDvlaExpiryDate(state, value) {
    state.driver.dvla_expiry_date = value;
  },
  setDvlaNumber(state, value) {
    state.driver.dvla_number = value;
  },
  setLiabilityAnswers(state, payload) {
    state.liability.answers = payload;
  },
  setLiabilityIsSynced(state, payload) {
    state.liability.isSynced = payload;
  },
  setLoading(store, value) {
    store.loading = value;
  },
  setDataDynamic(store, payload) {
    store.driver = payload;
  },

  setAssessment(store, payload) {
    store.assessment[payload.item] = payload.value;
  },
  setBranch(store, payload) {
    store.branch = payload;
  },
  setIsDriverFieldBeingEdited(store, payload) {
    store.isDriverFieldBeingEdited = payload;
  },
  setLiabilityValidation(store, payload) {
    store.liabilityValidation = payload;
  },
  setSmsFailed(store, payload) {
    store.isSmsFailed = payload;
  },
};
export const actions = {
  reset({ commit }) {
    commit('reset');
  },
  setDocumentStatus({ commit }, payload) {
    commit('setDocumentStatus', payload);
  },
  setDriverDetail({ commit }, payload) {
    commit('setDriverDetail', payload);
  },
  setDriverFromLookup({ commit }, payload) {
    commit('setDriverFromLookup', payload);
  },
  setDvlaExpiryDate({ commit }, payload) {
    commit('setDvlaExpiryDate', payload);
  },
  setDvlaNumber({ commit }, value) {
    commit('setDvlaNumber', value);
  },
  setLiabilityAnswers({ commit }, payload) {
    commit('setLiabilityAnswers', payload);
  },
  setLiabilityIsSynced({ commit }, payload) {
    commit('setLiabilityIsSynced', payload);
  },
  async createDriver(store) {
    await createDriver(store, store.state.driver, this);
  },
  async updateDriverDetails(store, payload) {
    await update(store, payload, this);
  },
  async createOrder(store) {
    await createOrder(store, this);
  },
  async setAssessment({ commit }, payload) {
    commit('setAssessment', payload);
  },
  async setBranch({ commit }, payload) {
    commit('setBranch', payload);
  },
  async setIsDriverFieldBeingEdited({ commit }, payload) {
    commit('setIsDriverFieldBeingEdited', payload);
  },
  async liabilityValidation({ commit, state }) {
    const { questions } = state.liability;
    const { answers } = state.liability;
    if (answers) {
      const validation = Object.keys(JSON.parse(answers)).length === questions.length;
      commit('setLiabilityValidation', validation);
    }
  },
  async validateDocuments({ state, commit }) {
    const areDocsValid = await validateDocuments(state, commit, this);
    return areDocsValid;
  },
};
