import * as Logger from '@/utils/logger';

export default {
  data() {
    return {
      storeName: '',
      baseFilters: '',
      pageSize: 10,
      currentPage: 0,
    };
  },
  methods: {
    async setFilters(filters) {
      this.$store.dispatch(`${this.storeName}/setFilters`, this.baseFilters + filters);
    },
    async setOrder(orderString) {
      this.$store.dispatch(`${this.storeName}/setOrder`, orderString);
    },
    async setBaseFilters(baseFilters) {
      this.baseFilters = baseFilters;
    },
    async setStore(storeName) {
      this.storeName = storeName;
    },
    async setPageSize(pageSize) {
      this.pageSize = pageSize;
    },
    async loadPage(page) {
      this.loading = true;
      try {
        this.$store.dispatch(`${this.storeName}/setPage`, page - 1);
        await this.$store.dispatch(`${this.storeName}/fetchData`);
      } catch (err) {
        Logger.log(err);
        this.$message({
          message: 'Error fetching data.',
          type: 'error',
          center: true,
        });
      }
      this.loading = false;
    },
    async loadFirst(baseFilters) {
      this.loading = true;
      this.setBaseFilters(baseFilters);
      await this.$store.dispatch(`${this.storeName}/setFilters`, this.baseFilters);
      await this.loadPage(1);
      this.loading = false;
    },
    async reloadPage() {
      await this.loadPage(this.currentPage);
    },
    async clearData() {
      await this.$store.dispatch(`${this.storeName}/reset`);
    },
  },
  computed: {
    listData() {
      return this.$store.getters[`${this.storeName}/getData`];
    },
    results() {
      return this.$store.getters[`${this.storeName}/getResultCount`];
    },
  },
};
