export const state = () => ({
  componentTypes: {
    driverSide: [
      'front_bumper',
      'windscreen',
      'driver_fc',
      'driver_fa',
      'driver_ra',
      'driver_rc',
      'rear_bumper',
      'internal_1',
      'internal_2',
    ],
    passengerSide: [
      'roof',
      'passenger_rc',
      'passenger_fc',
      'passenger_fa',
      'passenger_ra',
      'interior_1',
      'interior_2',
      'seatbelts',
    ],
    otherUploads: [
      'odometer',
      'telematics',
    ],
  },
  documents: {
    front_bumper: {
      label: 'Front Bumper',
      status: 'awaiting',
    },
    windscreen: {
      label: 'Windscreen',
      status: 'awaiting',
    },
    driver_fc: {
      label: 'Driver side - Front corner',
      status: 'awaiting',
    },
    driver_fa: {
      label: 'Driver side - Front alloy / wheel trim',
      status: 'awaiting',
    },
    driver_ra: {
      label: 'Driver side - Back right alloy / wheel trim',
      status: 'awaiting',
    },
    driver_rc: {
      label: 'Driver side - Rear corner',
      status: 'awaiting',
    },
    rear_bumper: {
      label: 'Rear bumper',
      status: 'awaiting',
    },
    internal_1: {
      label: 'Internal - Boot',
      status: 'awaiting',
    },
    internal_2: {
      label: 'Internal - Boot 2',
      status: 'awaiting',
    },
    roof: {
      label: 'Roof',
      status: 'awaiting',
    },
    passenger_rc: {
      label: 'Passenger side - Rear corner',
      status: 'awaiting',
    },
    passenger_fc: {
      label: 'Passenger side - Front corner',
      status: 'awaiting',
    },
    passenger_fa: {
      label: 'Passenger side - Front right alloy / wheel trim',
      status: 'awaiting',
    },
    passenger_ra: {
      label: 'Passenger side - Back right alloy / wheel trim',
      status: 'awaiting',
    },
    interior_1: {
      label: 'Interior - Front Seats',
      status: 'awaiting',
    },
    interior_2: {
      label: 'Interior - Back Seats',
      status: 'awaiting',
    },
    seatbelts: {
      label: 'Seatbelts',
      status: 'awaiting',
    },
    telematics: {
      label: 'Telematics',
      status: 'awaiting',
    },
    odometer: {
      label: 'Odemeter',
      status: 'awaiting',
    },
  },
});

export const getters = {
  getDocuments(store) {
    return store.documents;
  },
  getDriverSideDocuments(store) {
    return store.componentTypes.driverSide.map((docType) => store.documents[docType]);
  },
  getPassengerSideDocuments(store) {
    return store.componentTypes.passengerSide.map((docType) => store.documents[docType]);
  },
  getOtherUploadsDocuments(store) {
    return store.componentTypes.otherUploads.map((docType) => store.documents[docType]);
  },
};
export const mutations = {
  reset(store) {
    Object.keys(store.documents).forEach((key) => {
      store.documents[key].status = 'awaiting';
    });
  },
  setStatus(store, payload) {
    const { documentType } = payload;
    const { status } = payload;
    store.documents[documentType].status = status;
  },
};

const getComponentDocuments = (store, componentName) => {
  if (!store.state.componentTypes.hasOwnProperty(componentName)) {
    throw new Error(`Component Name: ${componentName} does not exist`);
  }
  const componentTypes = store.state.componentTypes[componentName];

  const documents = {};
  componentTypes.forEach((documentType) => {
    documents[documentType] = store.state.documents[documentType];
  });
  return documents;
};

export const actions = {
  reset({ commit }) {
    commit('reset');
  },
  setStatus({ commit }, payload) {
    commit('setStatus', payload);
  },
  async validateDocumentUpload(store, payload) {
    // Get list of document types for the given component
    // Check all of the document are succeeded status
    // Call API to get document types in RDS
    // Check the list we have is the same as in RDS
    const { componentName } = payload;
    const { conditionReportId } = payload;
    const documents = getComponentDocuments(store, componentName);

    // Check if upload to s3 has been successful
    let areAllUploadedToS3 = true;
    Object.keys(documents).forEach((key) => {
      if (documents[key].status !== 'succeeded') {
        // Set status to failed
        const docPayload = {
          documentType: key,
          status: 'failed',
        };
        store.commit('setStatus', docPayload);
        areAllUploadedToS3 = false;
      }
    });

    if (!areAllUploadedToS3) return;

    // Get document types stored in database
    let dbDocumentTypes = null;
    try {
      dbDocumentTypes = await this.$axios.$get(
        `/v2/servicing/document_condition_report/types/${conditionReportId}`,
      );
    } catch (error) {
      // TODO
      // We only want to set the dbDocumentTypes if its a 404. else we want to throw the error
      dbDocumentTypes = [];
    }
    // Check documents stored in db
    let allSucceeded = true;
    Object.keys(documents).forEach((key) => {
      if (!dbDocumentTypes.includes(key)) {
        // Set status to failed
        const payload = {
          documentType: key,
          status: 'failed',
        };
        store.commit('setStatus', payload);
        allSucceeded = false;
      }
    });
    return allSucceeded;
  },
};
