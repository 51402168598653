export const state = () => ({
  page: 0,
  sales: [],
  filters: '',
});

export const getters = {
  getSales(store) {
    return store.sales;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.sales = [];
    store.filters = '';
  },
  setSales(store, payload) {
    store.sales = payload;
  },
  clearSales(store) {
    store.sales = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};
export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getSales({ commit, state }) {
    let url = '/application?application_status=pending&results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const sales = await this.$axios.$get(url);
    const formatted = sales.application_list.map((app) => ({
      ...app,
      branch_name: app.branch[0].name,
      driver_name: `${app.driver[0].first_name} ${app.driver[0].last_name}`,
      driver_id: app.driver[0].id,
    }));
    commit('setSales', formatted);
  },
  async clearSales({ commit }) {
    commit('clearSales');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
