/**
 *
 * @param {Object} options
 * @param {Number} options.balloonValue
 * @param {Boolean} options.insurance
 * @param {Boolean} options.servicing
 * @returns
 */
const getValidPackage = (state, options) => {
  if (!state.packages.length > 0) return null;
  const optionsArray = Object.keys(options);

  const cleanedOptionsKeys = optionsArray.filter((item) => options[item] !== undefined);

  return state.packages.find((pkg) => cleanedOptionsKeys.filter((field) => options[field] === pkg[field]).length === cleanedOptionsKeys.length);
};

const formatPackages = (commit, packageDetails) => {
  const formattedPackageDetails = {};
  const pricings = {};
  packageDetails.pricings.forEach((item) => {
    const key = item.depositAmount;
    pricings[key] = item.weeklyAmount;
  });
  formattedPackageDetails.pricings = pricings;
  formattedPackageDetails.totalWeeks = packageDetails.totalWeeks;
  commit('setPackageDetails', formattedPackageDetails);
};

module.exports = { getValidPackage, formatPackages };
