import { fetchVehicleList } from "./requests";

export const state = () => ({
  page: 1,
  data: [],
  filters: "",
  headers: [
    { text: "Manufacturer", value: "manufacturer", sortable: false },
    { text: "Model", value: "model", sortable: false },
    { text: "Color", value: "color", sortable: false },
    { text: "VIN", value: "vin", sortable: false },
    { text: "VRM", value: "vrm", sortable: false },
    { text: "Action", value: "action", sortable: false },
  ],
  sortBy: undefined,
  sortDesc: undefined,
  totalResults: undefined,
  itemsPerPage: undefined,

  assignedVehicle: "",
});

export const getters = {
  getData(state) {
    return state.data;
  },
  getPage(state) {
    return state.page;
  },
  getHeaders(state) {
    return state.headers;
  },
  getTotalResults(state) {
    return state.totalResults;
  },
  getItemsPerPage(state) {
    return state.itemsPerPage;
  },
};

export const mutations = {
  setData(store, payload) {
    store.data = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setTotalResults(state, value) {
    state.totalResults = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setSortDesc(state, value) {
    state.sortDesc = value;
  },
  setItemsPerPage(state, value) {
    state.itemsPerPage = value;
  },
  clearData(state) {
    state.data = [];
    state.filters = "";
    state.headers = [];
    state.sortBy = undefined;
    state.sortDesc = undefined;
    state.totalResults = undefined;
    state.itemsPerPage = undefined;
  },
};

export const actions = {
  clearData({ commit }) {
    commit("clearData");
  },
  setPage({ commit }, payload) {
    commit("setPage", payload);
  },
  setFilters({ commit }, payload) {
    commit("setFilters", payload);
  },
  setSortBy({ commit }, value) {
    commit("setSortBy", value);
  },
  setSortDesc({ commit }, value) {
    commit("setSortDesc", value);
  },
  setItemsPerPage({ commit }, value) {
    commit("setItemsPerPage", value);
  },
  async loadPage({ commit, state }) {
    fetchVehicleList(commit, state, this);
  },
};
