const getDaysBetween = (date1, date2) =>
  // both are already date objects
  Math.abs(Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24)));

const datetimeToDate = (value) => {
  /*
    This was written so that our element ui dates can be formatted to pass to our API
    Its the same as this.$moment(date).format('YYYY-MM-DD')
    */
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

module.exports = {
  getDaysBetween,
  datetimeToDate,
};
