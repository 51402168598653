<template>
  <div v-loading="loading">
    <el-form-item size="small" label="Driver's PCO" prop="driver">
      <el-input
        v-model="formDriverPco"
        size="small"
        @change="inputChanged"
        @keydown.enter.native.prevent="preventDefaultEnter"
        @clear="clearData"
        clearable
      />
      <small
        class="d-block"
        :style="`margin-left:${labelWidth}`"
        v-if="driverObject"
        >{{ driverObject.first_name }} {{ driverObject.last_name }}</small
      >
    </el-form-item>

    <el-form-item size="small" label="Agreement" prop="agreement">
      <el-select
        class="full"
        v-model="formAgreementId"
        @change="updateAgreement"
        placeholder="Select an agreement"
      >
        <el-option
          v-for="a in agreements"
          :key="a.id"
          :value="a.id"
          :label="`${agreementTypes[a.agreement_type]} ${
            agreementStatuses[a.agreement_status]
          } ${a.agreement_start} ${a.vehicle[0].vrm}`"
        />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'PSelectAgreement',
  data() {
    return {
      agreements: [],
      driverObject: null,
      formDriverPco: null,
      formAgreementId: null,
      loading: false,
    };
  },
  props: {
    value: {
      required: false,
      default: null,
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    inputWidth: {
      type: String,
      default: '200px',
    },
  },
  methods: {
    async loadForm() {
      if ((!this.value || !Object.keys(this.value)) && !this.driverObject) {
        // no driver or agreement so all fields null
        this.agreements = [];
        this.driverObject = null;
        this.formDriverPco = null;
        this.formAgreementId = null;
      } else if (this.value) {
        // agreement given so all fields populated
        if (Array.isArray(this.value.driver)) this.driverObject = this.value.driver[0];
        else this.driverObject = await this.getDriver(this.value.driver, 'id');
        this.formDriverPco = this.driverObject.pco_document_no;
        this.agreements = await this.getAgreements(this.driverObject);
        this.formAgreementId = this.value.id;
      } else {
        // Just driver given from search so only agreement options populated
        this.formDriverPco = this.driverObject.pco_document_no;
        this.agreements = await this.getAgreements(this.driverObject);
      }
    },
    async search(val) {
      if (!val) return;

      try {
        this.loading = true;
        this.clearData();
        this.driverObject = await this.getDriver(val, 'pco_document_no');
        await this.loadForm();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.clearData();
        this.loading = false;
      }
    },
    async getDriver(val, key) {
      try {
        const drivers = await this.$axios.$get(`/driver?${key}=${val}`);
        const driver = drivers.driver_list[0];
        return driver;
      } catch (e) {
        this.$message({
          message: 'Cannot find driver',
          type: 'error',
          center: true,
        });
        this.clearData();
        return null;
      }
    },
    async getAgreements(driver) {
      try {
        const response = await this.$axios.$get(
          `/agreement?driver=${driver.id}`,
        );
        const agreements = response.agreement_list;
        return agreements;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    updateAgreement(val) {
      const agreement = this.agreements.filter((a) => a.id == val)[0];
      this.internalChange = true;
      this.$emit('input', agreement);
      this.$emit('change', agreement);
    },
    clearData() {
      this.driverObject = null;
      this.formDriverPco = null;
      this.formAgreementId = null;
      this.agreements = [];
      this.internalChange = true;
      this.$emit('input', null);
      this.$emit('change', null);
    },
    inputChanged(val) {
      // handles change if enter key not already pressed
      if (
        this.driverObject
        && this.driverObject.pco_document_no.toUpperCase() == val.toUpperCase()
      ) return;
      this.search(val);
    },
    preventDefaultEnter(e) {
      // handle search on enter key pressed
      this.search(e.srcElement.value);
    },
  },
  watch: {
    async value(val) {
      if (!this.internalChange) {
        this.loading = true;
        await this.loadForm();
        this.loading = false;
      }
      this.internalChange = false;
    },
  },
  computed: {
    agreementTypes() {
      return this.$store.getters['agreements/getTypes'];
    },
    agreementStatuses() {
      return this.$store.getters['agreements/getStatuses'];
    },
  },
  async created() {
    this.loading = true;
    await this.loadForm();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
small {
  display: block;
  font-size: smaller;
}
</style>
