export const state = () => ({
  page: 0,
  transactions: [],
  filters: '',
  statuses: {
    successful: 'Successful',
    failure: 'Failed',
    pending: 'Pending',
  },
});

export const getters = {
  getTransactions(store) {
    return store.transactions;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.transactions = [];
    store.filters = '';
  },
  setTransactions(store, payload) {
    store.transactions = payload;
  },
  clearTransactions(store) {
    store.transactions = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getTransactions({ commit, state }) {
    let url = 'transaction?results=10&order=created&sort=ascending';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const transactions = await this.$axios.$get(url);
    const formatted = transactions.transaction_list.map((transaction) => {
      const amountInPounds = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(transaction.amount);
      transaction.status = state.statuses[transaction.status];
      return {
        total: amountInPounds,
        formatted_amount: amountInPounds,
        ...transaction,
      };
    });
    commit('setTransactions', formatted);
  },
  async clearTransactions({ commit }) {
    commit('clearTransactions');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
