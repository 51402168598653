const fetchDetails = async (store, applicationId, context) => {
  const response = await context.$axios.$get(`/v2/application/driver/additional_details/${applicationId}`);
  // we are reformatting the response for front end usage
  const formattedResponse = {
    addressLine1: response.address_line_1,
    addressLine2: response.address_line_2,
    city: response.city,
    postCode: response.postcode,
    country: response.country,
    driverId: response.driver_id,
    driverName: response.driver_name,
    dvlaNumber: response.dvla_number,
    pcoNumber: response.pco_number,
    dvlaTestDate: response.dvla_test_date
      ? response.dvla_test_date.substr(0, 10) : response.dvla_test_date,
    nationalInsurance: response.national_insurance,
    uberRating: response.uber_rating,
    referralSource: response.referal_source,
    pco: {
      hasExpired: response.pco.has_expired,
      expiryDate: response.pco.expiry_date,
    },
    poa: {
      hasExpired: response.poa.has_expired,
      expiryDate: response.poa.expiry_date,
    },
  };
  formattedResponse.applicationId = applicationId;
  store.commit('setFormDetailsByApi', formattedResponse);
};

const updateDetails = async (state, context) => {
  const payload = {
    application_id: state.formDetails.applicationId,
    address_line_1: state.formDetails.addressLine1,
    address_line_2: state.formDetails.addressLine2 ? state.formDetails.addressLine2 : '',
    city: state.formDetails.city,
    postcode: state.formDetails.postCode,
    country: state.formDetails.country,
    dvla_test_date: state.formDetails.dvlaTestDate, // This date must be in the past
    national_insurance: state.formDetails.nationalInsurance.toUpperCase(),
    uber_rating: state.formDetails.uberRating,
    referral_source: state.formDetails.referralSource,
  };
  context.$axios.$put('/v2/application/driver/additional_details', payload);
};

module.exports = {
  fetchDetails,
  updateDetails,
};
