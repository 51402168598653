import { THEORY_QUESTIONS, MOCK_THEORY_ANSWERS } from './constants';
import { fetchAssessmentById, skipTheoryAssessmentRequest } from './requests';

export const state = () => ({
  assessmentId: '',
  driverId: null,
  driverName: null,
  address: null,
  email: null,
  mobile: null,
  dvlaNumber: null,
  theoryRequired: true,
  practicalRequired: true,
  liabilityAnswers: {},
  theoryAnswers: {},
  theoryQuestions: THEORY_QUESTIONS,
  practicalDocument: {
    status: 'awaiting',
  },
  historicAssessments: [],
  applicationId: null,
});

export const getters = {
  getAssessmentId(store) {
    return store.assessmentId;
  },
  getDriverId(store) {
    return store.driverId;
  },
  getDriverName(store) {
    return store.driverName;
  },
  getDriverAddress(store) {
    return store.address;
  },
  getDriverEmail(store) {
    return store.email;
  },
  getDriverMobile(store) {
    return store.mobile;
  },
  getDriverDvlaNumber(store) {
    return store.dvlaNumber;
  },
  getLiabilityAnswers(store) {
    return store.liabilityAnswers;
  },
  getPracticalDocumentStatus(store) {
    return store.practicalDocument.status;
  },
  getPracticalRequired(store) {
    return store.practicalRequired;
  },
  getTheoryRequired(store) {
    return store.theoryRequired;
  },
  getTheoryAnswers(store) {
    return store.theoryAnswers;
  },
  getTheoryQuestions(store) {
    return store.theoryQuestions;
  },
  getHistoricAssessmentDetails(store) {
    return store.historicAssessments;
  },
};

export const mutations = {
  reset(store) {
    store.driverId = null;
    store.driverName = null;
    store.address = null;
    store.email = null;
    store.mobile = null;
    store.dvlaNumber = null;
    store.theoryRequired = true;
    store.practicalRequired = true;
    store.liabilityAnswers = {};
    store.theoryAnswers = {};
    store.theoryQuestions = THEORY_QUESTIONS;
    (store.practicalDocument = {
      status: 'awaiting',
    }),
    (store.historicAssessments = []);
    store.applicationId = null;
  },
  setAssessmentId(store, value) {
    store.assessmentId = value;
  },
  setDriverId(store, value) {
    store.driverId = value;
  },
  setDriverName(store, value) {
    store.driverName = value;
  },
  setDriverAddress(store, value) {
    store.address = value;
  },
  setDriverEmail(store, value) {
    store.email = value;
  },
  setDriverMobile(store, value) {
    store.mobile = value;
  },
  setDriverDvlaNumber(store, value) {
    store.dvlaNumber = value;
  },
  setTheoryRequired(store, value) {
    store.theoryRequired = value;
  },
  setPracticalDocumentStatus(store, value) {
    store.practicalDocument.status = value;
  },
  setPracticalRequired(store, value) {
    store.practicalRequired = value;
  },
  setLiabilityAnswers(store, value) {
    store.liabilityAnswers = value;
  },
  setTheoryAnswers(store, value) {
    store.theoryAnswers = value;
  },
  setRecentAssessments(store, value) {
    store.recentAssessments = value;
  },
  mockTheoryNotRequired(store) {
    store.theoryRequired = false;
  },
  mockTheoryRequiredNotDone(store) {
    store.theoryRequired = true;
    store.theoryAnswers = null;
  },
  mockTheoryRequiredAndDone(store) {
    store.theoryRequired = true;
    store.theoryAnswers = MOCK_THEORY_ANSWERS;
  },
  setHistoricAssessments(store, value) {
    store.historicAssessments = value;
  },
  setApplicationId(store, value) {
    store.applicationId = value;
  },
};

export const actions = {
  reset({ commit }) {
    commit('reset');
  },
  async fetchAssessmentById({ commit }, assessmentId) {
    const assessment = await fetchAssessmentById(assessmentId, this);
    const liability_answers = JSON.parse(
      assessment.liability_answers.split('`').join('"'),
    );
    const theory_answers = assessment.theory_answers
      ? JSON.parse(assessment.theory_answers.split('`').join('"'))
      : {};
    commit('setAssessmentId', assessment.assessmentId);
    commit('setDriverId', assessment.driver_id);
    commit('setDriverName', assessment.driver_name);
    commit('setDriverAddress', assessment.address);
    commit('setDriverEmail', assessment.email);
    commit('setDriverMobile', assessment.mobile_phone);
    commit('setDriverDvlaNumber', assessment.dvla_number);
    commit('setTheoryRequired', assessment.theory_required);
    commit('setPracticalRequired', assessment.practical_required);
    commit('setLiabilityAnswers', liability_answers);
    commit('setTheoryAnswers', theory_answers);
    commit(
      'setPracticalDocumentStatus',
      assessment.has_practical_document ? 'succeeded' : 'awaiting',
    );
    commit('setHistoricAssessments', assessment.historic_assessments);
    commit('setApplicationId', assessment.application_id);
  },
  setPracticalDocumentStatus({ commit }, status) {
    commit('setPracticalDocumentStatus', status);
  },
  validateAssessmentComplete({ state }) {
    const error = new Error();
    error.name = 'ValidationError';

    // check assessement document
    if (state.practicalDocument.status !== 'succeeded') {
      error.message = 'Please upload a document of the practical assessment';
      throw error;
    }

    // check theory done
    if (state.theoryRequired && !Object.keys(state.theoryAnswers).length) {
      error.message = 'Cannot complete assessment, This driver still needs to take their theory test';
      throw error;
    }
  },
  fetchTheoryAnswersInArrayFormat({ commit }, payload) {},
  mockTheoryNotRequired({ commit }) {
    commit('mockTheoryNotRequired');
  },
  mockTheoryRequiredNotDone({ commit }) {
    commit('mockTheoryRequiredNotDone');
  },
  mockTheoryRequiredAndDone({ commit }) {
    commit('mockTheoryRequiredAndDone');
  },
  skipTheoryAssessment({ state, commit }) {
    skipTheoryAssessmentRequest(state, commit, this);
  },
};
