<template>
  <div :class="c_containerClass">
    <button
      :style="buttonStyle"
      :class="c_buttonClass"
      :disabled="disabled"
      :type="type"
      @click="click"
    >
      {{ buttonText }}
      <slot></slot>
    </button>
  </div>
</template>
<script>
export default {
  name: 'PButton',
  data() {
    return {
      baseButtonClass: 'p-button',
      baseContainerClass: 'button-container',
      preventDefault: false,
    };
  },
  props: {
    role: {
      type: String,
      default: 'default',
      validator(val) {
        const valids = ['default', 'success', 'warning', 'danger', 'info'];
        return valids.includes(val);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    containerClass: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
      validator(val) {
        const valids = ['button', 'submit', 'reset'];
        return valids.includes(val);
      },
    },
  },
  methods: {
    click(e) {
      if (this.preventDefault) return;

      this.disableButton();
      if (this.c_requiresValidation) {
        this.$on('validation-approved', this.clickOnValidation);
        this.$parent.$emit('click-to-validate');
      } else this.$emit('click');
    },
    clickOnValidation() {
      this.$off('validation-approved');
      this.$emit('click');
    },
    disableButton() {
      // prevent default for 2 seconds
      this.preventDefault = true;
      setTimeout(
        () => {
          this.preventDefault = false;
        },
        2000,
      );
    },
  },
  computed: {
    c_containerClass() {
      let v_classes = this.baseContainerClass;
      if (this.containerClass) v_classes += ` ${this.containerClass}`;
      return v_classes;
    },
    c_buttonClass() {
      let v_classes = this.baseButtonClass;
      v_classes += ` button-${this.role}`;
      if (this.disabled) v_classes += ` button-disabled button-${this.role}-disabled`;
      return v_classes;
    },
    c_requiresValidation() {
      const form = this.$el.closest('form');
      if (form) {
        const formClass = form.getAttribute('class');
        return formClass.includes('validation');
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.p-button:hover {
  background-color: var(--grey__slate);
  border-color: var(--grey__slate);
}
// default
.button-default,
.button-default-disabled:hover {
  background-color: var(--green);
  border-color: var(--green);
  color: white;
}
// success
.button-success,
.button-success-disabled:hover {
  background-color: var(--green);
  border-color: var(--green);
  color: white;
}
// warning
.button-warning,
.button-warning-disabled:hover {
  background-color: #e6a23d;
  border-color: #e6a23d;
  color: white;
}
// danger
.button-danger,
.button-danger-disabled:hover {
  background-color: var(--red);
  border-color: var(--red);
  color: white;
}
// info
.button-info,
.button-info-disabled:hover {
  background-color: var(--grey__slate);
  border-color: var(--grey__slate);
  color: white;
}
.button-info:hover {
  background-color: var(--grey__dark);
  border-color: var(--grey__dark);
}
// disabled
.button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

// conatiner
.button-container {
  display: inline-block;
}
.button-container + .button-container {
  margin-left: 10px;
}
</style>
