const { datetimeToDate } = require('@/utils/dateTools');

const createDriver = async (store, payload, context) => {
  const dob = datetimeToDate(payload.dob);

  const driverPayload = {
    first_name: payload.first_name,
    middle_name: payload.middle_name,
    last_name: payload.last_name,
    email: payload.email,
    mobile_phone: payload.mobile_phone.replace(/^0+/, ''),
    no_area_code: payload.no_area_code,
    dob,
    dvla_number: payload.dvla_number,
    pco_number: payload.pco_number,
  };
  const response = await context.$axios.$post(
    '/v2/application/driver',
    driverPayload,
  );
  store.commit('setDriverDetail', { key: 'driver_id', value: response.id });
};

const update = async (store, payload, context) => {
  const { key } = payload;
  let { value } = payload;
  const driverPayload = {};

  if (key === 'dob') {
    value = datetimeToDate(value);
  }

  driverPayload[key] = value;

  await context.$axios.$put(
    `/v2/application/driver/${store.state.driver.driver_id}`,
    driverPayload,
  );
  store.commit('setDriverDetail', payload);
};

const createOrder = async (store, context) => {
  const payload = {
    branch_id: store.state.branch,
    driver_id: store.state.driver.driver_id,
    practical_required: store.state.assessment.practical_required,
    theory_required: store.state.assessment.theory_required,
    liability_responses: store.state.liability.answers,
  };

  const resp = await context.$axios.$post(
    '/v2/application/application',
    payload,
  );
  store.commit('setSmsFailed', resp.theory_sms_failed);
};

module.exports = {
  createDriver,
  createOrder,
  update,
};
