export const state = () => ({
  phase: 0,
  applicationProgress: false,
  branchApplication: false,
  application: {},
  driver: {},
  driverDocs: [],
});

export const getters = {
  getApplicationPhase(state) {
    return state.phase;
  },
  inProgress(state) {
    return state.applicationProgress;
  },
  getApplication(state) {
    return state.application;
  },
  getDriver(state) {
    return state.driver;
  },
  getDriverDocs(state) {
    return state.driverDocs;
  },
};

export const mutations = {
  reset(state) {
    state.phase = 0;
    state.applicationProgress = false;
    state.branchApplication = false;
  },
  setApplicationPhase(state, payload) {
    state.phase = payload;
  },
  saveApplicationProgress(state, payload) {
    state.applicationProgress = payload;
  },
  clearApplicationProgress(state) {
    state.applicationProgress = false;
  },
  setBranchApplication(state, payload) {
    state.branchApplication = payload;
  },
  clearBranchApplication(state) {
    state.branchApplication = false;
  },
  setApplication(state, payload) {
    state.application = payload;
  },
  setDriver(state, payload) {
    state.driver = payload;
  },
  setDriverDocs(state, payload) {
    state.driverDocs = payload;
  },
  clearApplication(state) {
    state.application = {};
    state.driver = {};
    state.driverDocs = [];
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  setApplicationPhase({ commit, state }, payload) {
    commit('setApplicationPhase', payload);
  },
  saveApplicationProgress({ commit }, payload) {
    commit('saveApplicationProgress', payload);
    // localStorage.setItem('application.progress', JSON.stringify(payload));
  },
  clearApplicationProgress({ commit }) {
    commit('clearApplicationProgress');
    // localStorage.removeItem('application.progress');
  },
  setBranchApplication({ commit }, payload) {
    commit('setBranchApplication', payload);
    // localStorage.setItem('application.branch', payload);
  },
  clearBranchApplication({ commit }) {
    commit('clearApplicationProgress');
    // localStorage.removeItem('application.branch');
  },
  setApplication({ commit }, payload) {
    commit('setApplication', payload);
  },
  async fetchApplication({ commit }, userId) {
    const application = await this.$axios.$get(`/application?driver=${userId}&active=1`).then((resp) => {
      const applications = resp.application_list;
      return applications[0];
    });

    const driver = await this.$axios.$get(`/driver?id=${userId}`).then((resp) => {
      const drivers = resp.driver_list;
      return drivers[0];
    });

    const driverDocs = await this.$axios.$get(`/upload/driver?driver=${userId}`).then((resp) => resp.document_driver_list);

    commit('setApplication', application);
    commit('setDriver', driver);
    commit('setDriverDocs', driverDocs);
  },
  clearApplication({ commit }) {
    commit('clearApplication');
  },
};
