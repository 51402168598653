// import Vue from 'vue';
import { Auth } from 'aws-amplify';
import * as Logger from '@/utils/logger';
import { axiosErrorMap } from '@/utils/errorutils';

export default ({
  $axios,
  store,
  app,
}) => {
  $axios.interceptors.request.use((config) => {
    // this allows us to pass in /v2/rest-of-path/to-endpoint without hardcoding v2 base url
    if (config.url.startsWith('/v2')) config.baseURL = process.env.baseUrlv2;

    if (!config.url.includes('amazonaws')) {
      return Auth.currentAuthenticatedUser({
        bypassCache: false,
      }).then((user) => attachAuthHeader(config)).catch((err) => Auth.currentSession()
        .then((resp) => attachAuthHeader(config)).catch((err) => {
          app.router.app.$message({ message: 'Your session has expired. Please log in again.', type: 'error', center: true });
          store.dispatch('auth/logout');
        }));
    }
    const query = config.url.split('/api');
    Logger.log(`🔶 ${config.method.toUpperCase()} ${query[query.length - 1]}`, config);
    return config;
  });
  $axios.interceptors.response.use((res) => {
    const query = res.config.url.split('/api');
    Logger.log(`💎 ${res.config.method.toUpperCase()} ${query[query.length - 1]}`, res);
    return res;
  },
  (err) => {
    const mappedError = axiosErrorMap(err);
    return Promise.reject(mappedError);
  });
};

function attachAuthHeader(config) {
  const lastAuthUser = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.cognitoAppClient}.LastAuthUser`);
  const idToken = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.cognitoAppClient}.${lastAuthUser}.idToken`);

  config.headers = {
    ...config.headers,
    Authorization: idToken,
  };

  const query = config.url.split('/api');
  Logger.log(`🔶 ${config.method.toUpperCase()} ${query[query.length - 1]}`, config);
  return config;
}
