export const state = () => ({
  page: 0,
  branches: [],
  filters: '',
  branches_output: {},
});

export const getters = {
  getBranches(store) {
    return store.branches;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getBranchesOutput(store) {
    return store.branches_output;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.branches = [];
    store.filters = '';
  },
  setBranches(store, payload) {
    const new_obj = {};
    payload.map((branch) => {
      new_obj[branch.post_code] = branch.name;
    });
    store.branches_output = new_obj;
    store.branches = payload;
  },
  clearBranches(store) {
    store.branches = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getBranches({ commit, state }) {
    let url = 'branch?results=10';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const branches = await this.$axios.$get(url);
    commit('setBranches', branches.branch_list);
  },
  async clearBranches({ commit }) {
    commit('clearBranches');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
