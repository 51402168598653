export const state = () => ({
  page: 0,
  transfer: [],
  filters: '',
  reasons: {
    accident: 'Accident',
    'repair-warranty': 'Warranty',
    'repair-other': 'Repair',
    mot: 'MOT',
    drop: 'Drop',
    'phv-problem': 'PHV Problem',
    'driver-issue': 'Driver Issue',
    'pull-off': 'Pull Off',
    terminated: 'Terminate',
    'collection-new': 'Collection New',
    'collection-courtesy': 'Collection Courtesy',
    'repair-finished': 'Back From Repair',
    'courtesy-return': 'Courtesy Return',
  },
  resultCount: 0,
});

export const getters = {
  getTransfer(store) {
    return store.transfer;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
  getReasons(store) {
    return store.reasons;
  },
  getResultCount(store) {
    return store.resultCount;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.transfer = [];
    store.filters = '';
    store.resultCount = 0;
  },
  setTransfer(store, payload) {
    store.transfer = payload;
  },
  clearTransfer(store) {
    store.transfer = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setResultCount(store, payload) {
    store.resultCount = payload;
  },
};
export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getTransfer({ commit, state }) {
    // this parameter will change with 'signable signed' below. After we finish the signable integration. For the testing purposes, we send declaration_completed.
    let url = '/transfer?';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }
    const transfer = await this.$axios.$get(url);
    const formatted = transfer.transfer_list.map((tr) => {
      let vehicle_name = '';
      let reg = '';
      let checked_by = '';
      let branch = '';
      let agreement_type = '';
      let condition_notes = 'No notes';
      let driver_name = 'No Driver';
      let driver_email = '';
      let driver_number = '';

      if (tr.driver && tr.driver.length > 0) {
        if (tr.driver[0].email) driver_email = `${tr.driver[0].email}`;
        else driver_email = 'No email';

        if (tr.driver[0].mobile_phone) driver_number = `${tr.driver[0].mobile_phone}`;
        else driver_number = 'No mobile phone';

        driver_name = '';
        if (tr.driver[0].title) {
          driver_name = `${driver_name} ${tr.driver[0].title}`;
        }
        if (tr.driver[0].first_name) {
          driver_name = `${driver_name} ${tr.driver[0].first_name}`;
        }
        if (tr.driver[0].middle_name) {
          driver_name = `${driver_name} ${tr.driver[0].middle_name}`;
        }
        if (tr.driver[0].last_name) {
          driver_name = `${driver_name} ${tr.driver[0].last_name}`;
        }
      }
      if (tr.condition_report && tr.condition_report.length > 0) {
        if (tr.condition_report[0].notes != null) condition_notes = `${tr.condition_report[0].notes}`;
        else condition_notes = '';
      }

      if (tr.vehicle_id && tr.vehicle_id.length > 0) {
        vehicle_name = `${tr.vehicle_id[0].manufacturer} ${tr.vehicle_id[0].model}`;
        reg = `${tr.vehicle_id[0].vrm}`;
      } else vehicle_name = 'No vehicle type assigned';
      if (tr.employee && tr.employee.length > 0) {
        checked_by = `${tr.employee[0].first_name} ${tr.employee[0].last_name}`;
      } else vehicle_name = 'No vehicle type assigned';
      if (tr.branch && tr.branch.length > 0) {
        branch = `${tr.branch[0].name}`;
      } else vehicle_name = 'No vehicle type assigned';

      if (tr.agreement && tr.agreement.length > 0) agreement_type = tr.agreement[0].agreement_type;
      else agreement_type = '';

      return {
        ...tr,
        branch_name: tr.branch[0].name,
        vehicle_name,
        reg,
        checked_by,
        branch,
        agreement_type,
        condition_notes,
        driver_name,
        driver_email,
        driver_number,
      };
    });

    commit('setTransfer', formatted);
    commit('setResultCount', transfer.total);
  },
  async clearTransfer({ commit }) {
    commit('clearTransfer');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
