<template>
    <div class="header-style" v-loading="computedLoading">
      <h1>
          <slot></slot>
      </h1>
    </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
  },
  computed: {
    computedLoading() {
      return this.loading ? this.loading : false;
    },
  },
};
</script>
<style scoped>
.header-style {
  margin: 1em;
}
</style>
