export const state = () => ({
  page: 0,
  payments: [],
  filters: '',
});

export const getters = {
  getPayments(store) {
    return store.payments;
  },
  getPage(store) {
    return store.page;
  },
  getFilters(store) {
    return store.filters;
  },
};

export const mutations = {
  reset(store) {
    store.page = 0;
    store.payments = [];
    store.filters = '';
  },
  setPayments(store, payload) {
    store.payments = payload;
  },
  clearPayments(store) {
    store.payments = [];
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
};

export const actions = {
  async reset({ commit }) {
    commit('reset');
  },
  async getPayments({ commit, state }) {
    let url = 'payment?results=10&order=due_date&sort=ascending';
    if (state.page) {
      url += `&page=${state.page}`;
    }
    if (state.filters) {
      url += state.filters;
    }

    const payments = await this.$axios.$get(url);
    const formatted = payments.payment_list.map((payment) => {
      const totalInPounds = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(payment.total);
      return {
        formatted_amount: totalInPounds,
        ...payment,
      };
    });
    commit('setPayments', formatted);
  },
  async clearPayments({ commit }) {
    commit('clearPayments');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
};
