import { render, staticRenderFns } from "./PTabs.vue?vue&type=template&id=809f69ca&scoped=true&"
import script from "./PTabs.vue?vue&type=script&lang=js&"
export * from "./PTabs.vue?vue&type=script&lang=js&"
import style0 from "./PTabs.vue?vue&type=style&index=0&id=809f69ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "809f69ca",
  null
  
)

export default component.exports