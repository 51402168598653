// These are all the required getters and setters to work with p-table
// you can see/play with this p-table in the pulse test playground page

const MOCK_DATA = {
  data: [
    {
      data_value: 'First value',
      another_field: 'More data',
    },
    {
      data_value: 'Second value',
      another_field: 'Some more data',
    },
  ],
  results: 2,
};

export const state = () => ({ // maybe change some of these to choose your own default values
  page: 1,
  data: [],
  filters: '',
  headers: [ // definitely change these, they are the column headers
    { text: 'Human Readable Column Label', value: 'data_value', sortable: false },
    { text: 'Another Column', value: 'another_field' },
  ],
  sortBy: undefined,
  sortDesc: undefined,
  totalResults: undefined,
  itemsPerPage: undefined,
});

export const getters = {
  getData(state) {
    return state.data;
  },
  getPage(state) {
    return state.page;
  },
  getHeaders(state) {
    return state.headers;
  },
  getTotalResults(state) {
    return state.totalResults;
  },
  getItemsPerPage(state) {
    return state.itemsPerPage;
  },
};

export const mutations = {
  setData(store, payload) {
    store.data = payload;
  },
  setPage(store, payload) {
    store.page = payload;
  },
  setFilters(store, payload) {
    store.filters = payload;
  },
  setTotalResults(state, value) {
    state.totalResults = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setSortDesc(state, value) {
    state.sortDesc = value;
  },
  setItemsPerPage(state, value) {
    state.itemsPerPage = value;
  },
};

export const actions = {
  async clearData({ commit }) {
    commit('clearData');
  },
  async setPage({ commit }, payload) {
    commit('setPage', payload);
  },
  async setFilters({ commit }, payload) {
    commit('setFilters', payload);
  },
  setSortBy({ commit }, value) {
    commit('setSortBy', value);
  },
  setSortDesc({ commit }, value) {
    commit('setSortDesc', value);
  },
  setItemsPerPage({ commit }, value) {
    commit('setItemsPerPage', value);
  },
  async loadPage({ commit, state }) {
    let url = '/v2/fake-endpoint/'; // change this
    url += `?page=${state.page - 1}`;

    if (state.filters) url += state.filters;
    if (state.sortBy) url += `&order_by=${state.sortBy}`;
    if (state.sortDesc !== undefined) url += `&order=${state.sortDesc ? 'descending' : 'ascending'}`;
    if (state.itemsPerPage !== undefined) url += `&results=${state.itemsPerPage}`;

    // const data = await this.$axios.$get(url);
    const data = MOCK_DATA;

    commit('setData', data.data);
    commit('setTotalResults', data.total);
  },
};
