<template>
  <!-- <el-card shadow="never" id="query" v-loading="loading"> -->
  <div id="query" v-loading="loading">
    <el-input
      class="input"
      placeholder="Lookup driver or vehicle"
      v-model="query"
      @keyup.enter.native="onSubmit"
      size="small"
    ></el-input>
    <el-dialog title="Select result" :visible.sync="showModal">
      <el-table :data="results.rows" @row-click="clickRow">
        <el-table-column property="id" label="ID" v-if="results.type == 'vehicles'"></el-table-column>
        <el-table-column property="vin" label="VIN" v-if="results.type == 'vehicles'"></el-table-column>
        <el-table-column property="vrm" label="VRM" v-if="results.type == 'vehicles'"></el-table-column>
        <el-table-column property="first_name" label="First Name" v-if="results.type == 'drivers'"></el-table-column>
        <el-table-column property="last_name" label="Last Name" v-if="results.type == 'drivers'"></el-table-column>
        <el-table-column property="email" label="Email" v-if="results.type == 'drivers'"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <!-- </el-card> -->
</template>

<script>
export default {
  data() {
    return {
      query: '',
      showModal: false,
      loading: false,
      results: {
        rows: [],
        type: '',
      },
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$axios
        .$get(`/search?find=${encodeURIComponent(this.query)}`)
        .then((resp) => {
          const { driver_list, vehicle_list } = resp;
          if (driver_list.length > 0) this.hasDrivers(driver_list);
          else if (vehicle_list.length > 0) this.hasVehicles(vehicle_list);
          else {
            this.$message({
              message: 'No results',
              type: 'error',
              center: true,
            });
            this.resetQuery();
          }
        });
      this.loading = false;
    },
    hasDrivers(drivers) {
      if (drivers.length > 1) {
        this.results.type = 'drivers';
        this.results.rows = drivers;
        this.showModal = true;
      } else {
        this.pushToDynamicRoute('driver_single', drivers[0].id);
        this.resetQuery();
      }
    },
    hasVehicles(vehicles) {
      if (vehicles.length > 1) {
        this.results.type = 'vehicles';
        this.results.rows = vehicles;
        this.showModal = true;
      } else {
        this.pushToDynamicRoute('fleet_single', vehicles[0].id);
        this.resetQuery();
      }
    },
    clickRow(row, column, event) {
      if (this.results.type == 'drivers') {
        this.pushToDynamicRoute('driver_single', row.id);
      } else {
        this.pushToDynamicRoute('fleet_single', row.id);
      }
      this.resetQuery();
    },
    resetQuery() {
      this.query = '';
      this.loading = false;
      this.showModal = false;
      this.loading = false;
      this.results = { rows: [], type: '' };
    },
    pushToDynamicRoute(name, id) {
      this.$router.push(this.$store.getters.getDynamicRoute(name, id));
    },
  },
};
</script>

<style lang='scss' scoped>
#query {
  margin-bottom: 1em;
  border-radius: 25px;
  border-left: 0;
}
::v-deep .el-card__body {
  padding: 0.85em;
}
::v-deep .el-input__inner {
  width: 105% !important;
  border: solid 1px #dfdfdf;
  background-image: url("/logo/lookup.png"); /* Add a search icon to input */
  background-size: 1.8em;
  background-position: 5px; /* Position the search icon */
  background-repeat: no-repeat; /* Do not repeat the icon image */
  font-size: 14px;
  font-weight: lighter;
  padding: 12px 20px 12px 40px; /* Add some padding */
  margin-bottom: 12px;
  border-radius: 25px;
}
</style>
