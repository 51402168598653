import { Auth } from 'aws-amplify';

export const getters = {
  token() {
    const token = Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then((user) => user.signInUserSession.idToken.jwtToken).catch((e) => false);
    return token;
  },
  userId() {
    const userId = Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then((user) => user.signInUserSession.idToken.payload['custom:user_id']).catch((e) => false);
    return userId;
  },
  isAuthed(state) {
    return localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.cognitoAppClient}.LastAuthUser`) || false;
  },
  userType(state) {
    const type = Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then((user) => user.signInUserSession.idToken.payload['custom:role']).catch((e) => false);
    return type;
  },
};

export const actions = {
  async login({ rootGetters, dispatch, commit }) {
    // Request information on current user from Cognito
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    // Retrieve useful information from Cognito response
    const userId = user.signInUserSession.idToken.payload['custom:user_id'];
    const type = userId.split('_')[0];

    // Redirect to relevant page based on user type
    switch (type) {
      case 'driver':
        this.$router.push(rootGetters.getRoute('user_dashboard'));
        break;
      case 'dealer':
        this.$router.push(rootGetters.getRoute('fleet_batches'));
        break;
      case 'employee':
        this.$router.push(rootGetters.getRoute('staff_home'));
        break;
    }
  },
  async logout({ rootGetters, commit, dispatch }) {
    await Auth.signOut();
    await dispatch('clearState', null, { root: true });
    this.$router.push(rootGetters.getRoute('login'));
  },
};
