// TODO set loading either side of functions in components, not here

const getNow = (dateObj = new Date()) => dateObj.toISOString().slice(0, 19).replace('T', ' ');

// Fetch Data logic

const fetchDataDefault = (store) => {
  store.commit('setReason', '');
};

const fetchDataCollectionNew = async (store, context, params) => {
  store.loading = true;

  const { agreementId } = params;
  const { applicationId } = params;

  const agreements = await context.$axios.$get(`agreement?id=${agreementId}`);
  const { agreement_list } = agreements;
  const agreementObject = agreement_list[0];

  store.commit('setDataDynamic', { field: 'agreementId', value: agreementId });
  store.commit('setDataDynamic', { field: 'applicationId', value: applicationId });
  store.commit('setDataDynamic', { field: 'vehicleObject', value: agreementObject.vehicle[0] });
  store.commit('setDataDynamic', { field: 'driverId', value: agreementObject.driver[0].id });

  store.loading = false;
};

const fetchDataTemporaryRental = (store, context, params) => {
  store.loading = true;

  const { agreementId } = params;
  const { driverId } = params;

  store.commit('setDataDynamic', { field: 'agreementId', value: agreementId });
  store.commit('setDataDynamic', { field: 'driverId', value: driverId });

  store.loading = false;
};

const fetchDataReturnVehicle = async (store, context, params) => {
  store.loading = true;

  const { agreementId } = params;

  const agreements = await context.$axios.$get(`agreement?id=${agreementId}`);
  const { agreement_list } = agreements;
  const agreementObject = agreement_list[0];

  store.commit('setDataDynamic', { field: 'agreementId', value: agreementId });
  store.commit('setDataDynamic', { field: 'vehicleObject', value: agreementObject.vehicle[0] });

  store.loading = false;
};

const fetchDataAssignCourtesy = async (store, context, params) => {
  store.loading = true;

  const { agreementId } = params;

  const agreements = await context.$axios.$get(`agreement?id=${agreementId}`);
  const { agreement_list } = agreements;
  const agreementObject = agreement_list[0];

  store.commit('setDataDynamic', { field: 'agreementId', value: agreementId });
  store.commit('setDataDynamic', { field: 'driverId', value: agreementObject.driver[0].id });

  store.loading = false;
};

const fetchDataReturnCourtesy = async (store, context, params) => {
  store.loading = true;

  const { agreementId } = params;
  const { vehicleId } = params;

  const vehicles = await context.$axios.$get(`vehicle?id=${vehicleId}`);
  const { vehicle_list } = vehicles;
  const vehicleObject = vehicle_list[0];

  store.commit('setDataDynamic', { field: 'agreementId', value: agreementId });
  store.commit('setDataDynamic', { field: 'vehicleObject', value: vehicleObject });

  store.loading = false;
};

const fetchDataRentalSwap = async (store, context, params) => {
  store.loading = true;

  const { agreementId } = params;

  const agreements = await context.$axios.$get(`agreement?id=${agreementId}`);
  const { agreement_list } = agreements;
  const agreementObject = agreement_list[0];

  store.commit('setDataDynamic', { field: 'agreementId', value: agreementId });
  store.commit('setDataDynamic', { field: 'driverId', value: agreementObject.driver[0].id });

  store.loading = false;
};

const fetchDataFinishService = async (store, context, params) => {
  store.loading = true;

  const { vehicleId } = params;

  const vehicles = await context.$axios.$get(`vehicle?id=${vehicleId}`);
  const { vehicle_list } = vehicles;
  const vehicleObject = vehicle_list[0];

  store.commit('setDataDynamic', { field: 'vehicleObject', value: vehicleObject });

  if (vehicleObject.agreement) {
    store.commit('setDataDynamic', { field: 'agreementId', value: vehicleObject.agreement[0].id });
    store.commit('setDataDynamic', { field: 'driverId', value: vehicleObject.driver[0].id });
  }

  store.loading = false;
};

// Submit Logic

const submitDefault = async (store, context) => {
  const payload = {
    condition_report: {
      id: store.conditionReportId,
      vehicle: store.vehicleObject.id,
      mileage: store.mileage,
      reason: store.reason,
      fuel_level: store.fuelGauge,
      customer_signature: store.signature,
      condition_location: store.location,
      checklist_v2: store.checklist,
      notes: store.notes,
    },
    transfer: {
      agreement: store.agreementId,
      transfer_type: 'check-in',
      in_date: getNow(),
    },
  };
  await context.$axios.$put('/condition_report', payload);
};

const submitCollectionNew = async (store, context) => {
  const payload = {
    condition_report_id: store.conditionReportId,
    agreement_id: store.agreementId,
    vehicle_id: store.vehicleObject.id,
    mileage: Number(store.mileage),
    fuel_level: store.fuelGauge,
    location: store.location,
    checklist_v2: store.checklist,
    notes: store.notes,
  };
  await context.$axios.$post('/v2/application/condition_report/new_used_agreement', payload);
  await context.$axios.$post(
    `/v2/application/ordway_create_subscription/${store.agreementId}`,
    {
      condition_report_id: store.conditionReportId,
      location: store.location,
    },
  );
};

const submitTemporaryRental = async (store, context) => {
  const payload = {
    condition_report: {
      id: store.conditionReportId,
      vehicle: store.vehicleObject.id,
      mileage: store.mileage,
      reason: 'temporary-rental',
      fuel_level: store.fuelGauge,
      customer_signature: store.signature,
      condition_location: store.location,
      checklist_v2: store.checklist,
      notes: store.notes,
    },
    transfer: {
      agreement: store.agreementId,
      transfer_type: 'check-out',
      out_date: getNow(),
    },
    driver: store.driverId,
  };
  await context.$axios.$put('/condition_report', payload);
};

const submitReturnVehicle = async (store, context) => {
  const payload = {
    condition_report: {
      id: store.conditionReportId,
      vehicle: store.vehicleObject.id,
      mileage: store.mileage,
      reason: 'terminated',
      fuel_level: store.fuelGauge,
      customer_signature: store.signature,
      condition_location: store.location,
      checklist_v2: store.checklist,
      notes: store.notes,
    },
    transfer: {
      agreement: store.agreementId,
      transfer_type: 'check-in',
      in_date: getNow(),
    },
  };

  await context.$axios.$put('/condition_report', payload);
};

const submitAssignCourtesy = async (store, context) => {
  const payload = {
    condition_report_id: store.conditionReportId,
    agreement_id: store.agreementId,
    vehicle_id: store.vehicleObject.id,
    driver_id: store.driverId,
    mileage: store.mileage,
    fuel_level: store.fuelGauge,
    customer_signature: store.signature,
    location: store.location,
    checklist_v2: store.checklist,
    notes: store.notes,
  };
  await context.$axios.$post('/v2/servicing/courtesy', payload);
};

const submitReturnCourtesy = async (store, context) => {
  const payload = {
    condition_report_id: store.conditionReportId,
    agreement_id: store.agreementId,
    vehicle_id: store.vehicleObject.id,
    mileage: store.mileage,
    fuel_level: store.fuelGauge,
    customer_signature: store.signature,
    location: store.location,
    checklist_v2: store.checklist,
    notes: store.notes,
  };
  await context.$axios.$put('/v2/servicing/courtesy', payload);
};

const submitRentalSwap = async (store, context) => {
  const payload = {
    condition_report_id: store.conditionReportId,
    agreement_id: store.agreementId,
    vehicle_id: store.vehicleObject.id,
    driver_id: store.driverId,
    mileage: store.mileage,
    fuel_level: store.fuelGauge,
    customer_signature: store.signature,
    location: store.location,
    checklist_v2: store.checklist,
    notes: store.notes,
  };
  await context.$axios.$post('/v2/servicing/rental', payload);
};

const submitFinishService = async (store, context) => {
  if (store.agreementId) {
    const payload = {
      condition_report_id: store.conditionReportId,
      agreement_id: store.agreementId,
      vehicle_id: store.vehicleObject.id,
      driver_id: store.driverId,
      mileage: store.mileage,
      fuel_level: store.fuelGauge,
      customer_signature: store.signature,
      location: store.location,
      checklist_v2: store.checklist,
      notes: store.notes,
    };
    await context.$axios.$put('/v2/servicing/finish_servicing/driver', payload);
  } else {
    const payload = {
      condition_report_id: store.conditionReportId,
      vehicle_id: store.vehicleObject.id,
      mileage: store.mileage,
      fuel_level: store.fuelGauge,
      customer_signature: store.signature,
      location: store.location,
      checklist_v2: store.checklist,
      notes: store.notes,
    };
    await context.$axios.$put('/v2/servicing/finish_servicing/off_road', payload);
  }
};

const fetchData = {
  default: fetchDataDefault,
  collectionNew: fetchDataCollectionNew,
  temporaryRental: fetchDataTemporaryRental,
  returnVehicle: fetchDataReturnVehicle,
  assignCourtesy: fetchDataAssignCourtesy,
  returnCourtesy: fetchDataReturnCourtesy,
  rentalSwap: fetchDataRentalSwap,
  finishService: fetchDataFinishService,
};

const submit = {
  default: submitDefault,
  collectionNew: submitCollectionNew,
  temporaryRental: submitTemporaryRental,
  returnVehicle: submitReturnVehicle,
  assignCourtesy: submitAssignCourtesy,
  returnCourtesy: submitReturnCourtesy,
  rentalSwap: submitRentalSwap,
  finishService: submitFinishService,
};

module.exports = {
  fetchData,
  submit,
};
