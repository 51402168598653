const MAX_SIZE = 2500000; // max size of condition report image

export const state = () => ({
  agreement_types: {
    driver_signature: 'Driver Signature',
    contract: 'Contract',
    payment_plan: 'Payment Plan',
    permission_letters: 'Permission Letters',
  },
  driverDocumentTypes: {
    pco: 'PCO',
    dvla_front: 'DVLA Front',
    dvla_back: 'DVLA Back',
    poa: 'Proof of address',
    practical_assessment: 'Practical assessment',
    insurance: 'Insurance',
  },
});

export const getters = {
  getAgreementTypes(store) {
    return store.agreement_types;
  },
  getDriverDocumentTypes(store) {
    return store.driverDocumentTypes;
  },
};

export const mutations = {
  clearState(payload) {
    payload = [];
  },
};

export const actions = {
  async new_upload({ dispatch }, payload) {
    const key = await dispatch('documentAuthenticate', payload);

    // upload to s3
    await dispatch('uploadToS3', { ...payload, key });
    return key;
  },
  async documentAuthenticate(store, payload) {
    const {
      model,
      model_id,
      document_type,
      file,
      expiry_date,
      start_date,
      assessmentId,
    } = payload;
    const body = {
      model,
      model_id,
      document_type,
      filename: file.name,
    };
    if (start_date !== undefined) body.start_date = start_date;
    if (expiry_date !== undefined) body.expiry_date = expiry_date;
    if (assessmentId !== undefined) body.assessment_id = assessmentId;
    const resp = await this.$axios.$put('/document_authenticate', body);
    return resp.s3_response;
  },
  async upload({ dispatch, app }, payload) {
    const key = await dispatch('getPresignedUrl', payload);
    await dispatch('uploadToS3', { ...payload, key });
    const keyLocation = key.fields.key.split('/');
    const id = keyLocation[keyLocation.length - 1];
    return { ...payload, id };
  },
  async getPresignedUrl(store, payload) {
    const { entity, category, document_type } = payload;
    if (category === 'driver') {
      const resp = await this.$axios.$post('/upload/driver', {
        document_driver: {
          driver: entity,
          document_type,
          filename: payload.file.name,
        },
      });
      return resp.s3_response;
    }
    if (category === 'vehicle') {
      const resp = await this.$axios.$post('/upload/vehicle', {
        document_vehicle: {
          vehicle: entity,
          document_type,
          filename: payload.file.name,
        },
      });
      return resp.s3_response;
    }
    if (category === 'condition_report') {
      const resp = await this.$axios.$post('/upload/condition_report', {
        document_condition_report: {
          condition_report: entity,
          filename: payload.file.name,
        },
      });
      return resp.s3_response;
    }
    if (category === 'vehicle_type') {
      const resp = await this.$axios.$post('/upload/vehicle_type', {
        document_vehicle_type: {
          vehicle_type: entity,
          filename: payload.file.name,
        },
      });
      return resp.s3_response;
    }
    if (category === 'agreement') {
      const resp = await this.$axios.$post('/upload/agreement', {
        document_agreement: {
          agreement: entity,
          document_type,
          filename: payload.file.name,
        },
      });
      return resp.s3_response;
    }
    if (category === 'dealer') {
      const resp = await this.$axios.$post('/upload/dealer', {
        document_dealer: {
          dealer: entity,
          filename: payload.file.name,
        },
      });
      return resp.s3_response;
    }
  },
  async uploadToS3(store, payload) {
    const { key, file } = payload;
    console.log('Form data', new FormData());
    const formData = new FormData();
    Object.keys(key.fields).forEach((field) => {
      formData.append(field, key.fields[field]);
    });
    formData.append('file', file);
    await this.$axios.$post(key.url, formData);
  },
  async ocrFile(store, payload) {
    const { entity, document_type, id } = payload;
    const ocr = await this.$axios.get(
      `/ocr?driver=${entity}&document_type=${document_type}&id=${id}`,
    );
    if (ocr.data.ocr_response) return ocr.data.ocr_response;
    return false;
  },
  async clearState({ commit }, payload) {
    commit('clearState');
  },
};
