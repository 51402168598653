export const state = () => ({
  stores: [
    'finance/arrears/aftercare-full',
    'finance/arrears/aftercare-insurance',
    'finance/arrears/courtesy',
    'finance/arrears/r2b-classic',
    'finance/arrears/rental',
    'finance/arrears/van',
    'finance/byStatus/completed',
    'finance/byStatus/dropped',
    'finance/byStatus/live',
    'finance/byStatus/paid-off',
    'finance/byStatus/paused',
    'finance/byStatus/terminated',
    'finance/singleAccount/scheduledPayments',
    'finance/singleAccount/transactions',
    'order/vehiclesForSelectedOrder',
    'pendingAgreements/r2b-used',
    'pendingAgreements/r2b',
    'pendingAgreements/rental',
    'agreements',
    'application',
    'applications',
    'batches',
    'branchapplication',
    'branches',
    'dealerships',
    'drivers',
    'employees',
    'paymentPlan',
    'pendingAssessments',
    'pendingSales',
    'vehicles',
    'vehicleTypes',
    'pages/application',
  ],
  routes: {
    login: '/',
    // user_new: '/register',
    // user_confirm: '/register/confirm',

    // User-facing app
    // user_dashboard: '/dash',
    // card_dashboard: '/dash/add-card',
    // user_profile: '/profile',
    // user_profile_docs_new: '/profile/document-upload',

    // Application
    // application_start: '/application',
    // application_choose_plan: '/application/plan',
    // application_choose_vehicle: '/application/vehicle',
    // application_closed: '/application/closed',
    // application_assessment: '/application/assessment',
    // application_waiver: '/application/waiver',
    // application_practical: '/application/practical',
    // application_review: '/application/review',
    // application_declaration: '/application/declaration',
    // application_success: '/application/success',

    // application_other_choose_vehicle: '/application-other/vehicle',
    // application_other_closed: '/application-other/closed',
    // application_other_assessment: '/application-other/assessment',
    // application_other_practical: '/application-other/practical',
    // application_other_review: '/application-other/review',
    // application_other_declaration: '/application-other/declaration',
    // application_other_success: '/application-other/success',

    // Staff
    staff_home: '/staff',

    // Orders
    orders: '/staff/orders/orderList',
    applications: '/staff/drivers/applications',
    driver_assessment_create: '/staff/drivers/assessment/create',
    driver_assessments: '/staff/drivers/assessment',

    // Fleet Management
    fleet_list: '/staff/fleet',
    fleet_single: '/staff/fleet/', // Dynamic
    fleet_types: '/staff/fleet/types',
    fleet_batches: '/staff/fleet/batches',
    fleet_product: '/staff/fleet/product',
    // fleet_manage_pricing: '/staff/fleet/manage-pricing',
    fleet_transfer: '/staff/fleet/transfer',
    fleet_used: '/staff/fleet/used',
    condition_reports_list: '/staff/fleet/condition-reports-list',
    fleet_migration: '/staff/fleet/migration-review',
    condition_report_dynamic: '/staff/fleet/condition-report/',

    // User Management
    driver_list: '/staff/drivers',
    driver_single: '/staff/drivers/', // Dynamic
    employee_list: '/staff/users/employees',
    dealership_list: '/staff/users/dealerships',
    branches_list: '/staff/users/branches',

    // Agreement Management
    agreement_list: '/staff/agreements/agreement-list/',
    aftercare_review: '/staff/agreements/aftercare',
    agreement_single: '/staff/agreements/', // Dynamic
    agreement_finance: '/staff/agreements/agreement-finance',

    // Sales Management
    new_order: '/staff/sales/new-order',
    continue_order: '/staff/sales/continue-order/continue-order-list',
    continue_order_single: '/staff/sales/continue-order/continue-order-single/', // Dynamic
    co_additional_details: '/staff/sales/continue-order/continue-order-single/co-additional-details/',
    co_choose_model: '/staff/sales/continue-order/continue-order-single/co-choose-model/', // Dynamic
    co_select_plan: '/staff/sales/continue-order/continue-order-single/co-select-plan/', // Dynamic
    co_assign_vehicle: '/staff/sales/continue-order/continue-order-single/co-assign-vehicle/',
    co_declaration: '/staff/sales/continue-order/continue-order-single/co-declaration/', // Dynamic
    assessments_list: '/staff/sales/assessments/assessment-list',
    assessment_single: '/staff/sales/assessments/assessment-single/', // Dynamic
    pending_agreements_general: '/staff/sales/pending-agreements-general',
    r2b_classic_single: '/staff/sales/r2b-classic/', // Dynamic
    r2b_used_single: '/staff/sales/r2b-used/', // Dynamic
    rental_single: '/staff/sales/rental/', // Dynamic

    // Service Management
    services_list: '/staff/servicing',
    phv_and_mot: '/staff/servicing/phv-mot',

    // Finance Management
    finance: '/staff/finance',
    finance_anchor: '/staff/finance/anchor-agreements',
    finance_agreements: '/staff/finance/agreements',
    finance_drivers: '/staff/finance/drivers',
    finance_deflation: '/staff/finance/deflation',
    pcn_charges: '/staff/finance/pcn-charges',
    congestion_charge: '/staff/finance/congestion-charge',
    dart_charge: '/staff/finance/dart-charge',
    road_tax: '/staff/finance/road-tax',
    finance_new_agreements: '/staff/finance/new-agreements-list',
    finance_closed_agreements: '/staff/finance/closed-agreements-list',

    // Dashboard Management
    dashboard: '/staff/dashboard', //
    test: '/staff/test',
  },
  navSearch: [
    { value: 'Home', link: 'staff_home' },
    { value: 'Vehicle list', link: 'fleet_list' },
    { value: 'Fleet list', link: 'fleet_list' },
    { value: 'Vehicle types', link: 'fleet_types' },
    { value: 'Vehicle batches', link: 'fleet_batches' },
    { value: 'Driver list', link: 'driver_list' },
    { value: 'Customer list', link: 'driver_list' },
    { value: 'Customer list', link: 'driver_list' },
    { value: 'Driving Assessments', link: 'driver_assessments' },
    { value: 'New Assessment', link: 'driver_assessment_create' },
    { value: 'Create Assessment', link: 'driver_assessment_create' },
    { value: 'Driver Assessment', link: 'driver_assessment_create' },
    { value: 'Employee directory', link: 'employee_list' },
    { value: 'Team directory', link: 'employee_list' },
    { value: 'Dealership list', link: 'dealership_list' },
    { value: 'Services management', link: 'services_list' },
    { value: 'Services calendars', link: 'services_calendars' },
    { value: 'Agreements list (finance)', link: 'finance_agreements' },
    { value: 'Driver list (finance)', link: 'finance_drivers' },
    { value: 'Deflation', link: 'finance_deflation' },
    { value: 'End-of-contract customers', link: 'finance_deflation' },
    { value: 'Accident list', link: 'accident_list' },
    { value: 'Create accident', link: 'accident_create' },
    { value: 'Send messages to drivers', link: 'telephony' },
    { value: 'Textmarketer', link: 'telephony' },
    { value: 'Text Marketer', link: 'telephony' },
    { value: 'List applications', link: 'applications' },
    { value: 'List agreements', link: 'agreement_list' },
    { value: 'All agreements', link: 'agreement_list' },
    { value: 'Create new agreement', link: 'agreement_create' },
    { value: 'Create New Condition Report', link: 'fleet_handoff' },
  ],
});

export const getters = {
  getRoute: (state) => (name) => state.routes[name],
  getDynamicRoute: (state) => (name, id) => state.routes[name] + id,
};

export const mutations = {};

export const actions = {
  formatDate({ }, payload) {
    return payload.toISOString().substring(0, 10);
  },
  clearState({ dispatch, commit, state }, payload) {
    for (const x in state.stores) {
      dispatch(`${state.stores[x]}/reset`, null, { root: true });
    }
  },
};
