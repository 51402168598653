<template>
  <div class="btn-wrapper">
    <v-btn
      class="p-btn"
      dense
      :small="!large"
      :disabled="computedDisable"
      :color="`var(--${computedColor})`"
      @click="handleClick"
      depressed
      :loading="loading"
    >
      <slot />
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'PBtn',
  props: {
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    large: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
  computed: {
    computedColor() {
      return this.color ? this.color : 'primary';
    },
    computedDisable() {
      return this.disabled ? this.disabled : false;
    },
  },
};
</script>
<style lang="scss">
.p-btn {
  text-transform: none !important;
  color: #ffffff !important;
}
.btn-wrapper {
  margin: 1em 0.5em 1em 0.5em;
}
.v-btn__content {
  font-weight: 600;
}
.p-btn:hover {
  background-color: var(--grey__dark) !important;
}

</style>
