const addressLine1 = (state) => state.addressLine1
&& state.addressLine1.length > 0;

const city = (state) => !!state.city;
const postCode = (state) => !!state.postCode
&& state.postCode.length > 5 && state.postCode.length < 9;
const country = (state) => !!state.country;

const pcoExpiryDate = (state) => !!(state.pco.expiryDate || state.pco.selectedDate);

const poaExpiryDate = (state) => !!(state.poa.expiryDate || state.poa.selectedDate);
const referralSource = (state) => !!state.referralSource;
const dvlaNumber = (state) => !!state.dvlaNumber;
const pcoNumber = (state) => !!state.dvlaNumber;
const dvlaTestDate = (state) => !!state.dvlaTestDate
&& new Date(state.dvlaTestDate) < new Date();
const nationalInsurance = (state) => {
  let value = state.nationalInsurance;
  if (!value) {
    return false;
  }
  const re = /^((?!BG|GB|KN|NK|NT|TN|ZZ|[A-Z]?[DFIQUV])[A-Z][A-NP-Z])[0-9]{6}[ABCD]$/i;
  const replaceRe = /\s|\-|\(|\)/g;
  value = value.replace(replaceRe, '');
  if (!!value && re.exec(value)) return true;
  return false;
};

const validateForm = (state, commit) => {
  if (
    addressLine1(state.formDetails)
    && city(state.formDetails)
    && postCode(state.formDetails)
    && country(state.formDetails)
    && pcoExpiryDate(state.documents)
    && poaExpiryDate(state.documents)
    && referralSource(state.formDetails)
    && dvlaNumber(state.formDetails)
    && pcoNumber(state.formDetails)
    && dvlaTestDate(state.formDetails)
    && nationalInsurance(state.formDetails)
  ) commit('setFormValidation', true);
  else {
    commit('setFormValidation', false);
  }
};

const validateDocuments = async (state, commit, context) => {
  const payload = {
    driver_id: state.formDetails.driverId,
    document_types: ['pco', 'poa'],
  };
  const response = await context.$axios.$post('/v2/application/driver/validate_documents', payload);
  commit('setDocumentHasExpired', response);
};

module.exports = {
  validateDocuments,
  validateForm,
};
